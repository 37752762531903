
import { VersaoStore } from './store/versao.store';
import { VersaoService } from './services/versao.service';
import { ResumoStore } from './store/resumo.store';
import { Ng4LoadingSpinnerModule, Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CPFPipe } from './pipes/cpf.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ListaChamadoStore } from './store/listaChamado.store';
import { SituacaoRelacaoService } from './services/situacaoRelacao.service';
import { SituacaoStore } from './store/situacao.store';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FuseSharedModule } from '@fuse/shared.module';

import {
  MatRadioModule, MatAutocompleteModule, MatFormFieldModule, MatDialogModule, MatProgressSpinnerModule, MatCheckboxModule,
  MatDatepickerModule, MatInputModule, MatSelectModule, MatSnackBarModule, MatExpansionModule, MatTabsModule, MatIconModule, MatMenuModule, MatButtonModule,
} from '@angular/material';

import { Util } from './util';
import { ErrorPageComponent } from '../components/errors/errorpage.component';
import { RouterModule } from '@angular/router';

import { AreaService } from './services/area.service';
import { CanalService } from './services/canal.service';
import { AtendenteService } from './services/atendente.service';
import { AtendenteCanalService } from './services/atendenteCanal.service';
import { ChamadoService } from './services/chamado.service';
import { PessoaService } from './services/pessoa.service';
import { AssuntoService } from './services/assunto.service';
import { SituacaoChamadoService } from './services/situacaoChamado.service';
import { EventEmitterService } from './services/eventEmitter.service';
import { RegistroService } from './services/registro.service';

import { AreaStore } from './store/area.store';
import { CanalStore } from './store/canal.store';
import { AtendenteStore } from './store/atendente.store';
import { ChamadoStore } from './store/chamado.store';
import { AssuntoStore } from './store/assunto.store';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LoginStore } from './store/login.store';
import { VersaoAppStore } from './store/versaoApp.store';
import { ChamadoStatusService } from './services/chamadoStatus.service';
import { SnackBarService } from './services/snackBar.service';
import { IntegracaoService } from './services/integracao.service';
import { IntegracaoStore } from './store/integracao.store';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

const routes = [
  {
    path: 'errorpage',
    component: ErrorPageComponent
  }
];

@NgModule({
  declarations: [
    PhonePipe,
  ],
  imports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options),
    Ng4LoadingSpinnerModule.forRoot(),

  ],
  exports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    PhonePipe,
    NgxMaskModule,
    Ng4LoadingSpinnerModule,
  ],
  providers: [
    Util,
    AreaService,
    CanalService,
    AtendenteService,
    AtendenteCanalService,
    ChamadoService,
    ChamadoStatusService,
    PessoaService,
    AssuntoService,
    SituacaoChamadoService,
    ChamadoStatusService,
    AreaStore,
    CanalStore,
    AtendenteStore,
    ChamadoStore,
    AssuntoStore,
    EventEmitterService,
    RegistroService,
    SituacaoStore,
    ListaChamadoStore,
    SituacaoRelacaoService,
    Ng4LoadingSpinnerService,
    ResumoStore,
    LoginStore,
    VersaoService,
    VersaoStore,
    VersaoAppStore,
    SnackBarService,
    IntegracaoService,
    IntegracaoStore
  ]
})

export class SharedModule {
}
