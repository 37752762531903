import { LoginNewPassword } from './../../shared/models/loginNewPassword.model';
import { LoginService } from './../../shared/services/login.service';
import { LoginStore } from './../../shared/store/login.store';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogComponent } from '../../components/message/dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Util } from '../../shared/util';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {

  hideSenha = true;
  hideConfirmacaoSenha = true;
  formErrors: any;
  form: FormGroup;
  senha: LoginNewPassword =  new LoginNewPassword();
  


  constructor(
    private formBuilder: FormBuilder,
    private readonly router: Router,
    private loginService: LoginService,
    private readonly dialog: MatDialog,
    private util: Util,
    private snackBar: MatSnackBar,
    private loginStore: LoginStore) { }

  ngOnInit() {

    this.loginStore.getCurrent().subscribe(usuarioLogin => {
      this.senha.id = usuarioLogin.idLogin;
    });

    this.form = this.formBuilder.group({
      NovaSenha: ['', Validators.required],
      ConfirmaSenha: ['', Validators.required],
      Login: []
    });


  }

  back(): void {
    this.router.navigate(['project']);
  }

  SalvaSenha() {
    this.validaSenha(resultado => {
      if (resultado) {
        this.loginService.alteraSenha(this.senha).subscribe(senha => {
          const message = this.senha.id !== '' ? 'Senha atualizada' : 'Erro ao atualizar senha';
          this.showMessage(message, true);
        }, error => {
          if (JSON.stringify(error).includes('Confirmacao')) {
          this.showMessage('As senhas não coincidem!', false);
          }
          else {
            this.showMessage(error, false);
          }
        });
      }
    });

  }

  validaSenha(callback) {
    if (this.util.IsNullOrUndefined(this.senha.senha)) {
      this.showSnackBar('O senha é requerida.');
      callback(false);
    } else if (this.util.IsNullOrUndefined(this.senha.confirmacao)) {
      this.showSnackBar('O confirmação da senha é requerida.');
      callback(false);
    } else {
      callback(true);
    }
  }

  showMessage(message: string, valido: boolean): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: { message: message }
    });

    if (valido) {
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate(['project']);
      });
    }
  }
  showSnackBar(message: string): void {
    this.snackBar.open(message, 'Close');
  }

}
