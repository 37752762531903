import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()

export class LoaderInterceptor implements HttpInterceptor {
    totalRequests = 0;
    completedRequests = 0;
  
    constructor(private loader: Ng4LoadingSpinnerService) {}
  
    intercept(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
  
        this.loader.show();
        this.totalRequests++;
  
        return next.handle(request).pipe(
            finalize(() => {
    
                this.completedRequests++;
        
                //console.log(this.completedRequests, this.totalRequests);
        
                if (this.completedRequests === this.totalRequests) {
                    this.loader.hide();
                    this.completedRequests = 0;
                    this.totalRequests = 0;
                }
    
            })
        );
    }
}
