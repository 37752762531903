import { Empresa } from './empresa.model';
export class Pessoa {
    
    id: number;
    idDecujo: number;
    nome: string;
    email: string;
    nascimento: Date;
    isExposta: string;
    isAtivo: string;
    idNacionalidade: number;
    rg: string;
    icCnpjCpf: string;
    idEstadoCivil: string;
    isParticipante: string;
    isBeneficiario: string;
    IsResExterior: string;
    cpf: string;
    telefoneResidencial: string;
    telefoneCelular: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    cep: string;
    uf: string;
    pais: string;
    nomeMae: string;
    nomePai: string;
    sexo: string;
    empresa: Empresa;
    inscricao: string;
}
