import { AtendenteStore } from 'app/main/content/shared/store/atendente.store';
import { AtendenteService } from 'app/main/content/shared/services/atendente.service';
import { ConfirmDialogComponent } from './../components/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';

import { Chamado } from '../shared/models/chamado.model';
import { ChamadoDTO } from '../shared/models/chamadoDTO.model';

import { Assunto } from '../shared/models/assunto.model';
import { Area } from '../shared/models/area.model';
import { Situacao } from '../shared/models/situacao.model';

import { ChamadoService } from '../shared/services/chamado.service';
import { AreaService } from '../shared/services/area.service';
import { SituacaoRelacaoService } from '../shared/services/situacaoRelacao.service';

import { ChamadoStore } from '../shared/store/chamado.store';

import { Util } from '../shared/util';
import { AlterarChamado } from '../shared/models/alterarChamado.model';
import { AssuntoService } from '../shared/services/assunto.service';
import { Atendente } from '../shared/models/atendente.model';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { LoginStore } from '../shared/store/login.store';

@Component({
    selector: 'inserir-registro-chamado',
    templateUrl: './inserir-registro-chamado.component.html',
    styleUrls: ['./inserir-registro-chamado.component.scss'],
})

export class InserirRegistroChamadoComponent implements OnInit {

    date = new FormControl();
    // step = 0;
    chamado: Chamado = new Chamado();
    novoChamado: Chamado = new Chamado();
    assunto: Assunto = new Assunto();
    alterarChamado: AlterarChamado = new AlterarChamado();
    areas: Area[] = [];
    situacoes: Situacao[] = [];
    selectedArea: number;
    delegarChamado: boolean = false;
    dataPrevistaConclusao: Date;
    selectedAssunto: number;
    selectedAtendente: number;
    selectedSituacao: number;
    detalhamento: string = "";
    assuntos: Assunto[];
    atendentes: Atendente[];
    atendente = new Atendente();
    salvando: boolean;
    chamadoStatus: ChamadoStatus = new ChamadoStatus();
    chamadoDTO: ChamadoDTO = new ChamadoDTO();
    novoChamadoDTO: ChamadoDTO = new ChamadoDTO();
    concluirChamado: boolean;
    chamadoId: number;
    maxLength: number = 4000;

    constructor(
        private assuntoService: AssuntoService,
        public dialog: MatDialog,
        private readonly router: Router,
        private readonly situacaoRelacaoService: SituacaoRelacaoService,
        private chamadoService: ChamadoService,
        private chamadoStatusService: ChamadoStatusService,
        private readonly areaService: AreaService,
        private readonly atendenteService: AtendenteService,
        private readonly chamadoStore: ChamadoStore,
        private readonly loginStore: LoginStore,
        private readonly atendenteStore: AtendenteStore,
        private snackBar: MatSnackBar,
        private util: Util
    ) {
        this.salvando = false;
        this.atendenteStore.getCurrent().subscribe(atendente => {
            if (atendente) {
                this.atendente = atendente;
                this.selectedArea = this.atendente.area.id;
                this.areaChange(this.selectedArea);
            }
        });


    }

    ngOnInit() {
        this.loadChamado()
        this.loadAreas()
        this.loadSituacoes()
    }

    loadChamado() {
        this.chamadoStore.getCurrent().subscribe(chamado => {
            if (chamado) {
                this.chamado = chamado;
                this.novoChamado = chamado;
                this.dataPrevistaConclusao = this.chamado.dataPrevistaConclusao;
                this.date.setValue(chamado.dataPrevistaConclusao);
            }
        });
    }

    loadSituacoes() {
        this.situacaoRelacaoService.getBySituacaoOrigem(this.chamado.situacao.id).subscribe(situacoesRelacao => {
            situacoesRelacao.forEach(situacaoRelacao => this.situacoes.push(situacaoRelacao.destino))
        })
    }

    // loadAssuntos(){
    //     this.assuntoService.getAll().subscribe(assuntos => {
    //         this.assuntos = assuntos;
    //     });
    // }

    // loadAtendentes(){
    //     this.atendenteService.getAll().subscribe(atendentes => {
    //         this.atendentes = atendentes;
    //     });
    // }

    loadAreas() {
        this.areaService.getAll().subscribe(areas => {
            this.areas = areas;
        });
    }

    areaChange(event) {
        if (event) {
            this.assuntoService.getByArea(event).subscribe(assuntos => {
                this.assuntos = assuntos.sort((a, b) => a.nome.localeCompare(b.nome))
                ;
                // console.log(assuntos);
            });

            this.atendenteService.getAllByAtendenteArea(event).subscribe(atendentes => {
                this.atendentes = atendentes;
            });
        }
    }



    save() {
        this.salvando = true;
        this.validaChamado(resultado => {

            if (resultado) {
                this.chamadoService.getById(this.chamado.id).subscribe(chamado => {

                    this.alterarChamado.chamado = chamado;
                    this.alterarChamado.chamado.descricao = this.detalhamento;

                    if (!this.util.IsNullOrUndefined(this.selectedAssunto)) {
                        this.alterarChamado.chamado.assunto = this.assuntos.find(a => a.id === this.selectedAssunto);
                    }

                    if (!this.util.IsNullOrUndefined(this.selectedAtendente)) {
                        this.alterarChamado.chamado.atendente = this.atendentes.find(a => a.id === this.selectedAtendente);
                    }

                    if (!this.util.IsNullOrUndefined(this.selectedSituacao)) {
                        this.alterarChamado.chamado.situacao = this.situacoes.find(a => a.id === this.selectedSituacao);
                    }

                    this.chamadoService.alterarChamado(this.alterarChamado).subscribe(() => {
                        this.limpaCampos();
                        this.back();
                        this.salvando = false;
                    }, error => this.salvando = false);


                })

            } else {
                this.salvando = false;
            }
        });

    }



    newChamado() {
        this.salvando = true;
        // this.snackBar.dismiss();

        this.validaChamado(resultado => {
            if (resultado) {

                this.novoChamadoDTO = new ChamadoDTO();
                this.novoChamadoDTO.chamado = this.novoChamado;
                this.novoChamadoDTO.chamado.descricao = this.detalhamento;
                this.novoChamadoDTO.atendente = new Atendente();
                this.novoChamadoDTO.atendente = this.atendente;

                if (!this.util.IsNullOrUndefined(this.selectedAssunto)) {
                    this.novoChamadoDTO.chamado.assunto = this.assuntos.find(a => a.id === this.selectedAssunto);
                }
                if (!this.util.IsNullOrUndefined(this.selectedAtendente)) {
                    this.novoChamadoDTO.chamado.atendente = this.atendentes.find(a => a.id === this.selectedAtendente);
                }

                if (!this.util.IsNullOrUndefined(this.selectedSituacao)) {
                    this.novoChamadoDTO.chamado.situacao = this.situacoes.find(a => a.id === this.selectedSituacao);
                }


                this.chamadoService.novoChamado(this.novoChamadoDTO).subscribe(chamado => {
                    this.salvando = false;
                    this.limpaCampos();

                }, error => {
                    this.showSnackBar(error);
                    this.salvando = false
                });

            } else {
                this.salvando = false
            }
        });
    }

    validaChamado(callback) {
        if (this.delegarChamado) {
            if (this.util.IsNullOrUndefined(this.selectedAtendente)) {
                this.showSnackBar('É necessário escolher um atendente para delegar o chamado.');
                callback(false);
            }
            else if (this.util.IsNullOrUndefined(this.selectedAssunto)) {
                this.showSnackBar('É necessário escolher um assunto para delegar o chamado.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.detalhamento)) {
                this.showSnackBar('É necessário escolher uma descrição para o chamado.');
                callback(false);
            }
            else {
                callback(true);
            }
        }
        else if (this.util.IsNullOrUndefined(this.alterarChamado.detalhamento) && this.dataPrevistaConclusao !== this.chamado.dataPrevistaConclusao) {
            this.showSnackBar('Alteração de Data Prevista de Conclusão exige que uma justificativa seja feita no campo de detalhamento.');
            callback(false);
        } else if (this.util.IsNullOrUndefined(this.detalhamento)) {
            this.showSnackBar('É necessário escolher uma descrição para o chamado.');
            callback(false);
        } else {
            callback(true);
        }
    }

    showSnackBar(message: string): void {
        this.snackBar.open(message, 'Close');
    }

    showMessage(message: string, delegarChamado: boolean): void {

        this.limpaCampos();

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: { message: message, delegarChamado: delegarChamado }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                this.back();
            }

        });
    }

    limpaCampos() {
        this.delegarChamado = false;
        this.selectedAssunto = undefined;
        this.selectedAtendente = undefined;
        this.selectedSituacao = undefined;
        this.detalhamento = undefined;
    }

    back(): void {
        this.router.navigate(['/meus-atendimentos-app']);
    }

    backToDetalhe(): void {
        this.router.navigate(['chamado-detalhe-app']);
    }

    historicoChamado(): void {
        this.router.navigate(['historico-chamado-app']);
    }

}
