import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroLoginComponent } from './cadastro-login.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CadastroLoginComponent]
})
export class CadastroLoginModule { }
