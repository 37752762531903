import { FormGroup, FormBuilder } from '@angular/forms';
import { TempoServico } from './../../shared/models/tempoServico.model';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PessoaService } from '../../shared/services/pessoa.service';
import { Util } from '../../shared/util';

@Component({
  selector: 'app-tempo-servico',
  templateUrl: './tempo-servico.component.html',
  styleUrls: ['./tempo-servico.component.scss']
})
export class TempoServicoComponent {
  public message: string;
  tempoServico: TempoServico[] = [];
  total: string;

  displayedColumns: string[] = [ 'nome', 'dtinicio', 'dtdesligamento'];

  constructor(private readonly pessoaService: PessoaService,    
              private util: Util,
              public dialogRef: MatDialogRef<TempoServicoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any)
{
    this.message = data.message;
    this.pessoaService.getTempoServico(data.idPessoa).subscribe(listaTempoServico => {
        this.tempoServico = listaTempoServico;
        this.total = this.tempoServico[this.tempoServico.length - 1].total;
        console.log(this.tempoServico);
    });
}

}
