import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewLoginComponent } from './new-login.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule, MatInputModule, MatButtonModule } from '@angular/material';
import { FuseWidgetModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
const routes = [
  {
    path: 'new-login',
    component: NewLoginComponent,
    canActivate: [AuthGuard]
  }

];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    FuseWidgetModule,
    TranslateModule,
    FuseSharedModule
  ],
  declarations: [NewLoginComponent],
  exports: [NewLoginComponent]
})
export class NewLoginModule { }
