import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { CalendarAppComponent } from '../calendar/calendar-app.component';
import { CanalAppComponent } from '../canalApp/canal-app.component';
import { UploadAppComponent } from '../uploadApp/upload-app.component';

@Component({
    selector: 'output-app',
    templateUrl: './output-app.component.html',
    styleUrls: ['./output-app.component.scss']
})

export class OutPutAppComponent {


    constructor(public dialog: MatDialog, private router:Router ) {
     
      
    }


    newApp():void {
    this.router.navigate(["create-app"]);
    }
    
    
}