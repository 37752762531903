import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSituacaoComponent } from './new-situacao.component';
import { MatIconModule, MatTableModule, MatButtonModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatDialogModule, MatPaginatorModule, MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseWidgetModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthGuard } from '../../shared/guards/auth.guard';


const routes = [
  {
      path: 'new-situacao',
      component: NewSituacaoComponent,
      data: {
          breadcrumb: '!'
      },
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    NgxDatatableModule,
    MatButtonModule,    
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCardModule,
    MatTableModule,
    FuseWidgetModule,
    TranslateModule,
    FuseSharedModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [NewSituacaoComponent],
    exports: [
      NewSituacaoComponent
    ]
})
export class NewSituacaoModule { }
