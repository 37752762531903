import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { RouterModule } from'@angular/router';
import { UploadAppComponent } from './upload-app.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'upload-app',
        component: UploadAppComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        UploadAppComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
    
        MatIconModule,
        MatInputModule,
        MatButtonModule,

        MatDialogModule
    ]

})
export class UploadAppModule { }