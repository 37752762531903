import { FiltroDetalheModule } from './main/content/filtro-detalhe/filtro-detalhe.module';
import { CadastroLoginModule } from './main/content/cadastro-login/cadastro-login.module';
import { NewLoginModule } from './main/content/cadastro-login/new-login/new-login.module';
import { LoginModule } from './login/login.module';
import { NewSituacaoModule } from './main/content/cadastro-situacao/new-situacao/new-situacao.module';
import { CadastroSituacaoModule } from './main/content/cadastro-situacao/cadastro-situacao.module';
import { NewAssuntoModule } from './main/content/cadastro-assunto/new-assunto/new-assunto.module';
import { CadastroAssuntoModule } from './main/content/cadastro-assunto/cadastro-assunto.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule, MatTableModule, MAT_DATE_LOCALE } from '@angular/material';
import 'hammerjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from './fuse-config';
import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { MatSelectModule } from '@angular/material/select';
import { DialogModule } from './main/content/components/message/dialog.module';
import { ErrorPageModule } from './main/content/components/errors/errorpage.module';
import { ProgressSpinnerModule } from './main/content/components/progress-spinner/progress-spinner.module';
import { Ng4LoadingSpinnerModule, Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatDialogModule, MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { ProjectModule } from './main/content/project/project.module';
import { FuseWidgetModule } from '@fuse/components';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CalendarAppModule } from './main/content/calendar/calendar-app.module';
import { CanalAppModule } from './main/content/canalApp/canal-app.module';
import { UploadAppModule } from './main/content/uploadApp/upload-app.module';

// import 'hammerjs';
import { OutPutAppModule } from './main/content/outputApp/output-app.module';
import { PermissionAppModule } from './main/content/permission/permission-app.module';
import { ConfigAdminModule } from './main/content/configAdmin/config-admin.module';
import { MeusAtendimentosAppModule } from './main/content/meusAtendimentos/meus-atendimentos-app.module';
import { TodosAtendimentosAppModule } from './main/content/todos-atendimentos/todos-atendimentos-app.module';


import { CadastroAreaModule } from './main/content/cadastro-area/cadastro-area.module';
import { NewAreaModule } from './main/content/cadastro-area/new-area/new-area.module';
import { CadastroCanalModule } from './main/content/cadastro-canal/cadastro-canal.module';
import { NewCanalModule } from './main/content/cadastro-canal/new-canal/new-canal.module';
import { CadastroAtendenteModule } from './main/content/cadastro-atendente/cadastro-atendente.module';
import { NewAtendenteModule } from './main/content/cadastro-atendente/new-atendente/new-atendente.module';

import { AtendentesAppModule } from './main/content/atendentes/atendentes-app.module';
import { CadastroAtendenteAppModule } from './main/content/cadastro-atendente/cadastro-atendente-app.module';
import { ChamadoDetalheAppModule } from './main/content/chamado-detalhe/chamado-detalhe-app.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CadastrarChamadoAppModule } from './main/content/cadastrar-chamado/cadastrar-chamado-app.module';
import { HistoricoChamadoAppModule } from './main/content/historicoChamado/historico-chamado-app.module';
import { ChamadoDetalheTmpAppModule } from './main/content/chamado-detalhe-tmp/chamado-detalhe-tmp.module';
import { ChamadoParticipanteAppModule } from './main/content/chamados-participante/chamados-participante-app.module';
import { InserirRegistroChamadoModule } from './main/content/inserir-registro-chamado/inserir-registro-chamado.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { JwtInterceptor } from './main/content/shared/helper/jwt.interceptor';
import { UsuarioDetalheModule } from './main/content/usuario-detalhe/usuario-detalhe.module';
import { ConfirmDialogModule } from './main/content/components/confirm-dialog/confirm-dialog.module';
import { LoaderInterceptor } from './main/content/shared/helper/loader.interceptor';
import { CadastroIntegracaoModule } from './main/content/cadastro-integracao/cadastro-integracao.module';
import { NewIntegracaoModule } from './main/content/cadastro-integracao/new-integracao/new-integracao.module';

const appRoutes: Routes = [
    {
        path: 'app',
        redirectTo: 'login'
    }
];

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        MatDialogModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,
        DialogModule,
        ErrorPageModule,
        ProgressSpinnerModule,
        Ng4LoadingSpinnerModule.forRoot(),
        ProjectModule,
        FuseWidgetModule,
        CalendarAppModule,
        CanalAppModule,
        UploadAppModule,
        MatTableModule,
        OutPutAppModule,
        MatDatepickerModule,
        PermissionAppModule,
        ConfigAdminModule,
        MatFormFieldModule,
        MatTableModule,
        CanalAppModule,
        FormsModule,
        MeusAtendimentosAppModule,
        TodosAtendimentosAppModule,
        CadastroAtendenteModule,
        MatSelectModule,
        MatExpansionModule,
        CadastroAreaModule,
        NewAreaModule,
        CadastroCanalModule,
        NewCanalModule,
        CadastroAtendenteModule,
        NewAtendenteModule,
        AtendentesAppModule,
        CadastroAtendenteAppModule,
        ChamadoDetalheAppModule,
        FroalaEditorModule,
        FroalaViewModule,
        CadastrarChamadoAppModule,
        HistoricoChamadoAppModule,
        MatTooltipModule,
        ChamadoDetalheTmpAppModule,
        InserirRegistroChamadoModule,
        CadastroAssuntoModule,
        NewAssuntoModule,
        CadastroSituacaoModule,
        NewSituacaoModule,
        LoginModule,
        UsuarioDetalheModule,
        NewLoginModule,
        CadastroLoginModule,
        FiltroDetalheModule,
        ConfirmDialogModule,
        MatSnackBarModule,
        ChamadoParticipanteAppModule,
        CadastroIntegracaoModule,
        NewIntegracaoModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        Ng4LoadingSpinnerService
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule {

}
