import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatDialogModule, MatPaginatorModule, MatCardModule, MatTableModule, MatSlideToggleModule } from '@angular/material';
import { FuseWidgetModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { NewIntegracaoComponent } from './new-integracao.component';

const routes = [
  {
      path: 'new-integracao',
      component: NewIntegracaoComponent,
      data: {
          breadcrumb: '!'
      },
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NgxDatatableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCardModule,
    MatTableModule,
    FuseWidgetModule,
    TranslateModule,
    FuseSharedModule,
    MatSlideToggleModule
  ],
  declarations: [
    NewIntegracaoComponent
  ],
    exports: [
        NewIntegracaoComponent
    ]
})
export class NewIntegracaoModule { }
