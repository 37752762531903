import { Chamado } from "./chamado.model";

export class FiltroChamado {
    
    descricao: string;
    tipoFiltro: string;
    currentPage: number
    pageSize: number
    
}


export class FiltrarChamadoRequest {
    nome?: string
    cpf?: string
    situacao?: number
    dataAbertura?: Date
    atendente?: number
    currentPage: number
    pageSize: number
}

export class FiltrarChamadoResponse {
    chamados: Chamado[]
    total: number
}