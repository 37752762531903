import { Injectable } from '@angular/core';
import { Situacao } from '../models/situacao.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Util } from '../util';
import { Router } from '@angular/router';
import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { SituacaoRelacaoService } from './situacaoRelacao.service';

@Injectable()
export class SituacaoChamadoService extends ApiService implements BaseService<Situacao> {    

    private strAction = 'Situacao';
    
    constructor(
        httpClient: HttpClient,
        private util: Util,
        private situacaoRelacaoService: SituacaoRelacaoService,
        private route: Router) {
        super(httpClient, route);
        }

    getSituacaoNome(situacaoId: number): string {
        const situacao = this.getById(situacaoId);
        let nome = '';

        situacao.subscribe(sit => {
            nome = sit.nome;
        },
        err => {
            nome = 'Situação não encontrada.';
        });

        return nome;
    }

    getSituacoesPossiveis(situacao: number): Situacao[] {
        const situacoesPossiveis: Situacao[] = [];

        this.situacaoRelacaoService.getBySituacaoOrigem(situacao).subscribe(situacaoRelacao => {
            for (const situacaorelacao of situacaoRelacao){
                situacoesPossiveis.push(situacaorelacao.destino);
            }
        });

        // if (situacao === enumSituacaoChamado.Pendente){
        //     situacoesPossiveis.push(enumSituacaoChamado.EmAtendimento);
        // }
        // else if (situacao === enumSituacaoChamado.EmAtendimento) {
        //     situacoesPossiveis.push(enumSituacaoChamado.Concluido);
        //     situacoesPossiveis.push(enumSituacaoChamado.Cancelado);
        // }
        // else if (situacao === enumSituacaoChamado.Concluido) {
        //     situacoesPossiveis.push(enumSituacaoChamado.Reaberto);
        // }
        // else if (situacao === enumSituacaoChamado.Cancelado) {
        //     situacoesPossiveis.push(enumSituacaoChamado.Reaberto);
        // }
        // else if (situacao === enumSituacaoChamado.Reaberto) {
        //     situacoesPossiveis.push(enumSituacaoChamado.EmAtendimento);
        // }
        return situacoesPossiveis;
    }

    getComboSituacoesPossiveis(situacao: number): Situacao[] {
        const situacoesPossiveis: Situacao[] = [];

        this.situacaoRelacaoService.getBySituacaoOrigem(situacao).subscribe(situacaoRelacao => {
            for (const situacaorelacao of situacaoRelacao){
                situacoesPossiveis.push(situacaorelacao.destino);
            }
        });




        // if (situacao === enumSituacaoChamado.Pendente){
        //     const newSit: Situacao = { id: enumSituacaoChamado.EmAtendimento, nome: 'Em atendimento', email: 0};
        //     situacoesPossiveis.push(newSit);
        // }
        // else if (situacao === enumSituacaoChamado.EmAtendimento) {
        //     const newSit1: Situacao = { id: enumSituacaoChamado.Concluido, nome: enumSituacaoChamado[enumSituacaoChamado.Concluido], email: 1};
        //     situacoesPossiveis.push(newSit1);
        //     const newSit2: Situacao = { id: enumSituacaoChamado.Cancelado, nome: enumSituacaoChamado[enumSituacaoChamado.Cancelado], email: 0};
        //     situacoesPossiveis.push(newSit2);
        // }
        // else if (situacao === enumSituacaoChamado.Concluido) {
        //     const newSit: Situacao = { id: enumSituacaoChamado.Reaberto, nome: enumSituacaoChamado[enumSituacaoChamado.Reaberto], email: 1};
        //     situacoesPossiveis.push(newSit);
        // }
        // else if (situacao === enumSituacaoChamado.Cancelado) {
        //     const newSit: Situacao = { id: enumSituacaoChamado.Reaberto, nome: enumSituacaoChamado[enumSituacaoChamado.Reaberto], email: 0};
        //     situacoesPossiveis.push(newSit);
        // }
        // else if (situacao === enumSituacaoChamado.Reaberto) {
        //     const newSit: Situacao = { id: enumSituacaoChamado.EmAtendimento, nome: 'Em atendimento', email: 1};
        //     situacoesPossiveis.push(newSit);
        // }
        return situacoesPossiveis;
    }

    deleteById(situacaoId: number) {
        return this.post(this.strAction + `/DeleteById?id=${situacaoId}`);
    }

    save(situacao: Situacao): Observable<Situacao> {
        if (this.util.IsNullOrUndefined(situacao.id) || situacao.id === 0){
            return this.post<Situacao>(this.strAction + `/Post`, situacao);        
        }        
        return this.post<Situacao>(this.strAction + `/Put`, situacao);        
    }

    getAll(): Observable<Situacao[]> {
        return this.get<Situacao[]>(this.strAction + `/GetAll`);
    }

    getById(situacaoId: number): Observable<Situacao> {
        return this.get<Situacao>(this.strAction + `/GetById/ ${situacaoId}`);
    }

    getFiltroByNome(nome: string): Observable<Situacao> {
        return this.get<Situacao>(this.strAction + `/GetFiltroByNome?pNome=${nome}`);
    }
    
}
