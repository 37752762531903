import { TempoServico } from './../models/tempoServico.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';

import { BaseService } from '../../../../core/base.service';

import { Pessoa } from '../models/pessoa.model';
import { Plano } from '../models/plano.model';
import { PerfilInvestimento } from '../models/perfilInvestimento.model';
import { DadosResumo } from '../models/dadosResumo.model';
import { Saldo } from '../models/saldo.model';
import { Contribuicao } from '../models/contribuicao.model';
import { Beneficio } from '../models/beneficio.model';
import { TipoBeneficio } from '../models/tipoBeneficio.model';
import { TipoFolha } from '../models/tipoFolha.model';

import { Util } from '../util';
import { Emprestimo } from '../models/emprestimo.model';

@Injectable()
export class PessoaService extends ApiService implements BaseService<Pessoa> {

    private strAction = 'Pessoa';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    criar(pessoa: Pessoa): Observable<Pessoa> {
        return this.post<Pessoa>(this.strAction + `/PostInsertPessoa`, pessoa);
    }

    getAll(): Observable<Pessoa[]> {
        return this.get<Pessoa[]>(this.strAction + `/GetAll`);
    }

    getById(pessoaId: number): Observable<Pessoa> {
        return this.get<Pessoa>(this.strAction + `/GetById/${pessoaId}`);
    }

    getByEmail(pessoaEmail: string): Observable<Pessoa> {
        return this.get<Pessoa>(this.strAction + `/GetByEmail/${pessoaEmail}`);
    }

    getByCPF(pessoaCPF: string): Observable<Pessoa> {
        return this.get<Pessoa>(this.strAction + `/GetByCPF/${pessoaCPF}`);
    }

    getListaPessoaByCPF(pessoaCPF: string): Observable<Pessoa[]> {
        return this.get<Pessoa[]>(this.strAction + `/GetListaPessoaByCPF/${pessoaCPF}`);
    }

    getByIdPessoa(pessoaId: number): Observable<Pessoa> {
        return this.get<Pessoa>(this.strAction + `/GetByIdPessoa/${pessoaId}`);
    }

    getByIdPessoaFromBeneficiario(pessoaId: number): Observable<Pessoa> {
        return this.get<Pessoa>(this.strAction + `/GetByIdPessoaFromBeneficiario/${pessoaId}`);
    }

    getTempoServico(pessoaId: number): Observable<TempoServico[]> {
        return this.get<TempoServico[]>(this.strAction + `/GetTempoServico/${pessoaId}`);
    }

    getPlanos(pessoaId: number): Observable<Plano[]> {
        return this.get<Plano[]>(this.strAction + `/GetPlanos/${pessoaId}`);
    }

    getPerfilInvestimento(pessoaId: number, planoId: number): Observable<PerfilInvestimento> {
        return this.get<PerfilInvestimento>(this.strAction + `/GetDadosPerfil/${pessoaId}/${planoId}`);
    }

    // Contribuicão
    getDadosResumo(pessoaId: number, planoId: number): Observable<DadosResumo> {
        const pageNumber = 1;
        const pageSize = 1;
        // Pegando 1 página de 1 para simular o TOP 1 da Consulta que não foi implementado na API
        return this.get<DadosResumo>(this.strAction + `/GetDadosResumo/${pessoaId}/${planoId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    getSaldo(pessoaId: number, planoId: number): Observable<Saldo[]> {
        return this.get<Saldo[]>(this.strAction + `/GetSaldo/${pessoaId}/${planoId}`);
    }

    getSaldoCompleto(pessoaId: number, planoId: number): Observable<Saldo[]> {
        return this.get<Saldo[]>(this.strAction + `/GetSaldoCompleto/${pessoaId}/${planoId}`);
    }

    getHistoricoContribuicao(pessoaId: number, planoId: number): Observable<Contribuicao[]> {
        return this.get<Contribuicao[]>(this.strAction + `/GetHistoricoContribuicao/${pessoaId}/${planoId}`);
    }

    // Benefício
    getBeneficio(pessoaId: number): Observable<Beneficio[]> {
        return this.get<Beneficio[]>(this.strAction + `/GetBeneficio/${pessoaId}`);
    }

    getTipoBeneficio(beneficioId: number): Observable<TipoBeneficio> {
        return this.get<TipoBeneficio>(this.strAction + `/GetTipoBeneficio/${beneficioId}`);
    }

    getTipoFolha(pessoaId: number): Observable<TipoFolha> {
        return this.get<TipoFolha>(this.strAction + `/GetTipoFolha/${pessoaId}`);
    }

    getValorSuplementacao(beneficioId: number): Observable<number> {
        return this.get<number>(this.strAction + `/GetValorSuplementacao/${beneficioId}`);
    }

    getValorBPA(pessoaId: number): Observable<number> {
        return this.get<number>(this.strAction + `/GetValorBPA/${pessoaId}`);
    }

    getPercentualRenda(pessoaId: number, beneficioId: number): Observable<number> {
        return this.get<number>(this.strAction + `/GetPercentualRenda/${pessoaId}/${beneficioId}`);
    }

    
    deleteById(pessoaId: number) {
        return this.post(this.strAction + `/DeleteById?id=${pessoaId}`);
    }

    save(pessoa: Pessoa): Observable<Pessoa> {
        if (this.util.IsNullOrUndefined(pessoa.id) || pessoa.id === 0){
            return this.post<Pessoa>(this.strAction + `/Post`, pessoa);        
        }        
        return this.post<Pessoa>(this.strAction + `/Put`, pessoa);        
    }

    // Emprestimo

    getAllContratosEmprestimo(pessoaId: number): Observable<Emprestimo[]> {
        return this.get<Emprestimo[]>(this.strAction + `/getAllContratosEmprestimo/${pessoaId}`);
    }



}
