import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { AtendenteCanal } from '../models/atendenteCanal.model';
import { AtendenteCanalSaveDTO } from '../models/atendenteCanalSaveDTO.model';
// import { Atendente } from '../models/atendente.model';
import { Util } from '../util';

@Injectable()
export class AtendenteCanalService extends ApiService implements BaseService<AtendenteCanal> {

    private strAction = 'AtendenteCanal';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<AtendenteCanal[]> {
        return this.get<AtendenteCanal[]>(this.strAction + `/GetAll`);
    }

    getById(atendenteId: number): Observable<AtendenteCanal> {
        return this.get<AtendenteCanal>(this.strAction + `/GetById/${atendenteId}`);
    }

    getAllByAtendenteId(atendenteId: number): Observable<AtendenteCanal[]> {
        return this.get<AtendenteCanal[]>(this.strAction + `/GetAllAtendenteId?atendenteId=${atendenteId}`);
    }

    deleteById(atendenteCanalId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Access-Control-Allow-Origin', '*');
        return this.post(this.strAction + `/DeleteById?id=${atendenteCanalId}`);
    }
    
    deleteByAtendenteId(atendenteId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Access-Control-Allow-Origin', '*');
        return this.post(this.strAction + `/DeleteByAtendenteId/${atendenteId}`);
    }

    saveCanais(atendenteCanaisSaveDTO: AtendenteCanalSaveDTO): Observable<AtendenteCanalSaveDTO> {

        // if (this.util.IsNullOrUndefined(atendenteCanal.id) || atendenteCanal.id == 0){
            return this.post<AtendenteCanalSaveDTO>(this.strAction + `/PostAtendenteCanais`, atendenteCanaisSaveDTO);        
        // }        
        // return this.post<AtendenteCanalSaveDTO>(this.strAction + `/Put`, JSON.stringify(atendenteCanal), { headers: headers });        
    }

    save(atendenteCanal: AtendenteCanal): Observable<AtendenteCanal> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Access-Control-Allow-Origin', '*');

        if (this.util.IsNullOrUndefined(atendenteCanal.id) || atendenteCanal.id === 0){
            return this.post<AtendenteCanal>(this.strAction + `/Post`, atendenteCanal);        
        }        
        return this.post<AtendenteCanal>(this.strAction + `/Put`, atendenteCanal);        
    }
}
