import { Assunto } from './../models/assunto.model';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AssuntoStore {

    private Subject = new BehaviorSubject<Assunto>(null);
    assunto = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Assunto> {
        return this.assunto;
    }

    update(assunto: Assunto) {
        this.Subject.next(assunto);
    }
}
