import { Component, Injectable, Input } from '@angular/core';

@Component({
    selector: 'progress-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls: ['./progress-spinner.component.scss']
})

@Injectable( {providedIn: 'root'})
export class ProgressSpinnerComponent {
    
    @Input() show ;
    constructor() {
                
    }
}
