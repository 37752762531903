import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Canal } from '../models/canal.model';

@Injectable()
export class CanalStore {

    private subject = new BehaviorSubject<Canal>(null);
    canal = this.subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Canal> {
        return this.canal;
    }

    update(canal: Canal) {
        this.subject.next(canal);
    }
}