import { Router } from '@angular/router';
import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { MatTableModule} from '@angular/material/table';
// import { ConfigAdminComponent } from '../configAdmin/config-admin.component';

import { DialogComponent } from '../components/message/dialog.component';

// import { NewAreaComponent } from '../cadastro-area/new-area/new-area.component';
// import { MatFormFieldModule } from '@angular/material/form-field';
import { Area } from '../shared/models/area.model';
import { AreaService } from '../shared/services/area.service';
import { AreaStore } from '../shared/store/area.store';

@Component({
    selector: 'cadastro-area',
    templateUrl: './cadastro-area.component.html',
    styleUrls: ['./cadastro-area.component.scss']    
})

export class CadastroAreaComponent implements OnInit {
    areaFilter: any = [];
    areas: Area[];
    filteredAreas: Area[];
    orderDirection: boolean = true;
    sortField: string;

    displayedColumns: string[] = ['id', 'nome', 'retaguarda', 'primeiroNivel', 'acoes'];

    constructor(
        private readonly areaService: AreaService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly areaStore: AreaStore ) {
        }
        
    ngOnInit(){            
        this.loadAreas();
    }

    loadAreas(){
        this.areaService.getAll().subscribe(areas => {
            this.filteredAreas = areas;
            this.areas = areas;
            this.areaStore.update(null);
        });
    }

    newArea(): void {
        this.areaStore.update(null);
        this.router.navigate(['new-area']);
    }

    editArea(areaId: number) {
        const area = this.areas.find(a => a.id === areaId);
        this.areaStore.update(area);        
        this.router.navigate(['new-area']);
    }

    deleteArea(areaId) {
        this.areaService.deleteById(areaId).subscribe(
            () => {
                this.showMessage('Área removida.');
            },
            (err) => {
                this.showMessage(err);
            });
    }

    back(): void {
        this.router.navigate(['project']);
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });
    
        dialogRef.afterClosed().subscribe(() => {
            this.loadAreas();
        });
    }
}
