import { SituacaoRelacaoSaveDTO } from './../../shared/models/situacaoRelacaoSaveDTO.models';
import { SituacaoRelacao } from './../../shared/models/situacaoRelacao.model';
import { SituacaoRelacaoService } from './../../shared/services/situacaoRelacao.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Situacao } from '../../shared/models/situacao.model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SituacaoChamadoService } from '../../shared/services/situacaoChamado.service';
import { SituacaoStore } from '../../shared/store/situacao.store';
import { Util } from '../../shared/util';
import { DialogComponent } from '../../components/message/dialog.component';

@Component({
  selector: 'app-new-situacao',
  templateUrl: './new-situacao.component.html',
  styleUrls: ['./new-situacao.component.scss']
})
export class NewSituacaoComponent implements OnInit {

  formErrors: any;
  id: number;
  enviarEmail: boolean;
  situacoes: Situacao[];
  selectedItems = [];
  selectedSituacoes: Situacao[] = [];
  ShowFilter = false;
  dropdownSettings: any = {};
  situacao: Situacao = new Situacao();
  @Input()
  form: FormGroup;
  situacaoFluxo: SituacaoRelacao;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly situacaoService: SituacaoChamadoService,
    private situacaoRelacaoService: SituacaoRelacaoService,
    private readonly situacaoStore: SituacaoStore,
    private snackBar: MatSnackBar,
    private util: Util
  ) {

    this.situacaoStore.getCurrent().subscribe(situacao => {
      if (situacao) {
        this.situacao = situacao;
        console.log(situacao);

        this.loadSituacaoRelacao(situacao.id);

        this.enviarEmail = false;
        if (this.situacao.email === 1) {
          this.enviarEmail = true;
        }
      }
      else {
        this.situacao = new Situacao();
        this.enviarEmail = false;
      }

      this.loadSituacoes();

      this.formErrors = {
        Nome: {},
        enviarEmail: {}
      };
    });
  }

  ngOnInit() {
    this.selectedItems = this.selectedSituacoes;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nome',
      selectAllText: 'Marca todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 6,
      allowSearchFilter: this.ShowFilter
    };


    this.form = this.formBuilder.group({
      Nome: ['', Validators.required],
      enviarEmail: ['', Validators.required],
      Situacoes: [this.selectedItems]
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormValuesChanged();
    });
  }

  loadSituacoes() {
    this.situacaoService.getAll().subscribe(situacoes => {
      this.situacoes = situacoes;
    });
  }
  onFormValuesChanged() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};

      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  loadSituacaoRelacao(situacaoId: number) {
    this.situacaoRelacaoService.getBySituacaoOrigem(situacaoId).subscribe(situacaoRelacao => {
      this.selectedItems = situacaoRelacao
        .filter(x => x.origem.id === situacaoId)
        .map(c => {
          return {
            id: c.destino.id,
            nome: c.destino.nome,
            // prazo: c.canal.prazo
          };
        });
    });
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  save() {
    console.log('salvando');
    let updateSituacaoId = 0;
    if (!this.util.IsNullOrUndefined(this.situacao)) {
      updateSituacaoId = this.situacao.id;
    }
    this.validaSituacao(resultado => {
      if (resultado) {
        const vEmail = this.form.controls.enviarEmail.value;

        if (vEmail) {
          this.situacao.email = 1;
        } else {
          this.situacao.email = 0;
        }
        this.situacaoService.save(this.situacao).subscribe(situacao => {
          const situacaoRelacaoSaveDTO: SituacaoRelacaoSaveDTO = new SituacaoRelacaoSaveDTO();
          // Se for alteração de situacao
          if ((!this.util.IsNullOrUndefined(updateSituacaoId)) && (updateSituacaoId !== 0)) {
            situacaoRelacaoSaveDTO.situacaoId = updateSituacaoId;
          } else {
            console.log('situacaoID retornado: ', situacao.id);
            situacaoRelacaoSaveDTO.situacaoId = situacao.id;
          }
          const tmpSituacoes: number[] = [];
          for (const item of this.selectedItems) {
            tmpSituacoes.push(item.id);
          }
          situacaoRelacaoSaveDTO.situacaoRelacaoIds = tmpSituacoes;
          console.log(situacaoRelacaoSaveDTO);

          this.situacaoRelacaoService.saveSituacoes(situacaoRelacaoSaveDTO).subscribe(situacoesRelacao => {
            console.log('Salvou!!');
            const message = this.situacao.id > 0 ? 'Situação atualizada!' : 'Situação criada!';
            this.snackBar.dismiss();
            this.showMessage(message);
          });

        });
      } else {
        console.log('Não salvou!!');
      }
    });
  }

  validaSituacao(callback) {
    if (this.util.IsNullOrUndefined(this.situacao.nome)) {
      this.showSnackBar('O nome da situação é requerido.');
      callback(false);
    } else {
      callback(true);
    }
  }

  showSnackBar(message: string): void {
    this.snackBar.open(message, 'Close');
  }

  showMessage(message: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: { message: message }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.back();
    });
  }

  back(): void {
    this.router.navigate(['/cadastro-situacao']);
  }

}
