export const MailAppConfig = {
    settings: {
        layout: {
            style: 'layout1',
            config: {
                scroll: 'content',
                navbar: {
                    display: true,
                    folded: false,
                    position: 'right'
                }
            }
        }
    }

};


export const navigation = [


    {
        'id': 'project',
        'title': 'Painel Principal',
        'translate': 'NAV.PROJECT.TITLE',
        'type': 'item',
        'icon': 'insert_chart_outlined',
        'url': '/project'
    },
    {
        'id': 'Configurations',
        'title': 'Administrativo',
        'translate': 'NAV.ADMINISTRATIVE.TITLE',
        'type': 'collapse',
        'icon': 'group',
        'children': [
            {
                'id': 'vessel',
                'title': 'Cadastro de Áreas',
                'type': 'item',
                'url': '/cadastro-area',
                'icon': 'add_to_photos'
            },
            
            {
                'id': 'atendente',
                'title': 'Cadastro de Atendentes',
                'type': 'item',
                'url': 'cadastro-atendente',
                'icon': 'add_to_photos'
            },
            {
                'id': 'canal',
                'title': 'Cadastro de Canais',
                'type': 'item',
                'url': '/cadastro-canal',
                'icon': 'add_to_photos'
            },
            {
                'id': 'assuntos',
                'title': 'Cadastro de Assuntos',
                'type': 'item',
                'url': '/cadastro-assunto',
                'icon': 'add_to_photos'
            },
            {
                'id': 'situacoes',
                'title': 'Cadastro de Situações',
                'type': 'item',
                'url': '/cadastro-situacao',
                'icon': 'add_to_photos'
            },
            {
                'id': 'integracoes',
                'title': 'Cadastro de Integrações',
                'type': 'item',
                'url': '/cadastro-integracao',
                'icon': 'add_to_photos'
            }
        ]

    },
    // {
    //     'id': 'logout',
    //     'title': 'Sair',
    //     'type': 'item',
    //     'function': () => {
    //         window.location.replace('/login');
    //     },
    //     'icon': 'exit_to_app'
    // }

];
