import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule } from '@angular/material'; 
import { MatTabsModule } from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FroalaEditorModule , FroalaViewModule } from 'angular-froala-wysiwyg';
import { CadastrarChamadoAppComponent } from './cadastrar-chamado-app.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'cadastrar-chamado-app',
        component: CadastrarChamadoAppComponent,
        canActivate: [AuthGuard]
    }

];

@NgModule({
    declarations: [
        CadastrarChamadoAppComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatRadioModule,
        MatCheckboxModule,
        FroalaEditorModule,
        FroalaViewModule,
        TextMaskModule,
        Ng4LoadingSpinnerModule
        
    ],
    exports: [
        CadastrarChamadoAppComponent
    ]

})
export class CadastrarChamadoAppModule { }