import { AuthenticationService } from './authentication.service';
import { Login } from './../models/login.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Util } from '../util';
import { LoginNewPassword } from '../models/loginNewPassword.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ApiService implements BaseService<Login> {

  private strAction = 'Login';
  
  constructor(
              httpClient: HttpClient,
              private util: Util,
              private route: Router,
              private auth: AuthenticationService) {
              super(httpClient, route); 
              }
  
  getAll(): Observable<Login[]> {
    return this.get<Login[]>(this.strAction + `/GetAll`);
  }

    
  getAllByAtendenteLogin(): Observable<Login[]> {
    return this.get<Login[]>(this.strAction + `/GetAllByAtendenteLogin`);
  }
  
  GetFiltroByLogin(login: any): Observable<Login[]>{
    return this.get<Login[]>(this.strAction + `/GetFiltroByLogin?pLogin=${login}`);
  }
  

  getById(loginId: string): Observable<Login> {
    return this.get<Login>(this.strAction + `/GetById/${loginId}`);
  }

  deleteById(LoginId: number) {
    return this.post(this.strAction + `/DeleteById?id=${LoginId}`);
    // return this.delete(this.strAction + `?id=${LoginId}`, { headers: headers });
  }

  deleteLogin(login: Login) {
    // return this.delete(this.strAction + `/Delete`, JSON.stringify(Login), { headers: headers });
    return this.post(this.strAction + `/Delete`, login);
  }

  save(login: Login): Observable<Login> {
    if (this.util.IsNullOrUndefined(login.idLogin) || login.idLogin === ''){
        return this.post<Login>(this.strAction + `/Post`, Login);        
    }        
    return this.post<Login>(this.strAction + `/Put`, Login);        
  }

  alteraSenha(senha: LoginNewPassword): Observable<LoginNewPassword> {      
    return this.put<LoginNewPassword>(this.strAction + `/AlterarSenha`, senha);        
  }

  autenticar(user: string, password: string) {
    return this.auth.retornaToken(user, password);
  }

  logout() {
    return this.auth.logout();
  }
}
