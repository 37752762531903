import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Resumo } from "../models/resumo.model";
@Injectable()
export class ResumoStore {

    private subject = new BehaviorSubject<Resumo[]>(null);
    resumo = this.subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Resumo[]> {
        return this.resumo;
    }

    update (resumo: Resumo[]) {
        this.subject.next(resumo);
    }
}
