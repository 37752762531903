export class Resumo {
    
    pessoaId: number;
    planoId: number;
    contribuicaoId: number;
    anoReferencia: number;
    mesReferencia: number;
    anoCompetencia: number;
    mesCompetencia: number;
    origemId: number;
    perfilId: number;
    valorContribuicao: number;
    quantidadeCota: number;
    quantidadeCotaIsento: number;
    nomePerfil: string;
    nomeContribuicao: string;
    indexador: string;
    dataIndexador: Date;
    valorCota: number;
    dataAporte: Date;
    cotaIr: string;
    quantidadeCotaIrUtilizada: number;
    
}
