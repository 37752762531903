import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Canal } from '../models/canal.model';
import { Util } from '../util';
// import { version } from 'punycode';

@Injectable()
export class CanalService extends ApiService implements BaseService<Canal> {

    private strAction = 'Canal';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Canal[]> {
        return this.get<Canal[]>(this.strAction + `/GetAll`);
    }

    getById(canalId: number): Observable<Canal> {
        return this.get<Canal>(this.strAction + `/GetById/${canalId}`);
    }

    deleteById(canalId: number) {
        // return this.post(this.strAction + `/DeleteById?id=${canalId}`, { headers: headers });
        return this.delete(this.strAction + `?id=${canalId}`);
    }

    save(canal: Canal): Observable<Canal> {
        if (this.util.IsNullOrUndefined(canal.id) || canal.id === 0){
            return this.post<Canal>(this.strAction + `/Post`, canal);        
        }        
        return this.post<Canal>(this.strAction + `/Put`, canal);        
    }
}
