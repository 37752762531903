import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UltimosLancamentosComponent } from './ultimos-lancamentos.component';
import { MatDialogModule, MatButtonModule, MatTableModule, MatIconModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    BrowserModule,
    MatTableModule,
    MatIconModule,
  ],
  declarations: [UltimosLancamentosComponent],
  entryComponents: [
    UltimosLancamentosComponent
 ],
})
export class UltimosLancamentosModule { }
