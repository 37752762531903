export class Contribuicao {
    
    pessoaId: number;
    planoId: number;
    nomeContribuicao: string;
    dataVigencia: Date;
    percContribuicao: number;
    quantidadeParcelas: number;
    optante: string;
    quantidadeTeto: number;
    contribuicaoId: number;

}
