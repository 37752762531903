import { NgModule } from '@angular/core';
import { CadastroAssuntoComponent } from './cadastro-assunto.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule, MatIconModule, MatInputModule, MatButtonModule, MatTableModule, MatDialogModule, MatPaginatorModule, MatFormFieldModule, MatExpansionModule, MatTooltipModule } from '@angular/material';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
  {
      path: 'cadastro-assunto',
      component: CadastroAssuntoComponent,
      canActivate: [AuthGuard]
  }
  
];

@NgModule({
  imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatTooltipModule
  ],
  declarations: [
    CadastroAssuntoComponent
  ]
})
export class CadastroAssuntoModule { }
