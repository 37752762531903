import { Inject, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTable } from '@angular/material';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';

import { DialogComponent } from '../components/message/dialog.component';

// import { Atendente } from '../shared/models/atendente.model';
import { AtendenteDTO } from '../shared/models/atendenteDTO.model';
import { Canal } from '../shared/models/canal.model';
import { AtendenteService } from '../shared/services/atendente.service';
import { AtendenteCanal } from '../shared/models/atendenteCanal.model';
import { AtendenteCanalService } from '../shared/services/atendenteCanal.service';
import { AtendenteStore } from '../shared/store/atendente.store';

@Component({
    selector: 'cadastro-atendente',
    templateUrl: './cadastro-atendente.component.html',
    styleUrls: ['./cadastro-atendente.component.scss'],
})

export class CadastroAtendenteComponent implements OnInit {
    canalFilter: any = [];
    // atendentes: Atendente[];
    atendentes: AtendenteDTO[] = [];
    filteredAtendentes: AtendenteDTO[] = [];
    orderDirection: boolean = true;
    sortField: string;
    dropdownSettings: any = {};
    selectedItems = [];
    ShowFilter = false;
    limitSelection = false;
    dataSource: MatTableDataSource<AtendenteDTO>;
    // @ViewChild(MatTable) table: MatTable<any>;

    // displayedColumns: string[] = ['id', 'nome', 'email', 'canais', 'acoes'];
    displayedColumns: string[] = ['login', 'area', 'canais', 'acoes'];

    constructor(
        private readonly atendenteService: AtendenteService,
        private readonly atendenteCanalService: AtendenteCanalService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly atendenteStore: AtendenteStore) {

        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'nome',
            selectAllText: 'Marca todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 6,
            allowSearchFilter: this.ShowFilter
        };

    }

    ngOnInit(): void {
        this.loadAtendentes();
    }

    loadAtendentes() {
        const atendentesDTO = [];
        this.atendenteService.getAll().subscribe((atendentes) => {
            this.atendenteCanalService.getAll().subscribe(atendCanais => {
                for (const atendente of atendentes){
                    const newAtendDTO = new AtendenteDTO();
                    newAtendDTO.id = atendente.id;
                    newAtendDTO.login = atendente.login;
                    newAtendDTO.area = atendente.area;
                    newAtendDTO.canais =  atendCanais
                    .filter(x => x.atendente.id === atendente.id)
                    .map(c => {
                        return {
                            id: c.canal.id,
                            nome: c.canal.nome,
                            prazo: c.canal.prazo
                        }
                    });
                    atendentesDTO.push(newAtendDTO);
                }
                this.atendentes = atendentesDTO;
            });
        });
        // this.table.renderRows();
    }

    newAtendente(): void {
        this.atendenteStore.update(null);
        this.router.navigate(['new-atendente']);
    }

    editAtendente(atendenteId: number) {
        const atendente = this.atendentes.find(a => a.id === atendenteId);
        this.atendenteStore.update(atendente);
        this.router.navigate(['new-atendente']);
    }

    deleteAtendente(atendenteId: number) {
        this.atendenteCanalService.deleteByAtendenteId(atendenteId).subscribe(resultCanais => {
            this.atendenteService.deleteById(atendenteId).subscribe(resultAtendente => {
                this.showMessage('Atendente removido.');
            });
        });
    }

    back(): void {
        this.router.navigate(['project']);
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.loadAtendentes();
        });
    }

    mapCanal(atendCanal: AtendenteCanal): Canal {
        return {
            id: atendCanal.canal.id,
            nome: atendCanal.canal.nome,
            prazo: atendCanal.canal.prazo
        };
    }

}
