import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Situacao } from '../models/situacao.model';

@Injectable()
export class SituacaoStore {

    private Subject = new BehaviorSubject<Situacao>(null);
    situacao = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Situacao> {
        return this.situacao;
    }

    update(situacao: Situacao) {
        this.Subject.next(situacao);
    }
}
