// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    // TrustCRMApiHost: 'https://restritahmg.previericsson.com.br/webapicrm/api/' // Ambiente de desenvolvimento servidor
    //  TrustCRMApiHost: 'https://homologacao.trustprev.com.br/webapicrm/api/' // Ambiente de desenvolvimento servidor
    //TrustCRMApiHost: 'https://participante.rsprev.com.br/WebAPICRM/api/' 
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/WebAPICRM/api/' // Ambiente de desenvolvimento servidor
    //TrustCRMApiHost: 'http://localhost:63961/api/api/' // Ambiente de desenvolvimento local https://participante.rsprev.com.br/WebAPICRM
    //TrustCRMApiHost: 'http://apicrmhmg.serpros.com.br/api/' // Ambiente de homologação do Serpros
    TrustCRMApiHost: 'http://apicrm.serpros.com.br/api/' // Ambiente de produção do Serpros
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
