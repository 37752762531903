export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'PROJECT': {
                'TITLE': 'Projects',
            },
            'REGISTRATION': {
                'TITLE': 'Registration',
                'STRUCTURES': {
                    'TITLE': 'Structures',
                },
                'ACCESSORIESTYPES': {
                    'TITLE': 'Accessory Types',
                },
                'BASIN': {
                    'TITLE': 'Basin',
                },
                'CLIENT': {
                    'TITLE': 'Client',
                },
                'PROJECTDOCUMENT': {
                    'TITLE': 'Project Document',
                },
                'VESSELDOCUMENT': {
                    'TITLE': 'Vessel Document',
                },
                'EXTRACTIONFIELD': {
                    'TITLE': 'Extraction Field',
                },
                'PLATFORM': {
                    'TITLE': 'Platform',
                },
                'MANUFACTURER': {
                    'TITLE': 'Manufacturer',
                },
                'ACCESSORIES': {
                    'TITLE': 'Accessories',
                },
                'EQUIPMENTSTYPES': {

                    'TITLE' : 'Equipment Types',
                },
                'EQUIPMENTS': {
                    'TITLE' : 'Equipments',
                },
                'MATERIALS': {
                    'TITLE' : 'Materials',

                },
                'USERVESSEL': {
                    'TITLE': 'User Vessel',
                },
                'ATBAR': {
                    'TITLE': 'ATBAR',
                }
            },
            'ADMINISTRATIVE': {
                'TITLE': 'Administrative',
                'USERS': {
                    'TITLE': 'Users',
                },
                'VESSELS': {
                    'TITLE': 'Vessels',
                },
                'PLATFORMS': {
                    'TITLE': 'Platforms',
                },
                'WELLS': {
                    'TITLE': 'Wells',
                },
                'LINES': {
                    'TITLE': 'Line Types',
                },
                'SCOPES': {
                    'TITLE': 'Scopes',
                },
                'DIAGRAMS': {
                    'TITLE': 'Diagrams',
                },
                'SPREADSHEETS': {
                    'TITLE' : 'Spreadsheets',
                },
                'PROJECTMANAGER': {
                    'TITLE' : 'Projects',
                },
                'CLIENTS': {
                    'TITLE' : 'Clients',
                },
                'PROJECTQUESTION': {
                    'TITLE' : 'Project Question',
                },
                'VESSELQUESTION': {
                    'TITLE' : 'Vessel Question',
                },
                'CONFIGURATIONS': {
                    'TITLE' : 'Configurations',
                },
                'OPERATIONS': {
                    'TITLE' : 'Operations',
                },
                'UNIFILAR': {
                    'TITLE' : 'Unifilar',
                }
            },
            'HELP': {
                'TITLE': 'Help',
            }
        }
    }
};
