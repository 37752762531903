import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatIconRegistry } from '@angular/material';
import { Router } from '@angular/router';
import { UploadAppComponent } from '../uploadApp/upload-app.component';
import { Canal } from '../shared/models/canal.model';
import { ChamadosTotalizadores } from '../shared/models/chamadosTotalizadores.model';
import { ChamadoService } from '../shared/services/chamado.service';
import { CanalService } from '../shared/services/canal.service';
import { CanalStore } from '../shared/store/canal.store';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';
import { EventEmitterService } from '../../content/shared/services/eventEmitter.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project.component.scss']
})

export class ProjectListComponent implements OnInit {

    canais: Canal[];
    chamadosTotalizadores: ChamadosTotalizadores[] = [];
    
    idCanal01 = 82;
    nomeCanal01 = 'VÍDEO CHAT';
    iconCanal01 = 'lock';
    pendentesCanal01 = 0;
    totalCanal01 = 0;
    
    idCanal02 = 1;
    nomeCanal02 = 'Telefone';
    iconCanal02 = 'phone_in_talk';
    pendentesCanal02 = 0;
    totalCanal02 = 0;

    idCanal03 = 101;
    nomeCanal03 = 'Fale Conosco';
    iconCanal03 = 'alternate_email';
    pendentesCanal03 = 0;
    totalCanal03 = 0;

    idCanal04 = 102;
    nomeCanal04 = 'WhatsApp';
    //iconCanal04 = 'whatsapp';
    pendentesCanal04 = 0;
    totalCanal04 = 0;

    idCanal05 = 10;
    nomeCanal05 = 'Presencial';
    iconCanal05 = 'person';
    pendentesCanal05 = 0;
    totalCanal05 = 0;
    
    idCanal06 = 81;
    nomeCanal06 = 'Ativo';
    iconCanal06 = 'chat';
    pendentesCanal06 = 0;
    totalCanal06 = 0;

    MatrizAtendDia: any[] = [['Canais', 'Atendimentos dia', 'Atendidos', 'Em Atendimento', 'Retaguarda', 'Em Atraso', 'Pendentes']];
    public diaChartData: GoogleChartInterface = {
        chartType: 'Bar',
        dataTable: [
            ['Canais', 'Em Atraso', 'Atendidos', 'Em Atendimento', 'Retaguarda', 'Atendimentos dia', 'Pendentes']
            //['Canais', 'Atendidos', 'Em Atendimento', 'Retaguarda', 'Atendimentos dia', 'Pendentes']
        ],
        options: {
            width: 700,
            height: 380,
            chart: {
                title: 'ATENDIMENTO - DIA',
                subtitle: 'VÍDEO CHAT, Telefone, Fale Conosco, Ativo, Presencial, WhatsApp'
            }
        }
    };
    public retaguardaChartData: GoogleChartInterface = {
        chartType: 'Bar',
        dataTable: [
            ['Canais', 'Chamados em atendimento']
        ],
        options: {
            width: 500,
            height: 450,
            legend: { position: 'none' },
            chart: {
                title: 'RETAGUARDA',
                subtitle: 'Chamados em atendimento por canal',
                legend: 'none',
            }
        }
    };
    public atrasoChartData: GoogleChartInterface = {
        chartType: 'Bar',
        dataTable: [
            ['Área', 'Telefone', 'VÍDEO CHAT', 'Fale Conosco', 'Ativo', 'Presencial, WhatsApp']
        ],
        options: {
            width: 600,
            height: 450,
            chart: {
                title: 'ATRASOS - 24:00 Horas',
                subtitle: 'Chamados em atraso por Área'
            }
        }
    };

    constructor(
        public dialog: MatDialog, 
        private router: Router,
        private readonly chamadoService: ChamadoService,
        private readonly canalService: CanalService,
        private readonly canalStore: CanalStore,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        this.iconRegistry.addSvgIcon(
            'whatsapp',
            this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/whatsapp.svg')
        );
    }

    ngOnInit() {
        this.loadCanais();
        this.loadChamadosTotalizadores();
        this.AtualizaGraficoAtendimentoDia();
        this.AtualizaGraficoRetaguarda();
        this.AtualizaGraficoAtrasos();
    }

    loadCanais() {
        this.canalService.getAll().subscribe(canais => {
            this.canais = canais;
        });
    }

    loadChamadosTotalizadores() {
        EventEmitterService.get('atualizaChamadosPorCanal').emit();
        this.chamadoService.getTotalizadores().subscribe(chamadosTotalizadores => {
            this.chamadosTotalizadores = chamadosTotalizadores;
            this.totalCanal01 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01).total : 0;
            this.totalCanal02 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02).total : 0;
            this.totalCanal03 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03).total : 0;
            this.totalCanal04 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04).total : 0;
            this.totalCanal05 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05).total : 0;
            this.pendentesCanal01 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01).pendente : 0;
            this.pendentesCanal02 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02).pendente : 0;
            this.pendentesCanal03 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03).pendente : 0;
            this.pendentesCanal04 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04).pendente : 0;
            this.pendentesCanal05 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05).pendente : 0;
        });
    }

    ConvertStringToNumber(input: string) {
      var numeric = Number(input);
      return numeric;
    }
    

    AtualizaGraficoAtendimentoDia(){
        this.chamadoService.getAtendimentosDia().subscribe(resultado => {
            const dataTable = this.diaChartData.dataTable;
            const linhasRemover = dataTable.length - 1;
            for (let i = 0; i < linhasRemover; i++ ){
              dataTable.pop();
            }
            for (const linha of resultado){
              dataTable.push(linha);
            }
            this.diaChartData.component.draw();
        });
    }

    AtualizaGraficoRetaguarda(){
        this.chamadoService.getChamadosRetaguarda().subscribe(resultado => {
            const dataTable = this.retaguardaChartData.dataTable;
            const linhasRemover = dataTable.length - 1;
            for (let i = 0; i < linhasRemover; i++ ){
                dataTable.pop();
            }
            for (const linha of resultado){
                linha[1] = this.ConvertStringToNumber(linha[1]);
                dataTable.push(linha);
            }
            this.retaguardaChartData.component.draw();
        });
    }

    AtualizaGraficoAtrasos(){
        this.chamadoService.getChamadosAtraso().subscribe(resultado => {
            const title: string[] = [];
            title.push('Canal');
            const linhas: any[] = [];
            const dataTable = this.atrasoChartData.dataTable;
            const linhasRemover = dataTable.length;
            for (let i = 0; i < linhasRemover; i++ ){
                dataTable.pop();
            }
            let firstLine = true;
            for (const itemRes of resultado) {
                const linha: any[] = [];
                linha.push(itemRes.canal);
                for (const item of itemRes.areas){
                    if (firstLine) {
                        title.push(item.area);
                    }
                    linha.push(item.total);
                }
                if (firstLine){
                    linhas.push(title);
                    firstLine = false;
                }
                linhas.push(linha);
            }
            for (const l of linhas){
                dataTable.push(l);
            }
            this.atrasoChartData.component.draw();
        });
    }

    viewCanal(canalId: number): void {
        const canal = this.canais.find(a => a.id === canalId);
        this.canalStore.update(canal);
        this.router.navigate(['canal-app']);
    }

    uploadApp(): void {
        const dialogRef = this.dialog.open(UploadAppComponent, { width: '550px', });
    
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }    
    
}
