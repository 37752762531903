import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Login } from '../models/login.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Credencial } from '../models/credencial.model';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../components/message/dialog.component';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
                public dialog: MatDialog,
                private httpClient: HttpClient
             ) {
  }

  public get currentTokenValue(): string {
    return localStorage.getItem('token');
  }

  retornaToken(user: string, password: string) {

    const credencial = new Credencial();

    credencial.login = user;
    credencial.senha = password;

    return this.httpClient
      .post<{ token: string; username: string }>(
        environment.TrustCRMApiHost + 'Login/Autenticar', credencial
      )
      .pipe(
        tap(res => {
          localStorage.setItem('token', res.token);
        }),
        catchError(this.handleError<Login>('Recuperação do token falhou.'))
      );
  }

  logout() {
    localStorage.clear();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.showMessage('Credenciais inválidas.');
      console.error(operation + ' Erro: ' + error.message);

      return of(result as T);
    };
  }

  showMessage(message: string): void {
    const dialogRef = this.dialog.open(
      DialogComponent, {
        width: '350px',
        data: { message: message }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
}
}
