import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Registro } from '../models/registro.model';
import { Util } from '../util';
// import { version } from 'punycode';

@Injectable()
export class RegistroService extends ApiService {

    private strAction = 'Registro';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Registro[]> {
        return this.get<Registro[]>(this.strAction + `/GetAll`);
    }

    getAllByChamado(chamadoId: number): Observable<Registro[]> {
        return this.get<Registro[]>(this.strAction + `/GetAllByChamado/${chamadoId}`);
    }
}
