import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Integracao } from '../models/integracao';

@Injectable()
export class IntegracaoStore {

    private Subject = new BehaviorSubject<Integracao>(null);
    integracao = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Integracao> {
        return this.integracao;
    }

    update(integracao: Integracao) {
        this.Subject.next(integracao);
    }
}
