import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Versao } from '../models/versao.model';

@Injectable()
export class VersaoStore {

    private Subject = new BehaviorSubject<Versao>(null);
    Versao = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Versao> {
        return this.Versao;
    }

    update(versao: Versao) {
        this.Subject.next(versao);
    }
}