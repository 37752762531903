import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Assunto } from '../models/assunto.model';
import { Util } from '../util';
// import { version } from 'punycode';

@Injectable()
export class AssuntoService extends ApiService implements BaseService<Assunto> {

    private strAction = 'Assunto';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Assunto[]> {
        return this.get<Assunto[]>(this.strAction + `/GetAll`);
    }

    getById(assuntoId: number): Observable<Assunto> {
        return this.get<Assunto>(this.strAction + `/GetById/${assuntoId}`);
    }

    getByArea(areaId: number): Observable<Assunto[]> {
        return this.get<Assunto[]>(this.strAction + `/GetByArea?area=${areaId}`);
    }

    deleteById(assuntoId: number) {
        return this.post(this.strAction + `/DeleteAssunto?id=${assuntoId}`);
    }

    save(assunto: Assunto): Observable<Assunto> {
        if (this.util.IsNullOrUndefined(assunto.id) || assunto.id === 0){
            return this.post<Assunto>(this.strAction + `/Post`, assunto);        
        }        
        return this.post<Assunto>(this.strAction + `/Put`, assunto);        
    }
}
