import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { BaseService } from '../../../../core/base.service';

import { Util } from '../util';
import { ChamadoStatus } from '../models/chamadoStatus.model';
//import * as _ from '../../../../../../node_modules/ loadash';

// import { version } from 'punycode';

@Injectable()
export class ChamadoStatusService extends ApiService implements BaseService<ChamadoStatus> {

    private strAction = 'ChamadoStatus';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }
    save(entity: ChamadoStatus): Observable<ChamadoStatus> {
        throw new Error("Method not implemented.");
    }

    getAll(): Observable<ChamadoStatus[]> {
        return this.get<ChamadoStatus[]>(this.strAction + `/GetAll`);
    }

    getById(chamadoId: number): Observable<ChamadoStatus> {
        return this.get<ChamadoStatus>(this.strAction + `/GetById/${chamadoId}`);
    }

    Post(chamadoStatus: ChamadoStatus) {
        // return this.post(this.strAction + `/DeleteById?id=${areaID}`, JSON.stringify(area), { headers: headers });
        return this.post<ChamadoStatus>(this.strAction + `/Post`, chamadoStatus);
    }

    Put(chamadoStatus: ChamadoStatus) {
        // return this.post(this.strAction + `/DeleteById?id=${areaID}`, JSON.stringify(area), { headers: headers });
        return this.post<ChamadoStatus>(this.strAction + `/Put`, chamadoStatus);
    }


    deleteById(chamadoId: number) {
        // return this.post(this.strAction + `/DeleteById?id=${areaID}`, JSON.stringify(area), { headers: headers });
        return this.post(this.strAction + `/DeleteById?id=${chamadoId}`);
    }

}
