import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfigAdminComponent } from '../configAdmin/config-admin.component';
import { MatFormFieldModule } from '@angular/material/form-field';


export interface PeriodicElement {
    id :string;
    nome: string;
    telefone: string;
    area: string;
    canal: string;
    acoes: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    { id: '1234', nome: 'João Pedro', telefone: '987845623', area: 'financeiro', canal: '', acoes: '' },
    { id: '5678', nome: 'Marcos Almeida', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '91011', nome: 'Mariana Castro', telefone: '987845623', area: 'financeiro', canal: '', acoes: '' },
    { id: '1213', nome: 'Bruno Correa', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '1415', nome: 'João Pedro', telefone: '987845623', area: 'planejamento', canal: '', acoes: '' },
    { id: '1617', nome: 'João Pedro', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '1819', nome: 'Mariana Castro', telefone: '987845623', area: '', canal: '', acoes: '' },
    { id: '2021', nome: 'Bruno Correa', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
];

@Component({
    selector: 'atendentes-app',
    templateUrl: './atendentes-app.component.html',
    styleUrls: ['./atendentes-app.component.scss'],

})
export class AtendentesAppComponent {

    displayedColumns: string[] = ['id', 'nome', 'telefone', 'area', 'canal', 'acoes'];
    dataSource = ELEMENT_DATA;


    constructor(public dialog: MatDialog, private router: Router) {


    }

    // showMessage(message: string): void {

    // }

    // closeModal(): void {
    //     this.dialogRef.close();
    // }

    backToProject(): void {
        this.router.navigate(["project-list"]);
    }

    //newAtendente(): void {
    //    const dialogRef = this.dialog.open(ConfigAdminComponent, {
    //    width: '1280px',
    //    height: '90%'
    //    });

    //    dialogRef.afterClosed().subscribe(result => {
    //    console.log('The dialog was closed');
    //    });
    //}
    newAtendente(): void {
        this.router.navigate(['cadastro-atendente-app']);
    }
}