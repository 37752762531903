import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenavModule, MatDatepickerModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule } from '@fuse/components';

import { FuseContentModule } from 'app/main/content/content.module';
import { FuseFooterModule } from 'app/main/footer/footer.module';
import { FuseNavbarModule } from 'app/main/navbar/navbar.module';
import { FuseQuickPanelModule } from 'app/main/quick-panel/quick-panel.module';
import { FuseToolbarModule } from 'app/main/toolbar/toolbar.module';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseMainComponent } from './main.component';
import { SharedModule } from './content/shared/shared.module';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    declarations: [
        FuseMainComponent,
    ],
    imports     : [
        RouterModule,

        MatSidenavModule,
        MatDatepickerModule,
        FuseSharedModule,

        FuseThemeOptionsModule,
        FuseNavigationModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        FuseSidebarModule,
        FuseWidgetModule,
        FuseContentModule,
        FuseFooterModule,
        FuseNavbarModule,
        FuseQuickPanelModule,
        FuseToolbarModule,
        SharedModule,
        MatTooltipModule,
        TextMaskModule,
        Ng4LoadingSpinnerModule.forRoot()
    ],
    exports     : [
        FuseMainComponent
    ]
})

export class FuseMainModule {
}
