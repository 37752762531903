import { MatIconModule, MatButtonModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroDetalheComponent } from './filtro-detalhe.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';


const routes = [
  {
      path: 'filtro-detalhe-app',
      component: FiltroDetalheComponent,
      canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgxDatatableModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [FiltroDetalheComponent],
    exports: [
      FiltroDetalheComponent
    ]
})
export class FiltroDetalheModule { }
