import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatDialogModule, MatPaginatorModule, MatCardModule, MatTableModule } from '@angular/material';
import { ErrorPageComponent } from './errorpage.component' ;

@NgModule({
    declarations: [
        ErrorPageComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
        
    ],
   
     entryComponents: [
        ErrorPageComponent
     ],
})
export class ErrorPageModule
{
}
