export const locale = {
    lang: 'pt-br',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplicação',
            'SAMPLE': {
                'TITLE': 'Exemplo',
                'BADGE': '15'
            },
            'PROJECT': {
                'TITLE': 'Projetos',
            },
            'REGISTRATION': {
                'TITLE': 'Cadastros',
                'STRUCTURES': {
                    'TITLE': 'Estruturas',
                },
                'ACCESSORIESTYPES': {
                    'TITLE': 'Tipos de Acessório',
                },
                'BASIN': {
                    'TITLE': 'Bacia',
                },
                'CLIENT': {
                    'TITLE': 'Cliente',
                },
                'PROJECTDOCUMENT': {
                    'TITLE': 'Documento do Projeto',
                },
                'VESSELDOCUMENT': {
                    'TITLE': 'Documento do Navio',
                },
                'EXTRACTIONFIELD': {
                    'TITLE': 'Campo de extração',
                },
                'PLATFORM': {
                    'TITLE': 'Plataforma',
                },
                'MANUFACTURER': {
                    'TITLE': 'Fabricante',
                },
                'ACCESSORIES': {
                    'TITLE': 'Acessórios',
                },
                'EQUIPMENTSTYPES': {

                    'TITLE' : 'Tipos de Equipamento',
                },
                'EQUIPMENTS': {
                    'TITLE' : 'Equipamentos',
                },
                'MATERIALS': {
                    'TITLE' : 'Materiais',
                },
                'USERVESSEL': {
                    'TITLE': 'Usuários do Navio',
                },
                'ATBAR': {
                    'TITLE': 'Matriz ATBAR',
                }
            },
            'ADMINISTRATIVE': {
                'TITLE': 'Administrativo',
                'USERS': {
                    'TITLE': 'Usuários',
                },
                'VESSELS': {
                    'TITLE': 'Navios',
                },
                'PLATFORMS': {
                    'TITLE': 'Platformas',
                },
                'WELLS': {
                    'TITLE': 'Poços',
                },
                'LINES': {
                    'TITLE': 'Tipos de Linhas',
                },
                'SCOPES': {
                    'TITLE': 'Escopos',
                }                ,
                'DIAGRAMS': {
                    'TITLE': 'Diagramas',
                },
                'SPREADSHEETS': {
                    'TITLE' : 'Planilhas',
                },
                'PROJECTMANAGER': {
                    'TITLE' : 'Projetos',
                },
                'CLIENTS': {
                    'TITLE' : 'Clientes',
                },
                'PROJECTQUESTION': {
                    'TITLE' : 'Questionamento de Projeto',
                },
                'VESSELQUESTION': {
                    'TITLE' : 'Questionamento de Navio',
                },
                'CONFIGURATIONS': {
                    'TITLE' : 'Configurações',
                },
                'OPERATIONS': {
                    'TITLE' : 'Tipos de Operação',
                }
                ,
                'UNIFILAR': {
                    'TITLE' : 'Unifilar',
                }
            },
            'HELP': {
                'TITLE': 'Ajuda',
            }
        }
    }
};
