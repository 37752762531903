import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationPortuguese } from './navigation/i18n/pt-br';
import { AuthenticationService } from './main/content/shared/services/authentication.service';

@Component({
    selector: 'fuse-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    currentUser = false;
    template: string = `<div class="loader-animation">
    <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
   </div>`;

    constructor(
        private translate: TranslateService,
        private fuseNavigationService: FuseNavigationService,
        private fuseSplashScreen: FuseSplashScreenService,
        private fuseTranslationLoader: FuseTranslationLoaderService,
        private authenticationService: AuthenticationService
    ) {
      
        this.currentUser = false;

        // Add languages
        this.translate.addLangs(['en', 'pt-br']);

        // Set the default language
        this.translate.setDefaultLang('en');

        // Set the navigation translations
        this.fuseTranslationLoader.loadTranslations(navigationEnglish, navigationPortuguese);

        // Use a language
        this.translate.use('en');
    }

    exibeApp(event) {
        this.currentUser = false;
        if ((this.authenticationService.currentTokenValue !== null) && (event === true)) {
            this.currentUser = true;
          }
              }
}
