import { SituacaoRelacaoService } from './../shared/services/situacaoRelacao.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SituacaoStore } from '../shared/store/situacao.store';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';
import { DialogComponent } from '../components/message/dialog.component';
import { SituacaoDTO } from '../shared/models/situacaoDTO.model';

@Component({
  selector: 'app-cadastro-situacao',
  templateUrl: './cadastro-situacao.component.html',
  styleUrls: ['./cadastro-situacao.component.scss']
})
export class CadastroSituacaoComponent implements OnInit {

  situacaoFilter: any = [];
  //situacoes: Situacao[];
  situacoes: SituacaoDTO[] = [];
  filteredSituacoes: SituacaoDTO[] = [];
  //filteredSituacoes: Situacao[];
  orderDirection: boolean = true;
  sortField: string;

  displayedColumns: string[] = ['id', 'nome', 'enviarEmail', 'situacoesPossiveis', 'acoes'];
  constructor(
    private readonly situacaoService: SituacaoChamadoService,
    private readonly situacaoRelacaoService: SituacaoRelacaoService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly situacaoStore: SituacaoStore
  ) { }

  ngOnInit() {
    this.loadSituacoes();
  }


  loadSituacoes() {
    const situacoesDTO = [];
    this.situacaoService.getAll().subscribe((situacoes) => {
        this.situacaoRelacaoService.getAll().subscribe(situacaoRelacao => {
            for (const situacao of situacoes){
                const newSituacaoDTO = new SituacaoDTO();
                newSituacaoDTO.id = situacao.id;
                newSituacaoDTO.nome = situacao.nome;
                newSituacaoDTO.email = situacao.email;
                newSituacaoDTO.situacoes =  situacaoRelacao
                .filter(x => x.origem.id === situacao.id)
                .map(c => {
                    return {
                        id: c.id,
                        nome: c.destino.nome,
                        email: c.origem.email
                    }
                });
                situacoesDTO.push(newSituacaoDTO);
            }
            this.situacoes = situacoesDTO;
        });
    });
    // this.table.renderRows();
}

//   loadSituacoes(){
//     this.situacaoService.getAll().subscribe(situacoes => {
//         this.filteredSituacoes = situacoes;
//         this.situacoes = situacoes;
//         this.situacaoStore.update(null);
//     });
// }

  editSituacao(situacaoId: number) {
    const situacao = this.situacoes.find(a => a.id === situacaoId);
    this.situacaoStore.update(situacao);
    this.router.navigate(['new-situacao']);
  }

  deleteSituacao(situacaoId) {
    this.situacaoService.deleteById(situacaoId).subscribe(
      () => {
        this.showMessage('Situação removida.');
      },
      (err) => {
        this.showMessage(err);
      });
  }

  back(): void {
    this.router.navigate(['project']);
  }

  showMessage(message: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
        width: '350px',
        data: { message: message }
    });

    dialogRef.afterClosed().subscribe(() => {
        this.loadSituacoes();
    });
}


  newSituacao(): void {
    this.situacaoStore.update(null);
    this.router.navigate(['new-situacao']);
  }
}
