import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatProgressSpinnerModule } from '@angular/material';
import { ProgressSpinnerComponent } from './progress-spinner.component';

@NgModule({
    declarations: [
        ProgressSpinnerComponent
    ],
    imports: [
        TranslateModule,
        FuseSharedModule,
        MatProgressSpinnerModule
    ],
   
     entryComponents: [
        ProgressSpinnerComponent
     ],
})
export class ProgressSpinnerModule
{

}
