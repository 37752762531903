import { AssuntoService } from './content/shared/services/assunto.service';
import { ChamadoService } from './content/shared/services/chamado.service';
import { Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs/Subscription';
import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { MatDialog, MatInput, MatSelect } from '@angular/material';
import { DialogComponent } from '../main/content/components/message/dialog.component';
import { Util } from './content/shared/util';
import { FiltroChamado } from './content/shared/models/filtroChamado.model';
import { ListaChamadoStore } from './content/shared/store/listaChamado.store';
import { CanalService } from './content/shared/services/canal.service';
import { Canal } from './content/shared/models/canal.model';
import { Assunto } from './content/shared/models/assunto.model';
import { SituacaoChamadoService } from './content/shared/services/situacaoChamado.service';
import { Situacao } from './content/shared/models/situacao.model';

@Component({
    selector: 'fuse-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseMainComponent implements OnInit, OnDestroy {
     
    util: Util = new Util();
    canais: Canal[];
    assuntos: Assunto[];
    situacoes: Situacao[];
    onConfigChanged: Subscription;
    fuseSettings: any;
    navigation: any;
    filtroChamado = new FiltroChamado();
    statusFiltro = false;
    statusChamado = false;
    statusBusca = false;
    filtroGeral = false;
    periodoData = false;
    filtroCanal = false;
    filtroAssunto = false;
    filtroSituacao = false;
    selectedCanal: number;
    selectedSituacao: number;
    selectedAssunto: number;
    placeholder: string;
    dataInicio: Date;
    dataFim: Date;
    options: string[] = [
        'Canal',
        'Nome',
        'CPF',
        'Chamado',
        'Matrícula',
        'Assunto',
        'Situação',
        'Período'
    ];

    @HostBinding('attr.fuse-layout-mode') layoutMode;

    constructor(
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private fuseConfig: FuseConfigService,
        private platform: Platform,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly listaChamadoStore: ListaChamadoStore,
        private readonly chamadoService: ChamadoService,
        private canalService: CanalService,
        private assuntoService: AssuntoService,
        private situacaoService: SituacaoChamadoService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.filtroGeral = true;
        this.placeholder = 'Buscar de acordo com o filtro';
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.fuseSettings = newSettings;
                        this.layoutMode = this.fuseSettings.layout.mode;
                    }
                );

        if (this.platform.ANDROID || this.platform.IOS) {
            this.document.body.className += ' is-mobile';
        }

        this.navigation = navigation;
    }

    ngOnInit() {

    }

    loadCanal() {
        this.canalService.getAll().subscribe(canais => {
            this.canais = canais;
            // Fixando o canal em Telefone
            this.selectedCanal = 1;
        });
    }

    loadAssunto() {
        this.assuntoService.getAll().subscribe(assuntos => {
            this.assuntos = assuntos.sort((a, b) => a.nome.localeCompare(b.nome));
        });
    }

    loadSituacao() {
        this.situacaoService.getAll().subscribe(situacoes => {
            this.situacoes = situacoes;
        });
    }


    addClass(className: string) {
        this._renderer.addClass(this._elementRef.nativeElement, className);
    }

    removeClass(className: string) {
        this._renderer.removeClass(this._elementRef.nativeElement, className);
    }

    clickFiltro() {
        this.statusFiltro = !this.statusFiltro;

        if (!this.statusFiltro) {
            this.limpaFiltro();
        }
    }

    limpaFiltro() {
        this.filtroChamado = new FiltroChamado();
        this.filtroGeral = true;
        this.filtroCanal = false;
        this.periodoData = false;
        this.filtroAssunto = false;
        this.filtroSituacao = false;
        this.selectedAssunto = 0;
        this.selectedSituacao = 0;
        this.dataInicio = null;
        this.dataFim = null;
    }

    clickPuxarChamado() {
        this.statusChamado = !this.statusChamado;
    }

    searchClick() {
        this.statusBusca = !this.statusBusca;
    }


    aplicaFiltro() {

        if (this.filtroChamado.tipoFiltro === 'Período') {
            let dataInicial = '';
            let dataFinal = '';

            if (!this.util.IsNullOrUndefined(this.dataInicio)){
                // dataInicial = this.dataInicio.getDate() + '/' + (this.dataInicio.getMonth() + 1) + '/' + this.dataInicio.getFullYear(); 
                dataInicial = this.dataInicio.toISOString().slice(0,10)
            }
            if (!this.util.IsNullOrUndefined(this.dataFim)){
                // dataFinal = this.dataFim.getDate() + '/' + (this.dataFim.getMonth() + 1) + '/' + this.dataFim.getFullYear();
                dataFinal = this.dataFim.toISOString().slice(0,10)
            }
            
            const Data =  dataInicial + ',' + dataFinal;
            this.filtroChamado.descricao = Data.toString();
            localStorage.setItem('DescricaoFiltro', this.filtroChamado.tipoFiltro
            + ': ' + this.filtroChamado.descricao);
        }

        if (this.filtroChamado.tipoFiltro === 'Situação') {
            this.filtroChamado.descricao = this.selectedSituacao.toString();
            localStorage.setItem('DescricaoFiltro', this.filtroChamado.tipoFiltro
            + ': ' + this.situacoes.find(x => x.id === this.selectedSituacao).nome);
        }

        if (this.filtroChamado.tipoFiltro === 'Canal') {
            this.filtroChamado.descricao = this.selectedCanal.toString();
            localStorage.setItem('DescricaoFiltro', this.filtroChamado.tipoFiltro
            + ': ' + this.canais.find(x => x.id === this.selectedCanal).nome);
        }

        if (this.filtroChamado.tipoFiltro === 'Assunto') {
            this.filtroChamado.descricao = this.selectedAssunto.toString();
            localStorage.setItem('DescricaoFiltro', this.filtroChamado.tipoFiltro
            + ': ' + this.assuntos.find(x => x.id === this.selectedAssunto).nome);
        }

        if (this.util.IsNullOrUndefined(this.filtroChamado.descricao)) {
            this.showMessage('Por favor informe o parâmetro para busca.');
            return;
        }
        if (this.filtroChamado.tipoFiltro === 'CPF' || this.filtroChamado.tipoFiltro === 'Chamado' || this.filtroChamado.tipoFiltro === 'Matrícula') {
            if (!this.checkNumber(this.filtroChamado.descricao)) {
                this.showMessage('Valor informado no campo Pesquisar não é numérico.');
                return;
            }
            localStorage.setItem('DescricaoFiltro', this.filtroChamado.tipoFiltro
            + ': ' + this.filtroChamado.descricao);
        }

        this.filtroChamado.currentPage = 0
        this.filtroChamado.pageSize = 9
        localStorage.setItem('filtroChamado', JSON.stringify(this.filtroChamado))
        
        this.chamadoService.filtroChamado(this.filtroChamado).subscribe(listaChamados => {
            if (!this.util.IsNullOrUndefined(listaChamados)) {
                this.listaChamadoStore.update(listaChamados);
                
                this.clickFiltro();
                this.router.navigate(['filtro-detalhe-app']);
            }
            else {
                this.showMessage('Chamado não encontrado!');
            }
        }, error => {
            this.showMessage(error);
        });



        // else {
        //     this.showMessage('Filtro não implementado.');
        // }
    }

    modificaFiltro(event) {
        switch (event) {
            case 'Canal': {
                this.loadCanal();
                this.filtroCanal = true;
                this.filtroGeral = false;
                this.filtroAssunto = false;
                this.filtroSituacao = false;
                this.periodoData = false;
                break;
            }
            case 'Nome':
            case 'CPF':
            case 'Chamado':
            case 'Matrícula': {
                this.filtroCanal = false;
                this.filtroAssunto = false;
                this.filtroSituacao = false;
                this.periodoData = false;
                this.filtroGeral = true;
                break;
            }
            case 'Assunto': {
                this.loadAssunto();
                this.filtroAssunto = true;
                this.filtroCanal = false;
                this.periodoData = false;
                this.filtroGeral = false;
                this.filtroSituacao = false;
                break;
            }
            case 'Situação': {
                this.loadSituacao();
                this.filtroAssunto = false;
                this.filtroCanal = false;
                this.periodoData = false;
                this.filtroGeral = false;
                this.filtroSituacao = true;
                break;
            }
            case 'Período': {
                this.filtroAssunto = false;
                this.filtroCanal = false;
                this.periodoData = true;
                this.filtroGeral = false;
                this.filtroSituacao = false;
                break;
            }
        }
    }

    checkNumber(valor: string): boolean {
        const regra = /^[0-9]+$/;
        if (valor.match(regra)) {
            return true;
        }
        else {
            return false;
        }
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

    }

    ngOnDestroy() {
        this.onConfigChanged.unsubscribe();

    }

}


