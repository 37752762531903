import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { ISubscription } from 'rxjs/Subscription';
import { LoginStore } from '../content/shared/store/login.store';
import { Canal } from '../content/shared/models/canal.model';
import { CanalStore } from '../content/shared/store/canal.store';
import { EventEmitterService } from '../content/shared/services/eventEmitter.service';
import { ChamadoService } from '../content/shared/services/chamado.service';
import { CanalService } from '../content/shared/services/canal.service';
import { ChamadosTotalizadores } from '../content/shared/models/chamadosTotalizadores.model';
import { VersaoAppStore } from '../content/shared/store/versaoApp.store';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'fuse-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})

export class FuseToolbarComponent implements OnInit, OnDestroy {

    private sub: any;
    versao = '---';
    canais: Canal[] = [];
    userStatusOptions: any[];
    languages: any;
    usuarioLogado: string;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    // noNav: boolean;
    navigation: any;
    chamadosTotalizadores: ChamadosTotalizadores[] = [];
    @Output() updateStatusFiltro = new EventEmitter<boolean>();

    procedureSubscription: ISubscription;
    currentUserName: string;

    idCanal01 = 82;
    nomeCanal01 = 'VÍDEO CHAT';
    iconCanal01 = 'lock';
    pendentesCanal01 = 0;
    // totalCanal01: number = 0;

    idCanal02 = 1;
    nomeCanal02 = 'Telefone';
    iconCanal02 = 'phone_in_talk';
    pendentesCanal02 = 0;
    // totalCanal02: number = 0;

    idCanal03 = 101;
    nomeCanal03 = 'Fale Conosco';
    iconCanal03 = 'alternate_email';
    pendentesCanal03 = 0;
    // totalCanal03: number = 0;

    idCanal04 = 102;
    nomeCanal04 = 'WhatsApp';
    iconCanal04 = '';
    pendentesCanal04 = 0;
    // totalCanal04: number = 0;

    idCanal05 = 10;
    nomeCanal05 = 'Presencial';
    iconCanal05 = 'person';
    pendentesCanal05 = 0;
    // totalCanal01: number = 0;

    idCanal06 = 81;
    nomeCanal06 = 'Ativo';
    iconCanal06 = 'chat';
    pendentesCanal06 = 0;
    // totalCanal04: number = 0;


    constructor(
        private router: Router,
        private fuseConfig: FuseConfigService,
        private readonly loginStore: LoginStore,
        private versaoAppStore: VersaoAppStore,
        private readonly chamadoService: ChamadoService,
        private readonly canalService: CanalService,
        private sidebarService: FuseSidebarService,
        private readonly canalStore: CanalStore,
        private translate: TranslateService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        this.sub = EventEmitterService.get('atualizaChamadosPorCanal').subscribe(data => {
            this.loadChamadosTotalizadores();
            // alert('Atualização Realizada!');
        });
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                'id': 'en',
                'title': 'English',
                'flag': 'us'
            },
            {
                'id': 'pt-br',
                'title': 'Portugues(Brasil)',
                'flag': 'br'
            }
        ];

        this.selectedLanguage = this.languages[0];

        router.events.subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.showLoadingBar = true;
                }
                if (event instanceof NavigationEnd) {
                    this.showLoadingBar = false;
                }
            });

        this.fuseConfig.onConfigChanged.subscribe((settings) => {
            this.horizontalNav = settings.layout.navigation === 'top';
            // this.noNav = settings.layout.navigation === 'none';
        });

        this.navigation = navigation;

        this.iconRegistry.addSvgIcon(
            'whatsapp',
            sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/whatsapp.svg')
        );
    }

    // toggleSidebarOpened(key) {
    //     this.sidebarService.getSidebar(key).toggleOpen();
    // }

    viewCanal(canalId: number): void {
        const canal = this.canais.find(a => a.id === canalId);
        this.canalStore.update(canal);
        this.router.navigate(['canal-app']);
    }

    search(value) {
        // Do your search here...
        //console.log(value);
    }

    setLanguage(lang) {
        // Set the selected language for toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translate.use(lang.id);
    }

    ngOnInit() {

        this.loadVersao();
        this.loadCanais();
        this.loadChamadosTotalizadores();
        //console.log('teste')
    }

    loadVersao() {
        this.versaoAppStore.getCurrent().subscribe(versao => {
            if (versao) {
                this.versao = versao.numero;
            }
        });
    }

    loadCanais() {
        this.canalService.getAll().subscribe(canais => {
            this.canais = canais;
        });
    }

    loadChamadosTotalizadores() {
        this.chamadoService.getTotalizadores().subscribe(chamadosTotalizadores => {
            this.chamadosTotalizadores = chamadosTotalizadores;
            this.pendentesCanal01 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal01).pendente : 0;
            this.pendentesCanal02 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal02).pendente : 0;
            this.pendentesCanal03 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal03).pendente : 0;
            this.pendentesCanal04 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal04).pendente : 0;
            this.pendentesCanal05 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal05).pendente : 0;
            this.pendentesCanal06 = this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal06) != null ? this.chamadosTotalizadores.find(t => t.nome === this.nomeCanal06).pendente : 0;
        });
    }


    chamadoTmp() {
        this.router.navigate(['chamado-detalhe-tmp']);
    }

    clickFiltro() {
        this.updateStatusFiltro.emit(true);
    }

    meusAtendimentos(): void {
        this.router.navigate(['meus-atendimentos-app']);
    }

    abrirChamado(): void {
        this.router.navigate(['cadastrar-chamado-app']);
    }
    
    todosAtendimentos(): void {
        this.router.navigate(['todos-atendimentos-app'])
    }

    carregaUsuarioLogado() {
        this.loginStore.getCurrent().subscribe(usuario => {
            this.usuarioLogado = usuario.idLogin;
        });

    }

    detalheUsuario() {
        this.router.navigate(['app-usuario-detalhe']);
    }

    alteraSenha() {
        this.router.navigate(['new-login']);
    }

    logout() {
        window.location.replace('/login');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    telaPrincipal() {
        this.router.navigate(['project']);
    }
}
