import { AtendenteStore } from './../shared/store/atendente.store';
import { Component, OnInit } from '@angular/core';
import { LoginStore } from '../shared/store/login.store';
import { Pessoa } from '../shared/models/pessoa.model';
import { PessoaService } from '../shared/services/pessoa.service';
import { Router } from '@angular/router';
import { AtendenteCanalService } from '../shared/services/atendenteCanal.service';
import { Canal } from '../shared/models/canal.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AtendenteService } from '../shared/services/atendente.service';

@Component({
  selector: 'app-usuario-detalhe',
  templateUrl: './usuario-detalhe.component.html',
  styleUrls: ['./usuario-detalhe.component.scss']
})
export class UsuarioDetalheComponent implements OnInit {

  selectedPessoa: Pessoa = new Pessoa();
  canais: Canal[];
  selectedItems = [];
  dropdownSettings: any = {};
  isAtendente: string;
  ShowFilter = false;
  atendenteArea: string;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private readonly loginStore: LoginStore,
    private readonly atendenteStore: AtendenteStore,
    private readonly atendenteService: AtendenteService,
    private readonly atendenteCanalService: AtendenteCanalService,
    private readonly pessoaService: PessoaService,
    private readonly router: Router
  ) { }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nome',
      selectAllText: 'Marca todos',
      unSelectAllText: 'Desmarcar todos',
      itemsShowLimit: 6,
      allowSearchFilter: this.ShowFilter
    };

    this.form = this.formBuilder.group({
      Canais: [this.selectedItems, Validators.required]
    });

    this.loginStore.getCurrent().subscribe(usuario => {
      this.pessoaService.getByIdPessoa(usuario.idPessoa).subscribe(pessoa => {
        if (pessoa) {
          this.selectedPessoa = pessoa;
        }
      });
    });

    this.atendenteStore.getCurrent().subscribe(atendente => {
      this.isAtendente = 'Não configurado';
      this.atendenteArea = 'Não configurado';
      
      if (atendente) {
        this.isAtendente = 'Atendente';

        this.atendenteCanalService.getAll().subscribe(atendCanais => {

          this.canais = atendCanais
            .filter(x => x.atendente.id === atendente.id)
            .map(c => {
              return {
                id: c.canal.id,
                nome: c.canal.nome,
                prazo: c.canal.prazo
              };
            });
          this.selectedItems = this.canais;
          console.log(this.canais);

        });

        this.atendenteService.getByAtendenteId(atendente.id).subscribe(area => {
          this.atendenteArea = area.nome;
        });

      }
    });
  }

  back(): void {
    this.router.navigate(['project']);
  }
}
