import { Injectable } from '@angular/core';
import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { Versao } from '../models/versao.model';
import { HttpClient } from '@angular/common/http';
import { Util } from '../util';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VersaoApp } from '../models/versaoApp.model';

@Injectable()
export class VersaoService extends ApiService implements BaseService<Versao> {


    private strAction = 'Versao';

    constructor(
        httpClient: HttpClient,
        private http: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Versao[]> {
        return this.get<Versao[]>(this.strAction + `/GetAll`);
    }

    // Foi necessário criar um novo método para retornar a versão sem a necessidade de autenticação.
    getVersao(): Observable<Versao> {
        return this.get<Versao>(this.strAction + `/GetVersao`);
    }

    getVersaoJson(): Observable<VersaoApp[]> {
        return this.http.get<VersaoApp[]>('assets/versao/versao.json');
    }

    getById(versaoId: number): Observable<Versao> {
        return this.get<Versao>(this.strAction + `/GetById/${versaoId}`);
    }

    deleteById(versaoId: number) {
        return this.delete(this.strAction + `?id=${versaoId}`);
    }

    save(versao: Versao): Observable<Versao> {
        if (this.util.IsNullOrUndefined(versao.id) || versao.id === 0){
            return this.post<Versao>(this.strAction + `/Post`, versao);        
        }        
        return this.post<Versao>(this.strAction + `/Put`, versao);        
    }
}
