import { Component, Input, OnInit } from '@angular/core';
import { Integracao } from '../../shared/models/integracao';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { IntegracaoService } from '../../shared/services/integracao.service';
import { IntegracaoStore } from '../../shared/store/integracao.store';
import { Util } from '../../shared/util';
import { DialogComponent } from '../../components/message/dialog.component';

@Component({
  selector: 'app-new-integracao',
  templateUrl: './new-integracao.component.html',
  styleUrls: ['./new-integracao.component.scss']
})

export class NewIntegracaoComponent implements OnInit {

  formErrors: any;
//   id: number;
  integracao: Integracao = new Integracao();
  @Input()
  form: FormGroup;
  ShowFilter = false;
  dropdownSettings: any = {};
  metodos: string[] = ['GET', 'POST'];
  
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly integracaoService: IntegracaoService,
    private readonly integracaoStore: IntegracaoStore,
    private snackBar: MatSnackBar,
    private util: Util) {

    this.integracaoStore.getCurrent().subscribe(integracao => {
        if (integracao) {
            this.integracao = integracao;
        }
        else {
            this.integracao = new Integracao();
        }

        this.formErrors = {
            Url: {},
            Metodo: {},
            ClientId: {},
            ClientSecret: {}
        };
    });
}

ngOnInit() {
    this.form = this.formBuilder.group({
        Url: ['', Validators.required],
        ClientId: ['', Validators.required],
        ClientSecret: ['', Validators.required],
        Metodo: [this.integracao.metodo, Validators.required],
    });
    
    this.form.valueChanges.subscribe(() => {
        this.onFormValuesChanged();
    });
}

onFormValuesChanged() {
    for (const field in this.formErrors) {
        if (!this.formErrors.hasOwnProperty(field)) {
            continue;
        }
        this.formErrors[field] = {};

        const control = this.form.get(field);

        if (control && control.dirty && !control.valid) {
            this.formErrors[field] = control.errors;
        }
    }
}

setCheckedValue(e){
    if (e.checked) {
        this.integracao.ativada = 'S'
        console.log(this.integracao);
    }
    else {
        this.integracao.ativada = 'N';
        console.log(this.integracao);
    }
}

save() {
  let updateIntegracaoId = 0;
  if (!this.util.IsNullOrUndefined(this.integracao)){
      updateIntegracaoId = this.integracao.id;
  }
  this.validaIntegracao(resultado => {
      if (resultado){
          this.integracaoService.save(this.integracao).subscribe(integracao => {
              const message = this.integracao.id > 0 ? 'Integração atualizada' : 'Integração criada';
              this.snackBar.dismiss();
              this.showMessage(message);
          });
          console.log(this.integracao);
      } else {
      }
  });
  
}

validaIntegracao(callback) {
    if (this.util.IsNullOrUndefined(this.integracao.url)) {
        this.showSnackBar('A URL da integração é requerida.');
        callback(false);
    } else if (this.util.IsNullOrUndefined(this.integracao.metodo)) {
      this.showSnackBar('Um método deve ser selecionado.');
      callback(false);
    } else if (this.util.IsNullOrUndefined(this.integracao.clientId)) {
      this.showSnackBar('O Client ID da integração é requerido.');
      callback(false);
    } else if (this.util.IsNullOrUndefined(this.integracao.clientSecret)) {
      this.showSnackBar('O Client Secret da integração é requerido.');
      callback(false);
    } else {
        callback(true);
    }
}

showSnackBar(message: string): void {
    this.snackBar.open(message, 'Close');
}

showMessage(message: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
        width: '350px',
        data: { message: message }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.back();
    });
}    

back(): void {
    this.router.navigate(['/cadastro-integracao']);
}

}
