import { NgModule } from '@angular/core';
import { CadastroIntegracaoComponent } from './cadastro-integracao.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes = [
  {
      path: 'cadastro-integracao',
      component: CadastroIntegracaoComponent,
      canActivate: [AuthGuard]
  }
  
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    BrowserModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  declarations: [
    CadastroIntegracaoComponent
  ]
})
export class CadastroIntegracaoModule { }
