import { Login } from './login.model';
import { Canal } from './canal.model';
import { Area } from './area.model';

export class AtendenteDTO {
    
    // atendente: Atendente;
    id: number;
    login: Login;
    canais: Canal[];
    area: Area;
    
}
