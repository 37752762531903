import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { Chamado } from '../shared/models/chamado.model';
import { Registro } from '../shared/models/registro.model';

import { RegistroService } from '../shared/services/registro.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';

import { ChamadoStore } from '../shared/store/chamado.store';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { Situacao } from '../shared/models/situacao.model';
import { DialogComponent } from '../components/message/dialog.component';

@Component({
    selector: 'historico-chamado-app',
    templateUrl: './historico-chamado-app.component.html',
    styleUrls: ['./historico-chamado-app.component.scss'],
    
})

export class HistoricoChamadoAppComponent implements OnInit {

    situacaoId: number;
    selectedChamado: Chamado = new Chamado();
    registros: Registro[] = [];
    situacoes: Situacao[] = [];
    loadingIndicator = false;
    coluna: string;

    displayedColumns: string[] = ['id', 'situacao', 'participante', 'assunto', 'dtAbertura', 'dtPrevFim', 'dtEncerramento', 'historico'];
    //dataSource = ELEMENT_DATA;

    @ViewChild(DatatableComponent) table: DatatableComponent;
    
    constructor (
        public dialog: MatDialog, private router: Router,
        private readonly ChamadoStore: ChamadoStore,
        private readonly RegistroService: RegistroService,
        private readonly situacaoChamadoService: SituacaoChamadoService,
        ) {
    }

    ngOnInit() {
        this.ChamadoStore.getCurrent().subscribe(chamado => {
            if (chamado){
                this.selectedChamado = chamado;
                this.loadAllByChamado(chamado.id);
                this.coluna = 'DESCRIÇÃO';
            }
            else {
                // this.showMessage('Chamado não encontrado.');
                // this.chamado = new Chamado();
                // this.participante = new Pessoa();
                // this.participante.endereco = '---';
            }            
        });
    }

    loadAllByChamado(chamadoId: number) {
        this.loadingIndicator = true;
        this.RegistroService.getAllByChamado(chamadoId).subscribe(registros => {
            this.registros = _.orderBy(registros, ['situacao', 'id'], ['asc', 'asc']);
            this.loadingIndicator = false;
        });    
    }

    backToDetalhe(): void {
        this.router.navigate(['chamado-detalhe-app']);
    }

    showMessage(column: any, message: string): void {
            this.selectedChamado.descricao = message;
            this.coluna = column.name;
    }

    getSituacaoColor(situacaoId: number): string {
        if (situacaoId === 1 || situacaoId === 5) {
            // Pendentes
            return 'red-800-fg';
        }
        else if (situacaoId === 6) {
            // Em atendimento
            return 'green-700-fg';
        }
        else {
            // Outros
            return 'orange-700-fg';
        }
    }

    statusTR: boolean = false;
    openDetail() {
//        this.statusTR = !this.statusTR;
    }
}