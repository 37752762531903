import { ChamadoStore } from './../shared/store/chamado.store';
import { Component, OnInit } from '@angular/core';
import { Chamado } from '../shared/models/chamado.model';
import { Router } from '@angular/router';
import { ListaChamadoStore } from '../shared/store/listaChamado.store';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';
import { ChamadoService } from '../shared/services/chamado.service';

@Component({
  selector: 'app-filtro-detalhe',
  templateUrl: './filtro-detalhe.component.html',
  styleUrls: ['./filtro-detalhe.component.scss']
})
export class FiltroDetalheComponent implements OnInit {

  chamados: Chamado[] = [];
  chamadoStatus: ChamadoStatus = new ChamadoStatus();
  loadingIndicator = false;
  displayedColumns: string[] = ['id', 'situacao', 'participante', 'assunto', 'dtAbertura', 'dtPrevFim', 'dtEncerramento', 'historico'];
  descricao: string;
  page: number = 0
  totalChamados: number = 100

  constructor(
    private readonly listaChamadoStore: ListaChamadoStore,
    private readonly router: Router,
    private readonly chamadoStore: ChamadoStore,
    private readonly chamadoService: ChamadoService
  ) {
    this.listaChamadoStore.getCurrent().subscribe(filtrarChamadoResponse => {
      this.chamados = filtrarChamadoResponse.chamados;
      this.totalChamados = filtrarChamadoResponse.total;

      this.descricao = localStorage.getItem('DescricaoFiltro');
    });
  }

  ngOnInit() {

  }

  verDetalhe(chamadoId: number): void {
    const chamado = this.chamados.find(c => c.id === chamadoId);
    this.chamadoStore.update(chamado);
    this.router.navigate(['chamado-detalhe-app']);
  }

  backToProject(): void {
    this.router.navigate(['project']);
  }

  changePage(event: any) {
    console.log('xd')
    this.page = event.page
    let filtroChamado = JSON.parse(localStorage.getItem('filtroChamado'));
    filtroChamado.currentPage = this.page - 1
    console.log(filtroChamado)
    this.chamadoService.filtroChamado(filtroChamado).subscribe(listaChamados => {
      this.chamados = listaChamados.chamados
      console.log(listaChamados.chamados)
      console.log('xda')
    });
  }

}
