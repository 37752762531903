import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { Inject, Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from'@angular/router';
import { AtendentesAppComponent } from './atendentes-app.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'atendentes-app',
        component: AtendentesAppComponent,
        canActivate: [AuthGuard]
    }

];

@NgModule({
    declarations: [
        AtendentesAppComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule
    ],
    exports: [
        AtendentesAppComponent
    ]

})
export class AtendentesAppModule { }