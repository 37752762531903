import { LoginService } from './../../shared/services/login.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { MatDialog, MatSnackBar } from '@angular/material';

import { Atendente } from '../../shared/models/atendente.model';
import { AtendenteCanal } from '../../shared/models/atendenteCanal.model';
import { Canal } from '../../shared/models/canal.model';
import { Area } from '../../shared/models/area.model';
import { Pessoa } from '../../shared/models/pessoa.model';
import { AtendenteCanalSaveDTO } from '../../shared/models/atendenteCanalSaveDTO.model';

import { AtendenteService } from '../../shared/services/atendente.service';
import { AtendenteCanalService } from '../../shared/services/atendenteCanal.service';
import { CanalService } from '../../shared/services/canal.service';
import { AreaService } from '../../shared/services/area.service';
import { PessoaService } from '../../shared/services/pessoa.service';

import { AtendenteStore } from '../../shared/store/atendente.store';
import { DialogComponent } from '../../components/message/dialog.component';

import { Util } from '../../shared/util';
import { Login } from '../../shared/models/login.model';
import { Observable } from 'rxjs';
import { startWith, map, filter, switchMap, debounceTime, tap, finalize } from 'rxjs/operators';


@Component({
    selector: 'new-atendente',
    templateUrl: './new-atendente.component.html',
    styleUrls: ['./new-atendente.component.scss']
})

export class NewAtendenteComponent implements OnInit {
    formErrors: any;
    teste: any;
    id: number;
    atendente: Atendente = new Atendente();
    atendenteCanais: AtendenteCanal[];
    // filteredAtendenteCanais: AtendenteCanal[];
    canais: Canal[];
    areas: Area[];
    filteredLogin: Login[] = [];
    listaLogin: Login[];
    dropdownSettings: any = {};
    selectedItems = [];
    ShowFilter = false;
    limitSelection = false;
    displayedColumns: string[] = ['nome', 'acoes'];
    selectedLogin: any;
    selectedCanais: Canal[] = [];
    isLoading = false;
    selectedArea: number;

    @Input()
    form: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private readonly router: Router,
        private atendenteService: AtendenteService,
        private atendenteCanalService: AtendenteCanalService,
        private canalService: CanalService,
        private areaService: AreaService,
        private loginService: LoginService,
        private pessoaService: PessoaService,
        private readonly atendenteStore: AtendenteStore,
        private snackBar: MatSnackBar,
        private util: Util) {

        this.atendenteStore.getCurrent().subscribe(atendente => {
            if (atendente) {
                this.atendente = atendente;
                this.selectedLogin = atendente.login;
                this.selectedArea = this.atendente.area.id;

                this.loadAtendenteCanais(atendente.id);
            }
            else {
                this.atendente = new Atendente();
            }

            this.loadAreas();
            this.loadCanais();


            this.formErrors = {
                Nome: {},
            };
        });
    }

    ngOnInit() {

        this.form = this.formBuilder.group({

            Login: [this.selectedLogin, Validators.required],
            Canais: [this.selectedItems, Validators.required],
            Area: [this.selectedItems, Validators.required]
        });


        this.selectedItems = this.selectedCanais;
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'nome',
            selectAllText: 'Marca todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 6,
            allowSearchFilter: this.ShowFilter
        };

        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });



        this.formularioLogin.Login
            .valueChanges
            .pipe(
                debounceTime(300),
                tap(() => { this.isLoading = true;
                            this.filteredLogin = []; }),
                switchMap(value =>
                    this.loginService.GetFiltroByLogin(value)
                        .pipe(
                            finalize(() => this.isLoading = false),
                        )
                )
            )
            .subscribe(login => {
                if (login === undefined) {
                    this.filteredLogin = [];
                } else {
                    this.filteredLogin = login;
                }});
    }

    get formularioLogin() {
        return this.form.controls;
    }

    loadAtendenteCanais(atendenteId: number) {
        this.atendenteCanalService.getAllByAtendenteId(atendenteId).subscribe(atendenteCanais => {
            this.selectedItems = atendenteCanais
                .filter(x => x.atendente.id === atendenteId)
                .map(c => {
                    return {
                        id: c.canal.id,
                        nome: c.canal.nome,
                        // prazo: c.canal.prazo
                    };
                });
        });
    }

    displayFn(login: Login) {
        if (login) { return login.idLogin; }
    }


    loadLogin() {
        this.loginService.getAll().subscribe(login => {
            this.listaLogin = login;
        });
    }

    loadAreas() {
        this.areaService.getAll().subscribe(areas => {
            this.areas = areas;
        });
    }

    loadCanais() {
        this.canalService.getAll().subscribe(canais => {
            this.canais = canais;
        });
    }

    onItemSelect(item: any) {
        console.log('onItemSelect', item);
    }

    onSelectAll(items: any) {
        console.log('onSelectAll', items);
    }

    toogleShowFilter() {
        this.ShowFilter = !this.ShowFilter;
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
    }

    handleLimitSelection() {
        if (this.limitSelection) {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
        } else {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
        }
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};

            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    // buscaPessoaByEmail(pessoaEmail: string) {
    //     this.pessoaService.getByEmail(pessoaEmail).subscribe(pessoa => {
    //         if (this.util.IsNullOrUndefined(pessoa)) {
    //             this.showSnackBar('O e-mail do atendente não foi encontrado na base da Trust');
    //         } else {
    //             this.atendente.email = pessoa.email;
    //             this.atendente.nome = pessoa.nome;
    //         }
    //     });
    // }

    saveAtendente() {
        this.atendente.login = this.formularioLogin.Login.value;
        console.log(this.atendente.login);
        console.log('salvando');
        let updateAtendenteId = 0;
        if (!this.util.IsNullOrUndefined(this.atendente)) {
            updateAtendenteId = this.atendente.id;
        }
        this.validaAtendente(resultado => {
            if (resultado) {
                this.atendente.area = this.areas.find(a => a.id === this.selectedArea);
                this.atendenteService.save(this.atendente).subscribe(atendente => {
                    const atendenteCanaisSaveDTO: AtendenteCanalSaveDTO = new AtendenteCanalSaveDTO();
                    // Se for alteração de atendente
                    if ((!this.util.IsNullOrUndefined(updateAtendenteId)) && (updateAtendenteId !== 0)) {
                        atendenteCanaisSaveDTO.atendenteId = updateAtendenteId;
                    } else {
                        console.log('atendenteID retornado: ', atendente.id);
                        atendenteCanaisSaveDTO.atendenteId = atendente.id;
                    }
                    const tmpCanais: number[] = [];
                    for (const item of this.selectedItems) {
                        tmpCanais.push(item.id);
                    }
                    atendenteCanaisSaveDTO.canalIds = tmpCanais;
                    console.log(atendenteCanaisSaveDTO);

                    this.atendenteCanalService.saveCanais(atendenteCanaisSaveDTO).subscribe(atendenteCanal => {
                        console.log('Salvou!!');
                        const message = this.atendente.id > 0 ? 'Atendente atualizado' : 'Atendente criado';
                        this.snackBar.dismiss();
                        this.showMessage(message);
                    });
                });
            } else {
                console.log('Não salvou!!');
            }
        });
    }

    validaAtendente(callback) {
        if (this.util.IsNullOrUndefined(this.atendente.login)) {
            this.showSnackBar('O login é requerido.');
            callback(false);
        } else {
            callback(true);

            // } else if (this.util.IsNullOrUndefined(this.atendente.nome)) {
            //     this.showSnackBar('O nome do atendente é requerido.');
            //     callback(false);
            // } else if (this.util.IsNullOrUndefined(this.selectedArea)) {
            //     this.showSnackBar('O atendente deve ser associado a uma área.');
            //     callback(false);
            // } else {
            //     this.verificaAtendente(this.atendente.email, callback);
        }
    }

    verificaAtendente(idlogin: string, callback) {
        this.loginService.getById(idlogin).subscribe(pessoa => {
            let retorno = false;
            console.log(pessoa);
            if (this.util.IsNullOrUndefined(pessoa)) {
                this.showSnackBar('O login não foi encontrado na base da Trust');
                retorno = false;
            } else {
                retorno = true;
            }
            callback(retorno);
        });
    }

    showSnackBar(message: string): void {
        this.snackBar.open(message, 'Close');
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.back();
        });
    }

    back(): void {
        this.router.navigate(['/cadastro-atendente']);
    }

}
