import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Util } from '../util';
import { SituacaoRelacao } from '../models/situacaoRelacao.model';
import { SituacaoRelacaoSaveDTO } from '../models/situacaoRelacaoSaveDTO.models';

@Injectable()
export class SituacaoRelacaoService extends ApiService implements BaseService<SituacaoRelacao> {

    private strAction = 'SituacaoFluxo';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<SituacaoRelacao[]> {
        return this.get<SituacaoRelacao[]>(this.strAction + `/GetAll`);
    }

    getById(situacaoId: number): Observable<SituacaoRelacao> {
        return this.get<SituacaoRelacao>(this.strAction + `/GetById/${situacaoId}`);
    }

    getBySituacaoOrigem(origemId: number): Observable<SituacaoRelacao[]> {
        return this.get<SituacaoRelacao[]>(this.strAction + `/GetBySituacaoOrigem?origem=${origemId}`);
    }

    deleteById(situacaoRelacaoId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Access-Control-Allow-Origin', '*');
        return this.post(this.strAction + `/DeleteById?id=${situacaoRelacaoId}`);
    }

    deleteBySituacaoId(situacaoId: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        headers.append('Access-Control-Allow-Origin', '*');
        return this.post(this.strAction + `/Situacao/DeleteById/${situacaoId}`);
    }

    saveSituacoes(situacaoRelacaoSaveDTO: SituacaoRelacaoSaveDTO): Observable<SituacaoRelacaoSaveDTO> {
        return this.post<SituacaoRelacaoSaveDTO>(this.strAction + `/PostSituacaoFluxo`, situacaoRelacaoSaveDTO);

    }

    save(situacaoRelacao: SituacaoRelacao): Observable<SituacaoRelacao> {
        if (this.util.IsNullOrUndefined(situacaoRelacao.id) || situacaoRelacao.id === 0) {
            return this.post<SituacaoRelacao>(this.strAction + `/Post`, situacaoRelacao);
        }
        return this.post<SituacaoRelacao>(this.strAction + `/Put`, situacaoRelacao);
    }
}
