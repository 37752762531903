import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';


export interface PeriodicElement {
    id :string;
    usuario: string;
    actions: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {id: '1234', usuario: 'João Pedro',  actions: '' },
  {id: '5678', usuario: 'Marcos Almeida',  actions: '' },
  {id: '91011', usuario: 'Mariana Castro',  actions: '' },
  {id: '1213', usuario: 'Bruno Correa', actions: '' },
  {id: '1415', usuario: 'João Pedro',  actions: '' },
  {id: '1617', usuario: 'João Pedro',  actions: '' },
  {id: '1819', usuario: 'Mariana Castro',  actions: '' },
  {id: '2021', usuario: 'Bruno Correa', actions: '' },
];

@Component({
    selector: 'permission-app',
    templateUrl: './permission-app.component.html',
    styleUrls: ['./permission-app.component.scss'],
    
   })
   export class PermissionAppComponent {

        displayedColumns: string[] = [ 'id', 'usuario', 'actions'];
        dataSource = ELEMENT_DATA;


        constructor(public dialog: MatDialog, private router:Router ) {
     
      
        }

    // showMessage(message: string): void {
    
    // }

    // closeModal(): void {
    //     this.dialogRef.close();
    // }

    backToProject():void {
        this.router.navigate(["history-app"]);
      }

      newApp():void {
        this.router.navigate(["create-app"]);
      }
}