import { Inject, Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule, MatTooltipModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { CadastroAtendenteComponent } from './cadastro-atendente.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'cadastro-atendente',
        component: CadastroAtendenteComponent,
        canActivate: [AuthGuard]
    },
    
];

@NgModule({
    declarations: [
        CadastroAtendenteComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule,
        NgMultiSelectDropDownModule,
        MatTooltipModule

    ],
    exports: [
        CadastroAtendenteComponent
    ]

})

export class CadastroAtendenteModule { }
