import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector   : 'errorpage-component',
    templateUrl: './errorpage.component.html',
    styleUrls  : ['./errorpage.component.scss']
})
export class ErrorPageComponent
{
    public message: string;

    constructor(private route:Router)
    {
        
    }

    gotoDashBoard() : void {
        this.route.navigate(['project']);
    }

}
