import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Chamado } from '../shared/models/chamado.model';
import { Atendente } from '../shared/models/atendente.model';
import { ChamadoService } from '../shared/services/chamado.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';
import { AtendenteStore } from '../shared/store/atendente.store';
import { ChamadoStore } from '../shared/store/chamado.store';
import * as _ from 'lodash';
import { Situacao } from '../shared/models/situacao.model';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';
import { EventEmitterService } from '../../content/shared/services/eventEmitter.service';
import { FiltraChamadoAtendenteRequest } from '../shared/models/filtraChamadoAtendenteRequest.model';

@Component({
    selector: 'meus-atendimentos-app',
    templateUrl: './meus-atendimentos-app.component.html',
    styleUrls: ['./meus-atendimentos-app.component.scss'],
})

export class MeusAtendimentosAppComponent implements OnInit {

    chamados: Chamado[] = [];
    chamadoStatus: ChamadoStatus = new ChamadoStatus();
    filtraChamadoAtendenteRequest: FiltraChamadoAtendenteRequest = new FiltraChamadoAtendenteRequest();
    selectedChamados = [];
    selectedAtendente: Atendente = new Atendente();
    displayedColumns: string[] = ['id', 'situacao', 'participante', 'assunto', 'dtAbertura', 'dtPrevFim', 'dtEncerramento', 'historico'];
    statusTR = false;
    loadingIndicator = false;
    statusDoChamado = true;
    totalAtendimentos: number;
    page: number = 0;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        public dialog: MatDialog,
        private readonly router: Router,
        private readonly chamadoService: ChamadoService,
        private readonly situacaoChamadoService: SituacaoChamadoService,
        private readonly atendenteStore: AtendenteStore,
        private readonly chamadoStore: ChamadoStore,
        private readonly chamadoStatusService: ChamadoStatusService
    ) {
    }

    ngOnInit() {
        EventEmitterService.get('atualizaChamadosPorCanal').emit();

        this.atendenteStore.getCurrent().subscribe(atendente => {
            if (atendente) {
                this.selectedAtendente = atendente;
                this.loadChamadosPorAtendente(atendente.id);
            }
            else {
                // this.selectedAtendente = 1;
                this.loadChamadosPorAtendente(1);
                // this.backToProject();
            }
        });
    }

    loadChamadosPorAtendente(atendenteId: number) {
        this.loadingIndicator = true;
        this.filtraChamadoAtendenteRequest.atendenteId = atendenteId;
        this.filtraChamadoAtendenteRequest.currentPage = 0;
        this.filtraChamadoAtendenteRequest.pageSize = 9;
        this.chamadoService.getAllNaoConcluidosPaginadoByAtendente(this.filtraChamadoAtendenteRequest).subscribe(chamados => {
            const chamadoNaoPendentes = _.filter(chamados.chamados, function (o) { return o.situacao.id !== 1; });
            
            this.chamados = chamadoNaoPendentes;
            this.totalAtendimentos = chamados.total;
            
                        // this.chamados = chamados;
            this.loadingIndicator = false;
        });
    }

    getNomeSituacao(situacao: Situacao): string {
        return this.situacaoChamadoService.getSituacaoNome(situacao.id);
    }

    getSituacaoColor(situacaoId: number): string {
        if (situacaoId === 1 || situacaoId === 5) {
            // Pendentes
            return 'red-800-fg';
        }
        else if (situacaoId === 6) {
            // Em atendimento
            return 'green-700-fg';
        }
        else {
            // Outros
            return 'orange-700-fg';
        }
    }

    backToProject(): void {
        this.router.navigate(['project']);
    }

    newApp(): void {
        this.router.navigate(['create-app']);
    }

    permission(): void {
        this.router.navigate(['permission-app']);
    }

    viewCalendar(): void {
        this.router.navigate(['calendar-app']);
    }

    // onSelect() {
    //     console.log('Selected Event: ', this.selectedChamado);
    //     // Grava chamado
    //     this.verDetalhe();        
    // }


    verDetalhe(chamadoId: number): void {

        const chamado = this.chamados.find(c => c.id === chamadoId);
        this.chamadoStore.update(chamado);
        this.router.navigate(['chamado-detalhe-app']);
    }

    openDetail() {
        this.statusTR = !this.statusTR;
    }

    changePage(event: any) {
        this.page = event.page
        this.filtraChamadoAtendenteRequest.atendenteId = this.selectedAtendente.id;
        this.filtraChamadoAtendenteRequest.pageSize = 9;
        this.filtraChamadoAtendenteRequest.currentPage = this.page - 1;
        this.chamadoService.getAllNaoConcluidosPaginadoByAtendente(this.filtraChamadoAtendenteRequest).subscribe(chamados => {
            const chamadoNaoPendentes = _.filter(chamados.chamados, function (o) { return o.situacao.id !== 1  });
            this.chamados = chamadoNaoPendentes;

            // this.chamados = chamados;
            this.loadingIndicator = false;
        });
    }
}
