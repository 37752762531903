import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Resumo } from '../../shared/models/resumo.model';
import { ResumoStore } from '../../shared/store/resumo.store';

@Component({
  selector: 'app-ultimos-lancamentos',
  templateUrl: './ultimos-lancamentos.component.html',
  styleUrls: ['./ultimos-lancamentos.component.scss']
})
export class UltimosLancamentosComponent {
  public message: string;
  ultimoLancamento: Resumo[] = [];
  anoReferencia: any;
  mesReferencia: any;

  displayedColumns: string[] = ['descricao', 'valor'];
  constructor(public dialogRef: MatDialogRef<UltimosLancamentosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly resumoStore: ResumoStore) {
    this.message = data.message;

    this.resumoStore.getCurrent().subscribe(resumos => {
      if (resumos){
      this.ultimoLancamento = resumos;
      this.anoReferencia = resumos[0].anoCompetencia;
      this.mesReferencia = resumos[0].mesCompetencia;
      
      }
      

    });
  }

}
