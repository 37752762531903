export class Plano {
    
    pessoaIid: number;
    planoId: number;
    nomePlano: string;
    inicioPlano: Date;
    fimPlano: Date;
    inicioAuto: Date;
    fimAuto: Date;
    parcial: string;
    tributacao: string;
    calculoAuto: string;
    categoriaid: number;
    situacaoid: number;
    categoria: string;
    situacao: string;
    
}
