import { Inject, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { ConfigAdminComponent } from '../configAdmin/config-admin.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectModule, MatInputModule,  MatPaginatorModule, MatSortModule } from '@angular/material';

import { Assunto } from '../shared/models/assunto.model';
import { Atendente } from '../shared/models/atendente.model';
import { Chamado } from '../shared/models/chamado.model';
import { Situacao } from '../shared/models/situacao.model';
import { Pessoa } from '../shared/models/pessoa.model';

import { AssuntoService } from '../shared/services/assunto.service';
import { AtendenteService } from '../shared/services/atendente.service';
import { ChamadoService } from '../shared/services/chamado.service';
import { PessoaService } from '../shared/services/pessoa.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';

import { ChamadoStore } from '../shared/store/chamado.store';

// enum enumSituacao {
//     Pendente = 0,
//     EmAtendimento = 1,
//     Concluido = 2,
//     Reaberto = 3,
//     Cancelado = 4,
// }

@Component({
    selector: 'chamado-detalhe-tmp',
    templateUrl: './chamado-detalhe-tmp.component.html',
    styleUrls: ['./chamado-detalhe-tmp.component.scss'],
})

export class ChamadoDetalheTmpAppComponent implements OnInit {    

    assunto: Assunto = new Assunto();
    atendente: Atendente = new Atendente();
    chamado: Chamado = new Chamado();
    situacao: Situacao = new Situacao();
    participante: Pessoa = new Pessoa();
    situacoesPossiveis: Situacao[];
    delegarChamado = false;
    step = 0;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private assuntoService: AssuntoService,
        private atendenteService: AtendenteService,
        private chamadoService: ChamadoService,
        private pessoaService: PessoaService,
        private situacaoChamadoService: SituacaoChamadoService,
        private chamadoStore: ChamadoStore,
        ) {
    }

    ngOnInit(){
        this.chamadoStore.getCurrent().subscribe(chamado => {
            if (chamado){
                this.loadChamado(chamado.id);
            }
            else {
                this.loadChamado(44);                
            }
            this.setStep(0);
        });
    }

    loadChamado(chamadoId: number) {
        this.chamadoService.getById(chamadoId).subscribe(chamado => {
            this.chamado = chamado;
            this.situacao.id = chamado.situacao.id;
            this.situacoesPossiveis = this.situacaoChamadoService.getComboSituacoesPossiveis(chamado.situacao.id);
            console.log(this.situacoesPossiveis);
            this.situacao.nome = this.nomeSituacao(chamado.situacao.id);
            this.assuntoService.getById(chamado.assunto.id).subscribe(assunto => {
                this.assunto = assunto;
            });
            this.atendenteService.getById(chamado.atendente.id).subscribe(atendente => {
                this.atendente = atendente;
            });
            this.pessoaService.getByCPF(chamado.cpf).subscribe(participante => {
                console.log(participante);
                this.participante = participante;
            });

        });
    }

    nomeSituacao(situacaoId: number): string {

        const sitNome = this.situacaoChamadoService.getSituacaoNome(situacaoId);
        // let sitNome = '';
        // if (situacaoId === enumSituacao.Pendente) {
        //     sitNome = 'Pendente';
        // } else if (situacaoId === enumSituacao.EmAtendimento) {
        //     sitNome = 'Em Atendimento';
        // } else if (situacaoId === enumSituacao.Concluido) {
        //     sitNome = 'Concluído';
        // } else if (situacaoId === enumSituacao.Reaberto) {
        //     sitNome = 'Reaberto';
        // } else if (situacaoId === enumSituacao.Cancelado) {
        //     sitNome = 'Cancelado';
        // }
        return sitNome;
    }

    backToProject(): void {
        this.router.navigate(['project']);
    }

    historicoChamado(): void {
        this.router.navigate(['historico-chamado-app']);
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    getSexo(sexo: string) {
        console.log(sexo === 'F' ? 2 : 1);
        return (sexo === 'F' ? 2 : 1);
    }

    newAtendente(): void {
        this.router.navigate(['cadastro-atendente-app']);
    }



}