export class Login {
    idLogin: string;
    idPessoa: number;
    senha: string;
    isAtivo: string;
    numeroTentativa: number;
    senhaAnterior1: string;
    senhaAnterior2: string;
    senhaAnterior3: string;
    senhaAnterior4: string;
    senhaAnterior5: string;
    dataAtualizacao: Date;
    isExpira: string;
    isAtendente: string;
    token: string;
  
    Login() { }
  }