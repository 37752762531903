import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../../components/message/dialog.component';
// import { DatatableComponent } from '@swimlane/ngx-datatable';

import { Canal } from '../../shared/models/canal.model';
import { CanalService } from '../../shared/services/canal.service';
import { CanalStore } from '../../shared/store/canal.store';
import { Util } from '../../shared/util';

@Component({
    selector: 'new-canal',
    templateUrl: './new-canal.component.html',
    styleUrls: ['./new-canal.component.scss']
})

export class NewCanalComponent implements OnInit {
    formErrors: any;
    id: number;
    canal: Canal = new Canal();

    @Input()
    form: FormGroup;

    constructor(
        // private fuseTranslationLoader: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private readonly router: Router,
        private canalService: CanalService,
        private readonly canalStore: CanalStore,
        private snackBar: MatSnackBar,        
        private util: Util) {
        
        this.canalStore.getCurrent().subscribe(canal => {
            if (canal){
                this.canal = canal;
            }
            else {
                this.canal = new Canal();
            }
            this.formErrors = {
                Nome: {},
                Prazo: {},
            };
        });
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            Nome: ['', Validators.required],
            Prazo: ['', Validators.required]
        });
        
        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if(!this.formErrors.hasOwnProperty(field)){
                continue;
            }
            this.formErrors[field] = {};

            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    save() {
        console.log('salvando');
        this.validaCanal(resultado => {
            if (resultado){
                this.canalService.save(this.canal).subscribe(canal => {
                    const message = this.canal.id > 0 ? 'Canal atualizado' : 'Canal criado';
                    this.showMessage(message);
                    // this.canal = canal;
                });
            }
        });
    }

    validaCanal(callback) {
        if (this.util.IsNullOrUndefined(this.canal.nome)) {
            this.showSnackBar('O nome do canal é requerido.');
            callback(false);
        } else if (this.util.IsNullOrUndefined(this.canal.prazo)) {
            this.showSnackBar('O prazo de atendimento do canal é requerido.');
            callback(false);
        } else if (this.canal.prazo <= 0) {
            this.showSnackBar('O prazo de atendimento do canal deve ser maior que zero.');
            callback(false);
        } else {
            callback(true);
        }
    }

    showSnackBar(message: string): void {
        this.snackBar.open(message, 'Close');
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/cadastro-canal']);
        });
    }    

    back(): void {
        this.router.navigate(['cadastro-canal']);
    }
    
}
