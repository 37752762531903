import { UltimosLancamentosModule } from './ultimos-lancamentos/ultimos-lancamentos.module';
import { TempoServicoModule } from './tempo-servico/tempo-servico.module';
import { SharedModule } from './../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule } from '@angular/material'; 
import { MatTabsModule } from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ChamadoDetalheAppComponent } from './chamado-detalhe-app.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../shared/guards/auth.guard';
import { InserirRegistroChamadoModule } from '../inserir-registro-chamado/inserir-registro-chamado.module';
import { NgxMaskModule } from 'ngx-mask';
import { TempoServicoComponent } from './tempo-servico/tempo-servico.component';


const routes = [
  {
    path: 'chamado-detalhe-app',
    component: ChamadoDetalheAppComponent,
    canActivate: [AuthGuard]
  }

];

@NgModule({
   declarations: [
      ChamadoDetalheAppComponent
   ],
   imports: [
      RouterModule.forChild(routes),
      BrowserModule,
      BrowserAnimationsModule,
      MatSelectModule,
      MatIconModule,
      MatInputModule,
      MatButtonModule,
      MatTableModule,
      MatDialogModule,
      MatPaginatorModule,
      MatFormFieldModule,
      MatExpansionModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      MatNativeDateModule,
      ReactiveFormsModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      InserirRegistroChamadoModule,
      SharedModule,
      TempoServicoModule,
      UltimosLancamentosModule
   ],
   exports: [
      ChamadoDetalheAppComponent
   ]
})
export class ChamadoDetalheAppModule { }