import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { BaseService } from '../../../../core/base.service';

import { Chamado } from '../models/chamado.model';
import { ChamadosTotalizadores } from '../models/chamadosTotalizadores.model';
import { DataMatrizAtendDia } from '../models/dataMatrizAtendDia.model';
import { DataMatrizRetaguarda } from '../models/dataMatrizRetaguarda.model';
import { DataMatrizAtrasos } from '../models/dataMatrizAtrasos.model';
import { AlterarChamado } from '../models/alterarChamado.model';

import { Util } from '../util';
import { FiltrarChamadoRequest, FiltrarChamadoResponse, FiltroChamado } from '../models/filtroChamado.model';
import { ChamadoDTO } from '../models/chamadoDTO.model';
import { FiltraChamadoAtendenteRequest } from '../models/filtraChamadoAtendenteRequest.model';
//import * as _ from '../../../../../../node_modules/ loadash';

// import { version } from 'punycode';

@Injectable()
export class ChamadoService extends ApiService implements BaseService<Chamado> {

    private strAction = 'Chamado';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    filtrarChamados(request: FiltrarChamadoRequest): Observable<FiltrarChamadoResponse> {
        return this.post<FiltrarChamadoResponse>(this.strAction + `/FiltrarChamados`, request);
    }

    filtrarChamadosRelatorio(request: FiltrarChamadoRequest): Observable<FiltrarChamadoResponse> {
        return this.post<FiltrarChamadoResponse>(this.strAction + `/FiltrarChamadosRelatorio`, request);
    }

    getAll(): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAll`);
    }

    getTotalChamados(): Observable<number> {
        return this.get<number>(this.strAction + `/GetTotalChamados`);
    }

    getAllByPage(page: number): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAllByPage?actualPage=${page}&pageSize=9`);
    }

    getById(chamadoId: number): Observable<Chamado> {
        return this.get<Chamado>(this.strAction + `/GetById/${chamadoId}`);
    }

    getAtendimentosDia(): Observable<DataMatrizAtendDia[]> {
        return this.get<DataMatrizAtendDia[]>(this.strAction + `/GetAtendimentosDia`);
    }

    getChamadosRetaguarda(): Observable<DataMatrizRetaguarda[]> {
        return this.get<DataMatrizRetaguarda[]>(this.strAction + `/GetChamadosRetaguarda`);
    }

    getChamadosAtraso(): Observable<DataMatrizAtrasos[]> {
        return this.get<DataMatrizAtrasos[]>(this.strAction + `/GetChamadosAtraso`);
    }

    getAllByAtendente(atendenteId: number): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAllByAtendente?id=${atendenteId}`);
    }

    getAllNaoConcluidosByAtendente(atendenteId: number): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAllNaoConcluidosByAtendente?atendenteId=${atendenteId}`);
    }
    getAllNaoConcluidosPaginadoByAtendente(filtraChamadoAtendenteRequest: FiltraChamadoAtendenteRequest) {
        return this.post<FiltrarChamadoResponse>(this.strAction + `/GetAllNaoConcluidosPaginadoByAtendente`, filtraChamadoAtendenteRequest);
    }
    getAllByCanal(canalId): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAllByCanal?id=${canalId}`);
    }

    getAllByCanalAreaAtendente(canalId: number, areaId: number, atendenteId: number): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetAllByCanalAreaAtendente?canalId=${canalId}&areaId=${areaId}&atendenteId=${atendenteId}`);
    }

    getPendentesByCanal(canalId): Observable<Chamado[]> {
        return this.get<Chamado[]>(this.strAction + `/GetPendentesByCanal?id=${canalId}`);
    }

    getTotalizadores(): Observable<ChamadosTotalizadores[]> {
        return this.get<ChamadosTotalizadores[]>(this.strAction + `/GetTotalizadores`);
    }

    deleteById(chamadoId: number) {
        // return this.post(this.strAction + `/DeleteById?id=${areaID}`, JSON.stringify(area), { headers: headers });
        return this.post(this.strAction + `/DeleteById?id=${chamadoId}`);
    }

    alterarChamado(alterarChamado: AlterarChamado) {
        return this.post(this.strAction + `/AlterarChamado`, alterarChamado);
    }

    atenderChamado(chamado: Chamado): Observable<Chamado> {
        return this.post<Chamado>(this.strAction + `/AtenderChamado`, chamado);
    }

    save(chamado: Chamado): Observable<Chamado> {
        if (this.util.IsNullOrUndefined(chamado.id) || chamado.id === 0) {
            return this.post<Chamado>(this.strAction + `/SalvaChamado`, chamado);
        }
        return this.post<Chamado>(this.strAction + `/Put`, chamado);
    }

    novoChamado(chamadoDTO: ChamadoDTO): Observable<Chamado> {
        return this.post<Chamado>(this.strAction + `/NovoChamado`, chamadoDTO);
    }


    salvarChamado(chamadoDTO: ChamadoDTO): Observable<Chamado> {
        if (this.util.IsNullOrUndefined(chamadoDTO.chamado.id) || chamadoDTO.chamado.id === 0) {
            return this.post<Chamado>(this.strAction + `/SalvaChamado`, chamadoDTO);
        }
        return this.post<Chamado>(this.strAction + `/Put`, chamadoDTO);
    }

    filtroChamado(filtroChamado: FiltroChamado): Observable<FiltrarChamadoResponse> {
        return this.post<FiltrarChamadoResponse>(this.strAction + `/FiltroChamado`, filtroChamado);
    }
}
