import { SharedModule } from './../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MeusAtendimentosAppComponent } from './meus-atendimentos-app.component';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { AuthGuard } from '../shared/guards/auth.guard';
const routes = [
    {
        path: 'meus-atendimentos-app',
        component: MeusAtendimentosAppComponent,
        canActivate: [AuthGuard]
    }
    
];

@NgModule({
    declarations: [
        MeusAtendimentosAppComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSelectModule,
        MatExpansionModule,
        NgxDatatableModule,
        SharedModule
    ],
    exports: [
        MeusAtendimentosAppComponent
    ]

})
export class MeusAtendimentosAppModule { }