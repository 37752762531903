import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { Inject, Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material'; 
import { MatTabsModule } from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { InserirRegistroChamadoComponent } from './inserir-registro-chamado.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from '../shared/guards/auth.guard';


const routes = [
    {
        path: 'inserir-registro-chamado',
        component: InserirRegistroChamadoComponent,
        canActivate: [AuthGuard]
    }

];

@NgModule({
    declarations: [
        InserirRegistroChamadoComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatRadioModule,
        MatCheckboxModule,
        MatDatepickerModule
    ],
    exports: [
        InserirRegistroChamadoComponent
    ]

})
export class InserirRegistroChamadoModule { }