import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Chamado } from '../models/chamado.model';

@Injectable()
export class ChamadoStore {

    private subject = new BehaviorSubject<Chamado>(null);
    chamado = this.subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Chamado> {
        return this.chamado;
    }

    update (chamado: Chamado) {
        this.subject.next(chamado);
    }
}