import { AreaService } from './../shared/services/area.service';
import { ChamadoDTO } from './../shared/models/chamadoDTO.model';
import { ChamadoStore } from './../shared/store/chamado.store';
import { SituacaoChamadoService } from './../shared/services/situacaoChamado.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { Assunto } from '../shared/models/assunto.model';
import { Atendente } from '../shared/models/atendente.model';
import { Canal } from '../shared/models/canal.model';
import { Chamado } from '../shared/models/chamado.model';
import { Pessoa } from '../shared/models/pessoa.model';
import { Plano } from '../shared/models/plano.model';
import { AssuntoService } from '../shared/services/assunto.service';
import { CanalService } from '../shared/services/canal.service';
import { ChamadoService } from '../shared/services/chamado.service';
import { PessoaService } from '../shared/services/pessoa.service';
import { DialogComponent } from '../components/message/dialog.component';
import { Util } from '../shared/util';
import { AtendenteStore } from '../shared/store/atendente.store';
import { AtendenteCanalService } from '../shared/services/atendenteCanal.service';
import { AtendenteCanal } from '../shared/models/atendenteCanal.model';
import { Area } from '../shared/models/area.model';
import { EventEmitterService } from '../../content/shared/services/eventEmitter.service';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { throwError } from 'rxjs';
@Component({
    selector: 'cadastrar-chamado-app',
    templateUrl: './cadastrar-chamado-app.component.html',
    styleUrls: ['./cadastrar-chamado-app.component.scss'],
})

export class CadastrarChamadoAppComponent implements OnInit {

    desabilitaAssunto = false;
    mostraAvisoBeneficiario = false;
    chamadoBeneficiario = '';
    atendente: Atendente = new Atendente();
    canais: Canal[];
    areas: Area[] = [];
    selectedArea: number;
    concluirChamado: boolean;
    chamado: Chamado = new Chamado();
    chamadoDTO: ChamadoDTO = new ChamadoDTO();
    assuntos: Assunto[];
    pessoa: Pessoa = new Pessoa();
    participante: Pessoa = new Pessoa();
    beneficiario: Pessoa = new Pessoa();
    listaPessoa: Pessoa[] = [];
    planos: Plano[] = [];
    beneficio: string;
    desabilitaCheck = true;
    emptyParticipante: Pessoa = new Pessoa();
    informedCPF: string;
    selectedAssunto: number;
    selectedCanal: number;
    selectedPlano: number;
    atendenteCanais: AtendenteCanal[];
    hiddenParticipante = true;
    salvando = false;
    isNotParticipante: boolean;
    email: string;
    telefone: string;
    nome: string;
    cpfmask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    maxLength: number = 4000;

    constructor(
        private readonly atendenteStore: AtendenteStore,
        public dialog: MatDialog,
        private readonly router: Router,
        private assuntoService: AssuntoService,
        private chamadoStore: ChamadoStore,
        private canalService: CanalService,
        private chamadoService: ChamadoService,
        private pessoaService: PessoaService,
        private atendenteCanalService: AtendenteCanalService,
        private situacaoService: SituacaoChamadoService,
        private areaService: AreaService,
        private snackBar: MatSnackBar,
        private util: Util
    ) {
        this.planos = [];
    }

    ngOnInit() {
        EventEmitterService.get('atualizaChamadosPorCanal').emit();

        this.desabilitaCheck = true;
        this.atendenteStore.getCurrent().subscribe(atendente => {
            this.atendente = atendente;
            this.selectedArea = atendente.area.id;
            this.areaChange(this.selectedArea);
            this.atendenteCanalService.getAllByAtendenteId(atendente.id).subscribe(atendenteCanais => {
                this.atendenteCanais = atendenteCanais
                    .filter(x => x.atendente.id === atendente.id)
                    .map(c => {
                        return {
                            id: c.canal.id,
                            atendente: c.atendente,
                            canal: c.canal
                        };
                    });
            });
        });

        this.loadCanal();
        this.loadAreas();
    }

    loadCanal() {
        this.canalService.getAll().subscribe(canais => {
            this.canais = canais;
            // Fixando o canal em Telefone
            this.selectedCanal = 1;
        });
    }

    loadAreas() {
        this.areaService.getAll().subscribe(areas => {
            this.areas = areas;
        });
    }


    areaChange(event) {
        if (event) {
            this.assuntoService.getByArea(event).subscribe(assuntos => {
                this.assuntos = assuntos.sort((a, b) => a.nome.localeCompare(b.nome));
                this.desabilitaAssunto = true;
            });
        }
    }

    clickDisplay() {
        this.hiddenParticipante = !this.hiddenParticipante;
    }

    loadParticipante(cpf: string) {
        const tempCPF = this.limpaCPF(cpf);
        this.pessoaService.getListaPessoaByCPF(tempCPF).subscribe(
            listaPessoa => {
                this.pessoa = new Pessoa();
                this.participante = new Pessoa();
                this.beneficiario = new Pessoa();
                this.planos = [];
                this.selectedPlano = null;

                if (listaPessoa.length == 0) {
                    this.hiddenParticipante = true;
                    this.isNotParticipante = true;
                    this.nome = "";
                    this.email = "";
                    this.telefone = "";
                    this.showSnackBar('O CPF informado não é de um participante válido cadastrado na base de dados.');
                } else {
                    this.hiddenParticipante = false;

                    if (listaPessoa.find(p => p.isParticipante === 'S')) {
                        console.log('participante', listaPessoa.find(p => p.isParticipante === 'S'));
                        this.pessoa = listaPessoa.find(p => p.isParticipante === 'S')
                        this.isNotParticipante = false;
                        this.mostraAvisoBeneficiario = false;
                        this.participante = this.pessoa;
                        listaPessoa.forEach(pessoa => {
                            const idPessoa = pessoa.idDecujo > 0 ? pessoa.idDecujo : pessoa.id;
                            this.getPlanosBeneficios(idPessoa);
                        });                    }
                    
                    if (listaPessoa.find(p => p.isBeneficiario === 'S')) {
                        console.log('beneficiario', listaPessoa.find(p => p.isParticipante === 'S'));
                        this.pessoa = listaPessoa.find(p => p.isBeneficiario === 'S')
                        this.isNotParticipante = false;
                        this.beneficiario = this.pessoa;
                        this.participante = this.pessoa;
                        listaPessoa.forEach(pessoa => {
                            const idPessoa = pessoa.idDecujo > 0 ? pessoa.idDecujo : pessoa.id;
                            this.getPlanosBeneficios(idPessoa);
                        });
                        this.mostraAvisoBeneficiario = true;
                    }

                    if (Object.keys(this.pessoa).length === 0) {
                        if (listaPessoa.find(p => p.isBeneficiario === 'N' && p.isParticipante === 'N')) {
                            this.pessoa = listaPessoa.find(p => p.isBeneficiario === 'N' && p.isParticipante === 'N');
                            this.mostraAvisoBeneficiario = false;
                            this.participante = this.pessoa;
                            this.isNotParticipante = true;
                            this.nome = this.pessoa.nome;
                            this.email = this.pessoa.email;
                            this.telefone = this.pessoa.telefoneResidencial;
                        }
                    }

                    if (Object.keys(this.pessoa).length === 0) {
                        throwError('Not Found');
                    }
                }
            },
            err => {
                if (err === 'Not Found') {
                    this.participante = this.emptyParticipante;
                    this.hiddenParticipante = true;
                    this.showSnackBar('O CPF informado não é de um participante válido cadastrado na base de dados.');
                }
            }
        );
    }

    getPlanosBeneficios(idPessoa: number) {
        this.pessoaService.getPlanos(idPessoa).subscribe(planos => {
            planos.forEach(plano => {
                this.pessoaService.getBeneficio(idPessoa).subscribe(beneficios => {
                    console.log('beneficios', beneficios);

                    if (beneficios) {
                        beneficios.forEach(beneficio => {
                            if (beneficio.plano.planoId === plano.planoId) {
                                const benef = beneficios.find(x => x.plano.planoId === plano.planoId).tipoBeneficio.nome;
                                console.log(benef);
                                plano.nomePlano = plano.nomePlano + ' / ' + benef;
                            }
                        });
                    }
                    this.planos.push(plano);
                });
            });
            console.log('planos', planos);
        });
        if (this.planos.length > 0) {
            this.selectedPlano = this.planos[0].planoId;
        }
    }

    criarPessoaChamado() {
        const tempCPF = this.limpaCPF(this.informedCPF);

        this.participante = new Pessoa();
        this.participante.rg = 'N'
        this.participante.cpf = tempCPF
        this.participante.nome = this.nome
        this.participante.bairro = 'N'
        this.participante.cidade = 'N'
        this.participante.uf = 'N'
        this.participante.email = this.email
        this.participante.telefoneResidencial = this.telefone
        this.participante.telefoneCelular = 'N'
        this.participante.sexo = 'N'
        this.participante.endereco = 'N'
        this.participante.numero = 'N'
        this.participante.complemento = 'N'
        this.participante.cep = 'N'
        // this.participante.nascimento = this.dataNascimento
        this.participante.idEstadoCivil = "09";
        this.participante.idNacionalidade = 0;
        this.participante.icCnpjCpf = "F";
        this.participante.isExposta = "N";
        this.participante.isAtivo = "S";

        this.pessoaService.criar(this.participante).subscribe(participante => {

            this.chamado.assunto = this.assuntos.find(a => a.id === this.selectedAssunto);
            this.chamado.cpf = participante.cpf;
            this.chamado.nomeParticipante = participante.nome;
            this.chamado.planoId = this.selectedPlano > 0 ? this.selectedPlano : 0;
            this.chamado.atendente = this.atendente;
            this.chamado.beneficiario = this.chamadoBeneficiario ? 'S' : 'N';
            this.chamado.canal = this.canais.find(a => a.id === this.selectedCanal);
            this.chamadoDTO.chamado = this.chamado;
            if (this.concluirChamado) {
                this.chamadoDTO.concluirChamado = 1;
            } else {
                this.chamadoDTO.concluirChamado = 0;
            }

            this.chamadoService.salvarChamado(this.chamadoDTO).subscribe(chamado => {
                let message: string;

                if (this.concluirChamado) {
                    message = 'Chamado ' + chamado.id + ' concluído com sucesso.';
                } else {
                    message = 'Chamado ' + chamado.id + ' criado com sucesso.';
                }

                if (chamado.situacao.nome === 'Em Atendimento') {
                    this.chamadoStore.update(chamado);
                    this.showMessage(message, true);
                } else {
                    this.showMessage(message);
                }
                this.salvando = false;
            },
                error => {
                    this.showMessage(error);
                    this.salvando = false;
                });

        })
    }
    save() {
        this.salvando = true;
        this.snackBar.dismiss();
        if (this.isNotParticipante) {
            this.validaChamado(resultado => {
                if (resultado) {
                    const tempCPF = this.limpaCPF(this.informedCPF);
                    this.pessoaService.getListaPessoaByCPF(tempCPF).subscribe(listaPessoa => {
                        if (this.util.IsNullOrUndefined(listaPessoa) || listaPessoa.length <= 0) {
                            return this.criarPessoaChamado();
                        }

                        this.chamado.assunto = this.assuntos.find(a => a.id === this.selectedAssunto);
                        this.chamado.cpf = listaPessoa[0].cpf;
                        this.chamado.nomeParticipante = listaPessoa[0].nome;
                        this.chamado.planoId = this.selectedPlano > 0 ? this.selectedPlano : 0;
                        this.chamado.atendente = this.atendente;
                        this.chamado.beneficiario = this.chamadoBeneficiario ? 'S' : 'N';
                        this.chamado.canal = this.canais.find(a => a.id === this.selectedCanal);
                        this.chamadoDTO.chamado = this.chamado;
                        if (this.concluirChamado) {
                            this.chamadoDTO.concluirChamado = 1;
                        } else {
                            this.chamadoDTO.concluirChamado = 0;
                        }

                        this.chamadoService.salvarChamado(this.chamadoDTO).subscribe(chamado => {
                            let message: string;

                            if (this.concluirChamado) {
                                message = 'Chamado ' + chamado.id + ' concluído com sucesso.';
                            } else {
                                message = 'Chamado ' + chamado.id + ' criado com sucesso.';
                            }

                            if (chamado.situacao.nome === 'Em Atendimento') {
                                this.chamadoStore.update(chamado);
                                this.showMessage(message, true);
                            } else {
                                this.showMessage(message);
                            }
                            this.salvando = false;
                        },
                            error => {
                                this.showMessage(error);
                                this.salvando = false;
                            });

                    }, error => this.criarPessoaChamado());
                } else {
                    this.salvando = false;
                }
            });
        } else {
            this.validaChamado(resultado => {
                if (resultado) {


                    this.chamado.assunto = this.assuntos.find(a => a.id === this.selectedAssunto);
                    this.chamado.cpf = this.participante.cpf;
                    this.chamado.nomeParticipante = this.participante.nome;
                    this.chamado.planoId = this.selectedPlano;
                    this.chamado.atendente = this.atendente;
                    this.chamado.beneficiario = this.chamadoBeneficiario ? 'S' : 'N';
                    this.chamado.canal = this.canais.find(a => a.id === this.selectedCanal);
                    this.chamadoDTO.chamado = this.chamado;
                    if (this.concluirChamado) {
                        this.chamadoDTO.concluirChamado = 1;
                    } else {
                        this.chamadoDTO.concluirChamado = 0;
                    }

                    this.chamadoService.salvarChamado(this.chamadoDTO).subscribe(chamado => {
                        let message: string;

                        if (this.concluirChamado) {
                            message = 'Chamado ' + chamado.id + ' concluído com sucesso.';
                        } else {
                            message = 'Chamado ' + chamado.id + ' criado com sucesso.';
                        }

                        if (chamado.situacao.nome === 'Em Atendimento') {
                            this.chamadoStore.update(chamado);
                            this.showMessage(message, true);
                        } else {
                            this.showMessage(message);
                        }
                        this.salvando = false;
                    },
                        error => {
                            this.showMessage(error);
                            this.salvando = false;
                        });
                } else {
                    this.salvando = false;
                }
            });
        }
    }

    validaChamado(callback) {
        if (this.isNotParticipante) {
            if (this.util.IsNullOrUndefined(this.informedCPF)) {
                this.showSnackBar('O CPF do participante é requerido.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.nome)) {
                this.showSnackBar('O nome da pessoa é requerida.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.email)) {
                this.showSnackBar('O email da pessoa é requerida.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.telefone)) {
                this.showSnackBar('O telefone da pessoa é requerida.');
                callback(false);
            } else {
                callback(true);
            }
        } else {
            if (this.util.IsNullOrUndefined(this.participante.cpf)) {
                this.showSnackBar('Um Participante válido é requerido.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.selectedPlano)) {
                this.showSnackBar('O plano do participante é requerido.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.selectedAssunto)) {
                this.showSnackBar('O assunto do chamado é requerido.');
                callback(false);
            } else if (this.util.IsNullOrUndefined(this.chamado.descricao) || this.chamado.descricao === '') {
                this.showSnackBar('A descrição do chamado é requerida.');
                callback(false);
                // } else if (this.informedCPF !== this.participante.cpf) {
                //     this.showSnackBar('O CPF informado não é do participante listado.');
                //     callback(false);
            } else {
                this.verificaParticipante(this.participante.cpf, callback);
            }
        }
    }

    verificaParticipante(cpf: string, callback) {
        this.pessoaService.getByCPF(this.limpaCPF(cpf)).subscribe(pessoa => {
            let retorno = false;
            if (this.util.IsNullOrUndefined(pessoa)) {
                this.showSnackBar('O CPF informado não é de um participante cadastrado na base de dados.');
                retorno = false;
            } else {
                this.participante = pessoa;
                retorno = true;
            }
            callback(retorno);
        });
    }

    limpaCPF(cpf: string): string {
        let retorno = cpf;
        if (cpf) {
            retorno = cpf.replace(/[\.-]/g, '');
        }
        return retorno;
    }

    showSnackBar(message: string): void {
        this.snackBar.open(message, 'Close');
    }

    showMessage(message: string, atendDetalhe?: boolean): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (atendDetalhe) {
                this.router.navigate(['chamado-detalhe-app']);
            } else {
                this.back();
            }
        });
    }

    habilitaCheckCanalChange(event) {

        const canalId = event.value;

        if (!this.util.IsNullOrUndefined(this.atendente)) {

            if (this.atendente.area.primeiroNivel === 'S') {
                this.desabilitaCheck = false;
            }
            else {
                const assunto = this.assuntos.find(a => a.id === this.selectedAssunto);

                const atendenteCanais = this.atendenteCanais.find(a => a.canal.id === canalId && a.atendente.id === this.atendente.id);

                if ((this.atendente.area.id === assunto.area.id) && !this.util.IsNullOrUndefined((atendenteCanais))) {
                    this.desabilitaCheck = false;
                } else {
                    this.desabilitaCheck = true;
                }
            }
        }
    }

    habilitaCheckAssuntoChange(event) {

        const assuntoId = event.value;

        if (!this.util.IsNullOrUndefined(this.atendente)) {
            if (this.atendente.area.primeiroNivel === 'S') {
                this.desabilitaCheck = false;
            }
            else {
                const assunto = this.assuntos.find(a => a.id === assuntoId);
                const atendenteCanais = this.atendenteCanais.find(a => a.canal.id === this.selectedCanal && a.atendente.id === this.atendente.id);

                if ((this.atendente.area.id === assunto.area.id) && !this.util.IsNullOrUndefined((atendenteCanais))) {
                    this.desabilitaCheck = false;
                } else {
                    this.desabilitaCheck = true;
                }
            }
        }
    }
    back(): void {
        this.router.navigate(['project']);
    }

}
