import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatPaginatorModule, MatDialogModule, MatCardModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseWidgetComponent } from '@fuse/components/widget/widget.component';
import { FuseWidgetModule } from '@fuse/components';
import { FilterComponent } from '../filter/filter.component';
import { CanalAppComponent } from '../canalApp/canal-app.component';
import { OutPutAppComponent } from './output-app.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'output-app',
        component: OutPutAppComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        OutPutAppComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxDatatableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatDialogModule,
        MatPaginatorModule,
        MatCardModule,
        MatTableModule,
        FuseWidgetModule,
        TranslateModule,
        FuseSharedModule
    ],
    exports: [
        OutPutAppComponent
    ]
})

export class OutPutAppModule {
}
