import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AssuntoService } from '../shared/services/assunto.service';
import { DialogComponent } from '../components/message/dialog.component';
import { Assunto } from '../shared/models/assunto.model';
import { AssuntoStore } from '../shared/store/assunto.store';

@Component({
  selector: 'app-cadastro-assunto',
  templateUrl: './cadastro-assunto.component.html',
  styleUrls: ['./cadastro-assunto.component.scss']
})
export class CadastroAssuntoComponent implements OnInit {

  assuntoFilter: any = [];
  assuntos: Assunto[];
  filteredAssuntos: Assunto[];
  orderDirection: boolean = true;
  sortField: string;

  displayedColumns: string[] = ['id', 'nome', 'area', 'acoes'];

  constructor(
              private readonly assuntoService: AssuntoService,
              private readonly dialog: MatDialog,
              private readonly router: Router,
              private readonly assuntoStore: AssuntoStore
  ) { }

  ngOnInit() {
      this.loadAssuntos();
  }

  loadAssuntos(){
    this.assuntoService.getAll().subscribe(assuntos => {
        this.filteredAssuntos = assuntos.sort((a, b) => a.nome.localeCompare(b.nome));
        this.assuntos = assuntos.sort((a, b) => a.nome.localeCompare(b.nome));
        this.assuntoStore.update(null);
    });
}

newAssunto(): void {
    this.assuntoStore.update(null);
    this.router.navigate(['new-assunto']);
}

editAssunto(assuntoId: number) {
    const assunto = this.assuntos.find(a => a.id === assuntoId);
    this.assuntoStore.update(assunto);        
    this.router.navigate(['new-assunto']);
}

deleteAssunto(assuntoId) {
    this.assuntoService.deleteById(assuntoId).subscribe(
        () => {
            this.showMessage('Assunto removido.');
        },
        (err) => {
            this.showMessage(err);
        });
}

back(): void {
    this.router.navigate(['project']);
}

showMessage(message: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
        width: '350px',
        data: { message: message }
    });

    dialogRef.afterClosed().subscribe(() => {
        this.loadAssuntos();
    });
}

}
