import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { VersaoApp } from '../models/versaoApp.model';

@Injectable()
export class VersaoAppStore {

    private Subject = new BehaviorSubject<VersaoApp>(null);
    Versao = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<VersaoApp> {
        return this.Versao;
    }

    update(versao: VersaoApp) {
        this.Subject.next(versao);
    }
}