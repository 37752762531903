import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { Inject, Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule, MatTooltipModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CadastroAreaComponent } from './cadastro-area.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'cadastro-area',
        component: CadastroAreaComponent,
        canActivate: [AuthGuard]
    }
    
];

@NgModule({
    declarations: [
        CadastroAreaComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatTooltipModule
    ],
    exports: [
        CadastroAreaComponent
    ]

})
export class CadastroAreaModule { }
