import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Area } from '../models/area.model';

@Injectable()
export class AreaStore {

    private Subject = new BehaviorSubject<Area>(null);
    area = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Area> {
        return this.area;
    }

    update(area: Area) {
        this.Subject.next(area);
    }
}