import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Chamado } from '../models/chamado.model';
import { FiltrarChamadoResponse } from '../models/filtroChamado.model';

@Injectable()
export class ListaChamadoStore {

    private subject = new BehaviorSubject<FiltrarChamadoResponse>(null);
    filtrarChamadoResponse = this.subject.asObservable();

    constructor() { }

    getCurrent(): Observable<FiltrarChamadoResponse> {
        return this.filtrarChamadoResponse;
    }

    update (filtrarChamadoResponse: FiltrarChamadoResponse) {
        this.subject.next(filtrarChamadoResponse);
    }
}