import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Util } from '../util';
import { Integracao } from '../models/integracao';
import { head } from 'lodash';
// import { version } from 'punycode';

@Injectable()
export class IntegracaoService extends ApiService implements BaseService<Integracao> {

    private strAction = 'Integracao';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router,
        private httpExterno: HttpClient
        ) {
        super(httpClient, route);
    }

    getAll(): Observable<Integracao[]> {
        return this.get<Integracao[]>(this.strAction + `/GetAll`);
    }

    getById(integracaoId: number): Observable<Integracao> {
        return this.get<Integracao>(this.strAction + `/GetById/${integracaoId}`);
    }

    deleteById(integracaoId: number) {
        return this.post(this.strAction + `/DeleteIntegracao?id=${integracaoId}`);
    }

    save(integracao: Integracao): Observable<Integracao> {
        if (this.util.IsNullOrUndefined(integracao.id) || integracao.id === 0){
            return this.post<Integracao>(this.strAction + `/Post`, integracao);        
        }        
        return this.post<Integracao>(this.strAction + `/Put`, integracao);        
    }

    // Manter essa chamada para futuras integrações
    chamaIntegracao(integracao: Integracao, body: any) {

        let headers = {
            'Access-Control-Allow-Origin': integracao.url
        };

        let getString = '';

        if (integracao.metodo === 'GET') {
            let httpParams = new HttpParams({ fromObject: body });
            getString = (integracao.url.slice(-1) == '/' ? integracao.url.slice(0, -1) : integracao.url) + `?` + httpParams.toString();            
            this.httpExterno.get(getString, { headers });
        }
        else if (integracao.metodo === 'POST') {
            getString = integracao.url.slice(-1) == '/' ? integracao.url.slice(0, -1) : integracao.url;          
            this.httpExterno.post(getString, body, { headers });
        }
    }
}
