import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector: 'calendar-app',
    templateUrl: './calendar-app.component.html',
    styleUrls: ['./calendar-app.component.scss']
   })
   export class CalendarAppComponent {

    constructor(public dialog: MatDialog, private router:Router ) {
     
      
    }


    backToProject(): void {
        this.router.navigate(["project-list"]);
    }

    viewList():void {
      this.router.navigate(["canal-app"]);
    }

 
}