import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'upload-app',
    templateUrl: './upload-app.component.html',
    styleUrls: ['./upload-app.component.scss']
   })
   export class UploadAppComponent {

    constructor(private dialogRef: MatDialogRef<UploadAppComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    showMessage(message: string): void {
    
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}