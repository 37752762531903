export class TipoFolha {
    
    beneficioId: number;
    id: number;
    nome: string;

    public TipoFolha() {
        this.beneficioId = -1;
        this.id = -1;
        this.nome = '';
    }
    
}
