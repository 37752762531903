import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable()
export class SnackBarService {
    constructor(private _snackBar: MatSnackBar) { }

    open(mensagem: string, action: string) {
        this._snackBar.open(mensagem, action, {
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ['mensagemEmAtendimento']

        });
    }
}