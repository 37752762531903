import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';

import { DialogComponent } from '../components/message/dialog.component';

import { Canal } from '../shared/models/canal.model';
import { CanalService } from '../shared/services/canal.service';
import { CanalStore } from '../shared/store/canal.store';


@Component({
    selector: 'cadastro-canal',
    templateUrl: './cadastro-canal.component.html',
    styleUrls: ['./cadastro-canal.component.scss'],
    
})
export class CadastroCanalComponent {
    canalFilter: any = [];
    canais: Canal[];
    orderDirection: boolean = true;
    sortField: string;
    filteredCanais: Canal[];

    displayedColumns: string[] = ['id', 'nome', 'prazo', 'acoes'];

    constructor(
        private readonly canalService: CanalService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly canalStore: CanalStore ) {
        this.loadCanais();
    }

    loadCanais(){
        this.canalService.getAll().subscribe(canais => {
            this.filteredCanais = canais;
            this.canais = canais;
        });
    }
    
    newCanal(): void {
        this.canalStore.update(null);
        this.router.navigate(['new-canal']);
    }

    editCanal(canalId: number) {
        const canal = this.canais.find(a => a.id === canalId);
        this.canalStore.update(canal);        
        this.router.navigate(['new-canal']);
    }

    deleteCanal(canalId: number) {
        this.canalService.deleteById(canalId).subscribe(
            () => {
                this.showMessage('Canal removido.');
            },
            (err) => {
                this.showMessage(err);
            }
        );
    }

    back(): void {
        this.router.navigate(['project']);
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });
    
        dialogRef.afterClosed().subscribe(() => {
            this.loadCanais();
        });
    }

}
