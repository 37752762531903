import { Assunto } from './../../shared/models/assunto.model';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Util } from '../../shared/util';
import { DialogComponent } from '../../components/message/dialog.component';
import { AssuntoService } from '../../shared/services/assunto.service';
import { AssuntoStore } from '../../shared/store/assunto.store';
import { Area } from '../../shared/models/area.model';
import { AreaService } from '../../shared/services/area.service';

@Component({
  selector: 'app-new-assunto',
  templateUrl: './new-assunto.component.html',
  styleUrls: ['./new-assunto.component.scss']
})
export class NewAssuntoComponent implements OnInit {

  formErrors: any;
  id: number;
  assunto: Assunto = new Assunto();
  @Input()
  form: FormGroup;
  areas: Area[];
  ShowFilter = false;
  selectedItems = [];
  selectedAreas: Area[] = [];
  dropdownSettings: any = {};
  selectedArea: number;

  constructor(
      // private fuseTranslationLoader: FuseTranslationLoaderService,
      private formBuilder: FormBuilder,
      public dialog: MatDialog,
      private readonly router: Router,
      private readonly assuntoService: AssuntoService,
      private readonly assuntoStore: AssuntoStore,
      private snackBar: MatSnackBar,
      private areaService: AreaService,
      private util: Util) {

      this.assuntoStore.getCurrent().subscribe(assunto => {
          if (assunto) {
              this.assunto = assunto;
              this.selectedArea = this.assunto.area.id;
            //   console.log(assunto);
          }
          else {
              this.assunto = new Assunto();
          }

          this.loadAreas();

          this.formErrors = {
              Nome: {}
          };
      });
  }

  ngOnInit() {
      this.form = this.formBuilder.group({
          Nome: ['', Validators.required],
          Area: [this.selectedItems, Validators.required]
      });
      
      this.form.valueChanges.subscribe(() => {
          this.onFormValuesChanged();
      });
  }

  onFormValuesChanged() {
      for (const field in this.formErrors) {
          if (!this.formErrors.hasOwnProperty(field)) {
              continue;
          }
          this.formErrors[field] = {};

          const control = this.form.get(field);

          if (control && control.dirty && !control.valid) {
              this.formErrors[field] = control.errors;
          }
      }
  }

  loadAreas(){
      this.areaService.getAll().subscribe(areas => {
          this.areas = areas;
      });
  }

  save() {
    // console.log('salvando');
    let updateAssuntoId = 0;
    if (!this.util.IsNullOrUndefined(this.assunto)){
        updateAssuntoId = this.assunto.id;
    }
    this.validaAssunto(resultado => {
        if (resultado){
            this.assunto.area = this.areas.find(a => a.id === this.selectedArea);
            this.assuntoService.save(this.assunto).subscribe(assunto => {
                const message = this.assunto.id > 0 ? 'Assunto atualizado' : 'Assunto criado';
                this.snackBar.dismiss();
                this.showMessage(message);
            });
        } else {
            // console.log('Não salvou!!');
        }
    });
    
  }

  validaAssunto(callback) {
      if (this.util.IsNullOrUndefined(this.assunto.nome)) {
          this.showSnackBar('O nome do assunto é requerido.');
          callback(false);
      } else if (this.util.IsNullOrUndefined(this.selectedArea)) {
        this.showSnackBar('O assunto deve ser associado a uma área.');
        callback(false);
      } else {
          callback(true);
      }
  }

  showSnackBar(message: string): void {
      this.snackBar.open(message, 'Close');
  }

  showMessage(message: string): void {
      const dialogRef = this.dialog.open(DialogComponent, {
          width: '350px',
          data: { message: message }
      });

      dialogRef.afterClosed().subscribe(result => {
          this.back();
      });
  }    

  back(): void {
      this.router.navigate(['/cadastro-assunto']);
  }

}
