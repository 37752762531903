
import { Situacao } from "./situacao.model";


export class SituacaoDTO {
    
    id: number;
    nome: string;
    email: number;
    situacoes: Situacao[];

}
