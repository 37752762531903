import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioDetalheComponent } from './usuario-detalhe.component';
import { MatSelectModule, MatIconModule, MatButtonModule } from '@angular/material';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes = [
  {
      path: 'app-usuario-detalhe',
      component: UsuarioDetalheComponent,
      canActivate: [AuthGuard]
  }
  
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [UsuarioDetalheComponent]
})
export class UsuarioDetalheModule { }
