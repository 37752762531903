import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';
import { BaseService } from '../../../../core/base.service';
import { Area } from '../models/area.model';
import { Util } from '../util';
// import { version } from 'punycode';

@Injectable()
export class AreaService extends ApiService implements BaseService<Area> {

    private strAction = 'Area';

    constructor(
        httpClient: HttpClient,
        private util: Util,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Area[]> {
        return this.get<Area[]>(this.strAction + `/GetAll`);
    }

    getById(areaId: number): Observable<Area> {
        return this.get<Area>(this.strAction + `/GetById/${areaId}`);
    }

    deleteById(areaId: number) {
        return this.post(this.strAction + `/DeleteArea?id=${areaId}`);
        // return this.delete(this.strAction + `?id=${areaId}`, { headers: headers });
    }

    deleteArea(area: Area) {
        // return this.delete(this.strAction + `/Delete`, JSON.stringify(area), { headers: headers });
        return this.post(this.strAction + `/Delete`, area);
    }

    save(area: Area): Observable<Area> {
        if (this.util.IsNullOrUndefined(area.id) || area.id === 0){
            return this.post<Area>(this.strAction + `/Post`, area);        
        }        
        return this.post<Area>(this.strAction + `/Put`, area);        
    }
}
