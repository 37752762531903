import { SharedModule } from './../shared/shared.module';

import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatPaginatorModule, MatDialogModule, MatCardModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProjectListComponent } from './project-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseWidgetComponent } from '@fuse/components/widget/widget.component';
import { FuseWidgetModule } from '@fuse/components';
import { FilterComponent } from '../filter/filter.component';
import { CanalAppComponent } from '../canalApp/canal-app.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'project',
        component: ProjectListComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProjectListComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgxDatatableModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatDialogModule,
        MatPaginatorModule,
        MatCardModule,
        MatTableModule,
        FuseWidgetModule,
        TranslateModule,
        FuseSharedModule,
        Ng2GoogleChartsModule,
        SharedModule
    ],
    exports: [

    ]
})

export class ProjectModule {
}
