import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';
import { MatDialogModule } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog.component' ;
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        BrowserModule
    ],
   
     entryComponents: [
        ConfirmDialogComponent
     ],
})
export class ConfirmDialogModule
{
}
