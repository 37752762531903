import { Assunto } from './assunto.model';
import { Canal } from './canal.model';
import { Atendente } from './atendente.model';
import { Situacao } from './situacao.model';
import { Area } from './area.model';

export class Chamado {
    
    id: number;
    assunto: Assunto;
    canal: Canal;
    cpf: string;
    nomeParticipante: string;
    planoId: number;
    atendente: Atendente;
    descricao: string;
    dataAbertura: Date;
    dataPrevistaConclusao: Date;
    dataEncerramento: Date;
    situacao: Situacao;
    area: Area;
    status : string;
    beneficiario: string;
}
