import { Empresa } from "./empresa.model";
import { Plano } from "./plano.model";
import { TipoBeneficio } from "./tipoBeneficio.model";
import { Pessoa } from "./pessoa.model";
import { TipoRenda } from "./tipoRenda.model";

export class Beneficio {

    beneficioId: number;
    empresa: Empresa;
    plano: Plano;
    tipoBeneficio: TipoBeneficio;
    pessoa: Pessoa;
    dataDib: Date;
    dataPrimeiroPagamento: Date;
    dataConcessao: Date;
    dataFinal: Date;
    beneficioAnteriorId: number;
    numeroProcessoInss: number;
    sitBeneficioAnteriorId: number;
    sitBeneficioId: number;
    motivoSitBeneficioId: number;
    tipoRenda: TipoRenda;
    saqueProgramado: number;
    valorFatorAtuarial: number;
    quantidadeParcela: number;
    quantidadeParcelaPaga: number;
    saqueVista: number;
    valorSaqueVista: number;
    quantidadeCotaSaqueVista: number;
    valorInss: number;
    valorBeneficio: number;
    quantidadeCotaBeneficio: number;
    valorSrb: number;
    quantidadeSalarioSrb: number;
    totalSalarioSrb: number;
    tempoIdadeAno: number;
    tempoIdadeMes: number;
    tempoIdadeDia: number;
    tempoEfpcAno: number;
    tempoEfpcMes: number;
    tempoEfpcDia: number;
    tempoEmpresaAno: number;
    tempoEmpresaMes: number;
    tempoEmpresaDia: number;
    tempoInssAno: number;
    tempoInssMes: number;
    tempoInssDia: number;
    tempoPlanoAno: number;
    tempoPlanoMes: number;
    tempoPlanoDia: number;
    calculado: string;
    formaPagamento: string;
    valorBeneficioAtualCalculado: number;
    beneficioInss: string;
    acidenteTrabalho: string;
    dataAbandonoFinal: Date;
    valorFatorRedutor: number;
    tempoServicoContribuicaoAno: number;
    tempoServicoContribuicaoMes: number;
    tempoServicoContribuicaoDia: number;
    informacaoAdicional: string;
    trasferenciaBeneficiario: string;
    tempoContribuicaoAno: number;
    tempoContribuicaoMes: number;
    tempoContribuicaoDia: number;
    valorBeneficioFixo: number;
    tempoProjetoAno: number;
    tempoProjetoMes: number;
    tempoProjetoDia: number;
    opcaoReceber: string;


    public Beneficio() {
        this.tipoBeneficio = new TipoBeneficio();
        this.empresa = new Empresa();
        this.plano = new Plano();
        this.tipoRenda = new TipoRenda();
        this.pessoa = new Pessoa();
    }
}
