import { LoginService } from './../main/content/shared/services/login.service';
import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AtendenteStore } from 'app/main/content/shared/store/atendente.store';
import { AtendenteService } from 'app/main/content/shared/services/atendente.service';
import { LoginStore } from 'app/main/content/shared/store/login.store';
import { VersaoStore } from 'app/main/content/shared/store/versao.store';
import { VersaoService } from 'app/main/content/shared/services/versao.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'app/main/content/components/message/dialog.component';
import { VersaoAppStore } from 'app/main/content/shared/store/versaoApp.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  numeroVersao: string;
  numeroVersaoApp: string;
  hide = true;
  loginForm: FormGroup;
  submitted = false;
  @Output() exibeApp = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private readonly atendenteStore: AtendenteStore,
    private readonly loginStore: LoginStore,
    private readonly versaoAppStore: VersaoAppStore,
    private readonly atendenteService: AtendenteService,
    private login: LoginService,
    private versao: VersaoService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.login.logout();
    this.versao.getVersao().subscribe(listaVersao => {
      this.numeroVersao = listaVersao.numeroVersao;
    });

    this.versao.getVersaoJson().subscribe(listaVersaoApp => {
      this.numeroVersaoApp = listaVersaoApp[listaVersaoApp.length - 1].numero;
      this.versaoAppStore.update(listaVersaoApp[listaVersaoApp.length - 1]);
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    } else if (this.numeroVersaoApp !== this.numeroVersao) {
      this.showMessage('Versão da aplicação (' + this.numeroVersaoApp + ') não é compatível com a versão do banco ('
      + this.numeroVersao + ')');
    } else {
      this.login
        .autenticar(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
            this.login.getById(this.f.username.value).subscribe(login => {
              this.loginStore.update(login);
            });
            this.atendenteService
              .getByAtendenteLogin(this.f.username.value)
              .subscribe(atendente => {
                this.atendenteStore.update(atendente);
              });

            this.exibeApp.emit(true);
            this.router.navigate(['/project']);
          },
          error => {}
        );
    }
  }

  showMessage(message: string): void {
    const dialogRef = this.dialog.open(
      DialogComponent, {
        width: '350px',
        data: { message: message }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
}
}
