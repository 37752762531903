import { MatIconModule, MatTableModule, MatSelectModule, MatInputModule, MatButtonModule, MatDialogModule, MatPaginatorModule, MatFormFieldModule, MatExpansionModule, MatTooltipModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CadastroSituacaoComponent } from './cadastro-situacao.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
  {
      path: 'cadastro-situacao',
      component: CadastroSituacaoComponent,
      canActivate: [AuthGuard]
  }
  
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    BrowserModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  declarations: [CadastroSituacaoComponent],
  exports: [
    CadastroSituacaoComponent
  ]
})
export class CadastroSituacaoModule { }
