import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { Util } from '../../content/shared/util';
import { Chamado } from '../shared/models/chamado.model';
import { FiltroChamado } from '../../content/shared/models/filtroChamado.model';
import { ChamadoService } from '../shared/services/chamado.service';
import { ListaChamadoStore } from '../../content/shared/store/listaChamado.store';

import { Pessoa } from '../shared/models/pessoa.model';

import { Registro } from '../shared/models/registro.model';

import { RegistroService } from '../shared/services/registro.service';

import { ChamadoStore } from '../shared/store/chamado.store';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import { Situacao } from '../shared/models/situacao.model';
import { DialogComponent } from '../components/message/dialog.component';

@Component({
    selector: 'chamados-participante-app',
    templateUrl: './chamados-participante-app.component.html',
    styleUrls: ['./chamados-participante-app.component.scss'],
})

export class ChamadosParticipanteComponent implements OnInit {

    totalChamados: number = 0
    page: number = 0
    dataTeste: string = "10/10/1010";
    participante: Pessoa = new Pessoa();
    util: Util = new Util();
    filtroChamado = new FiltroChamado();
    chamados: Chamado[] = [];
    situacaoId: number;
    selectedChamado: Chamado = new Chamado();
    registros: Registro[] = [];
    situacoes: Situacao[] = [];
    loadingIndicator = false;

    displayedColumns: string[] = ['CHAMADO', 'SITUAÇÃO', 'DATA DE ABERTURA', 'DATA PREVISTA DE CONCLUSÃO', 'ASSUNTO', 'DESCRIÇÃO', 'CANAL'];
    //dataSource = ELEMENT_DATA;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private readonly RegistroService: RegistroService,
        private readonly ChamadoStore: ChamadoStore,
        private readonly listaChamadoStore: ListaChamadoStore,
        private readonly chamadoService: ChamadoService
    ) {
        this.listaChamadoStore.getCurrent().subscribe(listaChamados => {
            this.chamados = listaChamados.chamados;
        });
    }

    changePage(event: any) {
        this.page = event.page
          
        this.filtroChamado.tipoFiltro = "CPF";
        this.filtroChamado.descricao = this.selectedChamado.cpf;
        this.filtroChamado.currentPage = this.page - 1;
        this.filtroChamado.pageSize = 9;

        this.chamadoService.filtroChamado(this.filtroChamado).subscribe(listaChamados => {
            this.listaChamadoStore.update(listaChamados);
            this.chamados = listaChamados.chamados;
            this.totalChamados = listaChamados.total
        });

    }

    ngOnInit() {
        this.ChamadoStore.getCurrent().subscribe(chamado => {
            if (chamado) {
                this.selectedChamado = chamado;
                this.loadAllByChamado(chamado.id);

                this.filtroChamado.tipoFiltro = "CPF";
                this.filtroChamado.descricao = this.selectedChamado.cpf;
                this.filtroChamado.currentPage = 0;
                this.filtroChamado.pageSize = 9;

                this.chamadoService.filtroChamado(this.filtroChamado).subscribe(listaChamados => {
                    this.listaChamadoStore.update(listaChamados);
                    this.chamados = listaChamados.chamados;
                    this.totalChamados = listaChamados.total
                });
            }
            else {
                return null;

                // this.filtroChamado.tipoFiltro = "CPF";
                // this.filtroChamado.descricao = this.selectedChamado.cpf;
        
                // this.chamadoService.filtroChamado(this.filtroChamado).subscribe(listaChamados => {
                //     this.listaChamadoStore.update(listaChamados);
                //     this.chamados = listaChamados.chamados;
                //     console.table(this.chamados);
                //     console.table(this.listaChamadoStore);
                // });

            }
        });

  
    }

    loadAllByChamado(chamadoId: number) {
        this.loadingIndicator = true;
        this.RegistroService.getAllByChamado(chamadoId).subscribe(registros => {
            this.registros = _.orderBy(registros, ['situacao', 'id'], ['asc', 'asc']);
            this.loadingIndicator = false;
        });
    }

    verDetalhes(chamadoId: number): void {
        const chamado = this.chamados.find(c => c.id === chamadoId);
        this.ChamadoStore.update(chamado);
        this.router.navigate(['chamado-detalhe-app']);
    }

    showMessage(message: string): void {
        this.selectedChamado.descricao = message;
    }

    backToDetalhe(): void {
        this.router.navigate(['chamado-detalhe-app']);
    }
}