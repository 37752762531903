import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../../components/message/dialog.component';
// import { DatatableComponent } from '@swimlane/ngx-datatable';

import { Area } from '../../shared/models/area.model';
import { Atendente } from '../../shared/models/atendente.model';

import { AreaService } from '../../shared/services/area.service';
import { AtendenteService } from '../../shared/services/atendente.service';

import { AreaStore } from '../../shared/store/area.store';
import { Util } from '../../shared/util';

@Component({
    selector: 'new-area',
    templateUrl: './new-area.component.html',
    styleUrls: ['./new-area.component.scss']
})

export class NewAreaComponent implements OnInit {
    formErrors: any;
    id: number;
    area: Area = new Area();
    areaRetaguarda: boolean;
    areaprimeiroNivel: boolean;
    // responsaveis: Atendente[] = [];
    // responsavel = new Atendente();
    // responsavelArea: number;
    selectedItems = [];
    salvando: boolean;

    @Input()
    form: FormGroup;

    constructor(
        // private fuseTranslationLoader: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private readonly router: Router,
        private readonly areaService: AreaService,
        private readonly atendenteService: AtendenteService,
        private readonly areaStore: AreaStore,
        private snackBar: MatSnackBar,        
        private util: Util) {
            this.salvando = false;
        this.areaStore.getCurrent().subscribe(area => {
            if (area){
                this.area = area;
                // this.responsavelArea = area.responsavel.id;
                // console.log(area);

                this.areaRetaguarda = false;
                this.areaprimeiroNivel = false;

                if (area.primeiroNivel === 'S'){
                    this.areaprimeiroNivel = true;
                }
                if (area.retaguarda === 'S'){
                    this.areaRetaguarda = true;
                }
            }
            else {
                this.area = new Area();
                this.areaRetaguarda = false;
                this.areaprimeiroNivel = false;
            }
        
            // this.loadResponsaveis();
            
            this.formErrors = {
                Nome: {},
                Responsavel: {},
                Retaguarda: {},
            };
        });
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            Nome: ['', Validators.required],
            Responsavel: [this.selectedItems, Validators.required],
            Retaguarda: ['', Validators.required],
            PrimeiroNivel: ['']
        });
        
        this.form.valueChanges.subscribe(() => {
            this.onFormValuesChanged();
        });
    }

    // loadResponsaveis(){
    //     this.atendenteService.getAllByAtendenteArea().subscribe(responsaveis => {
    //         this.responsaveis = responsaveis;
            
    //         if (this.area) {
    //             this.atendenteService.getById(this.area.responsavel.id).subscribe(atendente => {
    //                 this.responsaveis.push(atendente);
    //             });
    //         }
    //     });
    // }

    onFormValuesChanged() {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            this.formErrors[field] = {};

            const control = this.form.get(field);

            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    save() {
        this.validaArea(resultado => {
            if (resultado){
                this.salvando = true;
                this.area.retaguarda = 'N';
                this.area.primeiroNivel = 'N';
                if (this.areaRetaguarda) {
                    this.area.retaguarda = 'S';
                }
                if (this.areaprimeiroNivel) {
                    this.area.primeiroNivel = 'S';
                }
                // this.area.responsavel = this.responsaveis.find(r => r.id === this.responsavelArea);
                this.areaService.save(this.area).subscribe(area => {
                    const message = this.area.id > 0 ? 'Área atualizada' : 'Área criada';
                    this.showMessage(message);
                    this.area = area;
                });
            }
        });
    }

    validaArea(callback) {
        if (this.util.IsNullOrUndefined(this.area.nome)) {
            this.showSnackBar('O nome da área é requerido.');
            callback(false);
        } 
        // else if (this.util.IsNullOrUndefined(this.responsavelArea)) {
        //     this.showSnackBar('A área tem que ter um atendente responsável.');
        //     callback(false);
        // } 
        else {
            callback(true);
        }
    }

    showSnackBar(message: string): void {
        this.snackBar.open(message, 'Close');
    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.back();
        });
    }    

    back(): void {
        this.router.navigate(['/cadastro-area']);
    }
    
}
