import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { RouterModule } from'@angular/router';
import { CalendarAppComponent } from './calendar-app.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'calendar-app',
        component: CalendarAppComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
   declarations: [
      CalendarAppComponent
   ],
   imports: [
      RouterModule.forChild(routes),
      BrowserModule,
      BrowserAnimationsModule,
      MatIconModule,
      MatInputModule,
      MatButtonModule,
      MatDialogModule
   ]
})
export class CalendarAppModule { }