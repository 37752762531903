import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { NgModule } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from'@angular/router';
import { PermissionAppComponent } from './permission-app.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes = [
    {
        path: 'permission-app',
        component: PermissionAppComponent,
        canActivate: [AuthGuard]
    }
    
];

@NgModule({
    declarations: [
        PermissionAppComponent,
        
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        BrowserAnimationsModule,
    
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatDialogModule,
        MatPaginatorModule,
        MatFormFieldModule
    ],
    exports: [
        PermissionAppComponent
    ]

})
export class PermissionAppModule { }