import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TempoServicoComponent } from './tempo-servico.component';
import { MatDialogModule, MatButtonModule, MatTableModule, MatIconModule, MatFormFieldModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        BrowserModule,
        MatTableModule,
        MatIconModule,
  ],
  declarations: [TempoServicoComponent],
  entryComponents: [
    TempoServicoComponent
 ],
})
export class TempoServicoModule { }
