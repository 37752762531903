import { Util } from './../shared/util';
import { ResumoStore } from './../shared/store/resumo.store';
import { UltimosLancamentosComponent } from './ultimos-lancamentos/ultimos-lancamentos.component';
import { Router } from '@angular/router';
import { Inject, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
// import { MatTableModule } from '@angular/material/table';

import { DialogComponent } from '../components/message/dialog.component';

// import { ConfigAdminComponent } from '../configAdmin/config-admin.component';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { FormBuilder, FormGroup } from '@angular/forms';
// import { MatSelectModule, MatInputModule,  MatPaginatorModule, MatSortModule } from '@angular/material';

import { Chamado } from '../shared/models/chamado.model';
import { Assunto } from '../shared/models/assunto.model';
import { Pessoa } from '../shared/models/pessoa.model';
import { Plano } from '../shared/models/plano.model';
import { PerfilInvestimento } from '../shared/models/perfilInvestimento.model';
import { DadosResumo } from '../shared/models/dadosResumo.model';
import { Resumo } from '../shared/models/resumo.model';
import { Saldo } from '../shared/models/saldo.model';
import { Contribuicao } from '../shared/models/contribuicao.model';
import { Beneficio } from '../shared/models/beneficio.model';
import { TipoBeneficio } from '../shared/models/tipoBeneficio.model';
import { TipoFolha } from '../shared/models/tipoFolha.model';

import { ChamadoService } from '../shared/services/chamado.service';
import { PessoaService } from '../shared/services/pessoa.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';

import * as _ from 'lodash';
import { ChamadoStore } from '../shared/store/chamado.store';
import { TempoServicoComponent } from './tempo-servico/tempo-servico.component';
import { Emprestimo } from '../shared/models/emprestimo.model';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';
import { LoginStore } from '../shared/store/login.store';
import { Inscricao } from '../shared/models/inscricao.model';
import { timer } from 'rxjs';
import { SnackBarService } from 'app/main/content/shared/services/snackBar.service';
import { IntegracaoService } from '../shared/services/integracao.service';
import { Integracao } from '../shared/models/integracao';
import { Console } from 'console';
import { IntegracaoStore } from '../shared/store/integracao.store';

@Component({
    selector: 'chamado-detalhe-app',
    templateUrl: './chamado-detalhe-app.component.html',
    styleUrls: ['./chamado-detalhe-app.component.scss'],
})

export class ChamadoDetalheAppComponent implements OnInit {

    beneficios: Beneficio[] = []
    inscricoes: Inscricao[] = []
    inscricao: Inscricao = new Inscricao();
    chamado: Chamado = new Chamado();
    assunto: Assunto = new Assunto();
    participante: Pessoa = new Pessoa();
    participante2: Pessoa = new Pessoa();
    participantes: Pessoa[] = []
    listaPessoa: Pessoa[] = [];
    planos: Plano[] = [];
    plano: Plano = new Plano();
    dadosResumo: DadosResumo = new DadosResumo();
    resumos: Resumo[] = [];
    emprestimos: Emprestimo[] = [];
    resumo: Resumo = new Resumo();
    // contribuicoes: Contribuicao[] = [];
    contribuicao: Contribuicao = new Contribuicao();
    saldos: Saldo[] = [];
    listaBeneficio: Beneficio[] = [];
    saldoContribuicao: number = 0;
    beneficio: Beneficio = new Beneficio();
    tipoBeneficio: TipoBeneficio = new TipoBeneficio();
    tipoFolha: TipoFolha = new TipoFolha();
    valorSuplementacao: number = 0;
    valorBPA: number = 0;
    percentualRenda: number = 0;
    step = 0;
    dataFim = new Date();
    mostrarRegistroConcluido = true;
    chamadoStatus: ChamadoStatus = new ChamadoStatus();
    messageBox = false;
    usuarioLogado: any;
    displayedColumns: string[] = ['nrContrato', 'dtCredito', 'dtQuitacao', 'prazo', 'prestacao', 'vlSolicitado', 'vlDevedor', 'parcelasPagas'];
    integracao: Integracao = new Integracao();

    constructor(
        private location: Location,
        public dialog: MatDialog,
        private readonly router: Router,
        private readonly loginStore: LoginStore,
        private readonly chamadoStatusService: ChamadoStatusService,
        private readonly chamadoService: ChamadoService,
        private readonly situacaoChamadoService: SituacaoChamadoService,
        private readonly pessoaService: PessoaService,
        private readonly chamadoStore: ChamadoStore,
        private readonly resumoStore: ResumoStore,
        private readonly util: Util,
        private snackBarService: SnackBarService,
        private readonly integracaoService: IntegracaoService
    ) {
    }


    loadParticipante(cpf: string) {
        this.inscricoes = []


        this.pessoaService.getListaPessoaByCPF(cpf).subscribe(
            listaPessoa => {
                if (this.util.IsNullOrUndefined(listaPessoa)) {

                } else {
                    
                    listaPessoa.forEach(pessoa => {

                        const idPessoa = pessoa.idDecujo > 0 ? pessoa.idDecujo : pessoa.id;

                        this.pessoaService.getPlanos(idPessoa).subscribe(planos => {
                            planos.forEach(plano => {
                                this.pessoaService.getBeneficio(pessoa.id).subscribe(beneficios => {
                                    let inscricao = new Inscricao()
                                    inscricao.participante = pessoa
                                    inscricao.plano = plano
                                    inscricao.beneficios = []

                                    if (beneficios) {
                                        beneficios.forEach(beneficio => {
                                            if (beneficio.plano.planoId === plano.planoId) {
                                                inscricao.beneficios.push(beneficio)
                                                inscricao.plano.nomePlano = inscricao.plano.nomePlano + ' / ' + beneficio.tipoBeneficio.nome;
                                            }
                                        });
                                    }
                                    
                                    //console.log(inscricao.beneficios)
                                    this.inscricoes.push(inscricao);
                                });

                            });
                        });
                    });
                }
            }
        );
    }


    ngOnInit() {
        this.chamado.assunto = this.assunto;
        this.dadosResumo.resumos = this.resumos;
        this.loginStore.getCurrent().subscribe(usuario => {
            this.usuarioLogado = usuario;
        });
        this.chamadoStore.getCurrent().subscribe(chamado => {

            if (chamado) {
                // this.chamado = chamado;
                this.loadChamadoById(chamado.id);
            }
            else {
                // this.showMessage('Chamado não encontrado.');
                // this.chamado = new Chamado();
                // this.participante = new Pessoa();
                // this.participante.endereco = '---';
            }
        });
        this.integracaoService.getById(1).subscribe( integracao => {
            this.integracao = integracao; });
    }


    selectBeneficio(target) {
        let beneficio = target.value
        this.beneficio = beneficio
    }

    selectInscricao(target) {
        let inscricao = target.value

        let participante = inscricao.participante
        let plano = inscricao.plano
        let beneficio = inscricao.beneficios[0]
        this.inscricao = inscricao
        this.beneficios = this.inscricao.beneficios
        this.participante = participante
        this.plano = plano
        this.beneficio = beneficio

        const idPessoa = participante.idDecujo > 0 ? participante.idDecujo : participante.id;

        this.pessoaService.getDadosResumo(idPessoa, plano.planoId).subscribe(dadosResumo => {
            if (dadosResumo) {
                this.dadosResumo = dadosResumo;
                this.resumo = dadosResumo.resumos[0];
                this.resumoStore.update(dadosResumo.resumos);
                // console.log('objeto de resumo: ', this.resumo);
            }
        });
        this.pessoaService.getHistoricoContribuicao(idPessoa, plano.planoId).subscribe(contribuicoes => {
            //console.log('Contribuicoes: ', contribuicoes);
            if (contribuicoes.length > 0) {
                // console.log('Contribuicão: ', contribuicoes[0]);
                //this.contribuicao = _.orderBy(contribuicoes, ['DataVigencia'], ['desc'])[0];
                if (this.plano.nomePlano.includes("PS"))
                    this.contribuicao = contribuicoes.find(x=> x.contribuicaoId == 6)
                else
                    this.contribuicao = contribuicoes.find(x => x.contribuicaoId == 1)
            }
        });
        this.pessoaService.getSaldo(idPessoa, plano.planoId).subscribe(saldos => {
            this.saldos = saldos;
            let total = 0;
            for (const saldo of this.saldos) {
                total = total + saldo.reais;
            }
            this.saldoContribuicao = total;
        });

        this.tipoBeneficio.nome = beneficio.tipoBeneficio.nome;

        this.pessoaService.getValorSuplementacao(beneficio.beneficioId).subscribe(valorSuplementacao => {
            if (valorSuplementacao) {
                this.valorSuplementacao = valorSuplementacao;
            }
        });
        this.pessoaService.getPercentualRenda(participante.id, beneficio.beneficioId).subscribe(percentualRenda => {
            if (percentualRenda) {
                this.percentualRenda = percentualRenda;
            }
        });

        this.pessoaService.getTipoFolha(idPessoa).subscribe(tipoFolha => {
            if (!this.util.IsNullOrUndefined(tipoFolha)) {
                this.tipoFolha = tipoFolha;
            }
        });
        this.pessoaService.getValorBPA(idPessoa).subscribe(valorBPA => {
            if (valorBPA) {
                this.valorBPA = valorBPA;
            }
        });

        this.pessoaService.getAllContratosEmprestimo(participante.id).subscribe(contratos => {
            this.emprestimos = contratos;
        });

    }

    // selectParticipante(participante) {
    //     this.participante = participante
    //     console.log(participante)
    //     this.pessoaService.getPlanos(participante.id).subscribe(planos => {
    //         // console.log('Detalhe Planos', planos);
    //         this.planos = planos;

    //         console.log(planos);
    //         //this.plano = planos.find(p => p.planoId === chamado.planoId);
    //         if (planos.length > 0) {
    //             this.plano = planos[0];
    //             this.pessoaService.getDadosResumo(this.participante.id, this.plano.planoId).subscribe(dadosResumo => {
    //                 if (dadosResumo) {
    //                     this.dadosResumo = dadosResumo;
    //                     this.resumo = dadosResumo.resumos[0];
    //                     this.resumoStore.update(dadosResumo.resumos);
    //                     console.log('objeto de resumo: ', this.resumo);
    //                 }
    //             });
    //             this.pessoaService.getHistoricoContribuicao(this.participante.id, this.plano.planoId).subscribe(contribuicoes => {
    //                 console.log('Contribuicoes: ', contribuicoes);
    //                 if (contribuicoes.length > 0) {
    //                     console.log('Contribuicão: ', contribuicoes[0]);
    //                     this.contribuicao = _.orderBy(contribuicoes, ['DataVigencia'], ['desc'])[0];

    //                 }
    //             });
    //             this.pessoaService.getSaldo(this.participante.id, this.plano.planoId).subscribe(saldos => {
    //                 this.saldos = saldos;
    //                 let total = 0;
    //                 for (const saldo of this.saldos) {
    //                     total = total + saldo.reais;
    //                 }
    //                 this.saldoContribuicao = total;
    //             });
    //             this.pessoaService.getBeneficio(this.participante.id, this.participante.empresa.id).subscribe(beneficios => {
    //                 console.log('Beneficios: ', beneficios);
    //                 if (beneficios.length !== 0) {
    //                     this.listaBeneficio = beneficios;
    //                     // const tmpBeneficios = beneficios;
    //                     console.log('Beneficio: ', beneficios[0]);
    //                     this.beneficio = beneficios[0];
    //                     this.tipoBeneficio.nome = this.beneficio.tipoBeneficio.nome;
    //                     // this.pessoaService.getTipoBeneficio(this.beneficio.tipoBeneficioId).subscribe(tipoBeneficio => {
    //                     //     console.log('TipoBeneficio: ', tipoBeneficio);                                
    //                     //     this.tipoBeneficio = tipoBeneficio;
    //                     // });
    //                     this.pessoaService.getValorSuplementacao(this.beneficio.beneficioId).subscribe(valorSuplementacao => {
    //                         if (valorSuplementacao) {
    //                             this.valorSuplementacao = valorSuplementacao;
    //                         }
    //                     });
    //                     this.pessoaService.getPercentualRenda(this.participante.id, this.beneficio.beneficioId).subscribe(percentualRenda => {
    //                         if (percentualRenda) {
    //                             this.percentualRenda = percentualRenda;
    //                         }
    //                     });
    //                 }
    //             });
    //             this.pessoaService.getTipoFolha(this.participante.id).subscribe(tipoFolha => {
    //                 if (!this.util.IsNullOrUndefined(tipoFolha)) {
    //                     this.tipoFolha = tipoFolha;
    //                 }
    //             });
    //             this.pessoaService.getValorBPA(this.participante.id).subscribe(valorBPA => {
    //                 if (valorBPA) {
    //                     this.valorBPA = valorBPA;
    //                 }
    //             });

    //             this.pessoaService.getAllContratosEmprestimo(this.participante.id).subscribe(contratos => {
    //                 this.emprestimos = contratos;
    //                 console.log('contratos ', this.emprestimos);
    //             });

    //         }
    //         console.log('Detalhe Plano', this.plano);
    //         console.log('Detalhe Participante', this.participante)
    //     });
    // }

    // selectPlano(plano) {
    //     this.plano = plano.value;

    //     this.pessoaService.getDadosResumo(this.participante.id, this.plano.planoId).subscribe(dadosResumo => {
    //         if (dadosResumo) {
    //             this.dadosResumo = dadosResumo;
    //             this.resumo = dadosResumo.resumos[0];
    //             this.resumoStore.update(dadosResumo.resumos);
    //             console.log('objeto de resumo: ', this.resumo);
    //         }
    //     });
    //     this.pessoaService.getHistoricoContribuicao(this.participante.id, this.plano.planoId).subscribe(contribuicoes => {
    //         console.log('Contribuicoes: ', contribuicoes);
    //         if (contribuicoes.length > 0) {
    //             console.log('Contribuicão: ', contribuicoes[0]);
    //             this.contribuicao = _.orderBy(contribuicoes, ['DataVigencia'], ['desc'])[0];

    //         }
    //     });
    //     this.pessoaService.getSaldo(this.participante.id, this.plano.planoId).subscribe(saldos => {
    //         this.saldos = saldos;
    //         let total = 0;
    //         for (const saldo of this.saldos) {
    //             total = total + saldo.reais;
    //         }
    //         this.saldoContribuicao = total;
    //     });
    //     this.pessoaService.getBeneficio(this.participante.id, this.participante.empresa.id).subscribe(beneficios => {
    //         console.log('Beneficios: ', beneficios);
    //         if (beneficios.length !== 0) {
    //             this.listaBeneficio = beneficios;
    //             // const tmpBeneficios = beneficios;
    //             console.log('Beneficio: ', beneficios[0]);
    //             this.beneficio = beneficios[0];
    //             this.tipoBeneficio.nome = this.beneficio.tipoBeneficio.nome;
    //             // this.pessoaService.getTipoBeneficio(this.beneficio.tipoBeneficioId).subscribe(tipoBeneficio => {
    //             //     console.log('TipoBeneficio: ', tipoBeneficio);                                
    //             //     this.tipoBeneficio = tipoBeneficio;
    //             // });
    //             this.pessoaService.getValorSuplementacao(this.beneficio.beneficioId).subscribe(valorSuplementacao => {
    //                 if (valorSuplementacao) {
    //                     this.valorSuplementacao = valorSuplementacao;
    //                 }
    //             });
    //             this.pessoaService.getPercentualRenda(this.participante.id, this.beneficio.beneficioId).subscribe(percentualRenda => {
    //                 if (percentualRenda) {
    //                     this.percentualRenda = percentualRenda;
    //                 }
    //             });
    //         }
    //     });
    //     this.pessoaService.getTipoFolha(this.participante.id).subscribe(tipoFolha => {
    //         if (!this.util.IsNullOrUndefined(tipoFolha)) {
    //             this.tipoFolha = tipoFolha;
    //         }
    //     });
    //     this.pessoaService.getValorBPA(this.participante.id).subscribe(valorBPA => {
    //         if (valorBPA) {
    //             this.valorBPA = valorBPA;
    //         }
    //     });

    //     this.pessoaService.getAllContratosEmprestimo(this.participante.id).subscribe(contratos => {
    //         this.emprestimos = contratos;
    //         console.log('contratos ', this.emprestimos);
    //     });

    //     // this.pessoaService.getDadosResumo(this.participante.id, this.plano.planoId).subscribe(dadosResumo => {
    //     //     console.log(this.participante + 'participante')
    //     //     if (dadosResumo) {
    //     //         this.dadosResumo = dadosResumo;
    //     //         this.resumo = dadosResumo.resumos[0];
    //     //         this.resumoStore.update(dadosResumo.resumos);
    //     //         console.log('objeto de resumo: ', this.resumo);
    //     //     }
    //     // });
    //     // this.pessoaService.getHistoricoContribuicao(this.participante.id, this.plano.planoId).subscribe(contribuicoes => {
    //     //     console.log('Contribuicoes: ', contribuicoes);
    //     //     if (contribuicoes.length > 0) {
    //     //         console.log('Contribuicão: ', contribuicoes[0]);
    //     //         this.contribuicao = _.orderBy(contribuicoes, ['DataVigencia'], ['desc'])[0];

    //     //     }
    //     // });
    //     // this.pessoaService.getSaldo(this.participante.id, this.plano.planoId).subscribe(saldos => {
    //     //     this.saldos = saldos;
    //     //     let total = 0;
    //     //     for (const saldo of this.saldos) {
    //     //         total = total + saldo.reais;
    //     //     }
    //     //     this.saldoContribuicao = total;
    //     // });
    // }
    verificaChamadoEmAtendiemtno(chamado) {
        if (chamado.situacao.nome == "Em Atendimento" && this.usuarioLogado.idLogin != this.chamado.atendente.login.idLogin) {
           
            this.router.navigate(['project']).then((navigated: boolean) => {
                if(navigated) {
                  this.snackBarService.open('Esse atendimento está associado ao:  '+ chamado.atendente.login.idLogin, 'Fechar');
                }
            });

            //this._snackBar.open('Esse atendimento está associado ao'+ chamado.atendente.login.idLogin, 'X');
            // console.log("Esse atendimento está associado ao " + chamado.atendente.login.idLogin);
            // this.router.navigate(['todos-atendimentos-app']);
        }
    }
    loadChamadoById(chamadoId: number) {
        // this.loadingIndicator = true;
        this.chamadoService.getById(chamadoId).subscribe(chamado => {
            this.chamado = chamado;
            if (chamado !== null) {
                this.verificaChamadoEmAtendiemtno(chamado);
               
                if (chamado.situacao.nome === 'Concluído') {

                    this.mostrarRegistroConcluido = false;
                }

                this.loadParticipante(chamado.cpf)

                this.pessoaService.getListaPessoaByCPF(chamado.cpf).subscribe(listaPessoa => {

                    if (listaPessoa) {
                        this.participantes = listaPessoa;
                        // listaPessoa.forEach(pessoa => {
                        //     if ((this.chamado.beneficiario === 'S' && pessoa.isBeneficiario === 'S') || (this.chamado.beneficiario === 'N') || pessoa.idDecujo > 0) {
                        //         this.participante = pessoa;
                        //     }
                        // });

                        // const participante = listaPessoa.find(x => x.idDecujo > 0)

                        // if (listaPessoa.length > 1 && participante != null) {
                        //     this.participante = participante
                        // }

                        // if (listaPessoa.length > 1 && participante == null) {
                        //     this.participante = listaPessoa.find(x => x.isParticipante === 'S')
                        // }

                        // if (this.participante.isParticipante === 'N') {
                        //     return;
                        // }

                        // const idPessoa = this.participante.idDecujo > 0 ? this.participante.idDecujo : this.participante.id;

                        this.participante = listaPessoa.find(x => x.isParticipante === 'S')
                        
                        if(!this.participante) {
                            this.participante = listaPessoa.find(x => x.isBeneficiario === 'S')
                        }

                        if(!this.participante) {
                            this.participante = listaPessoa.find(x => x.id == Math.max(...listaPessoa.map(y => y.id)))
                        }

                        if (this.participante.isBeneficiario === 'S') {
                            this.pessoaService.getByIdPessoaFromBeneficiario(this.participante.id).subscribe(participante2 => {
                                if (participante2)
                                    this.participante2 = participante2;
                            });
                        }

                        if (this.participante.isParticipante === 'S' || this.participante.isBeneficiario === 'S') {

                            var participanteId = this.participante2 ? this.participante2.id : this.participante.id

                            this.pessoaService.getPlanos(participanteId).subscribe(planos => {
                                // console.log('Detalhe Planos', planos);
                                this.planos = planos;
                                // console.log(planos);
                                //this.plano = planos.find(p => p.planoId === chamado.planoId);
                                this.plano = planos[0];
                                // console.log('Detalhe Plano', this.plano);
                                // console.log('Detalhe Participante', this.participante)
                            });
                            this.pessoaService.getDadosResumo(participanteId, chamado.planoId).subscribe(dadosResumo => {
                                if (dadosResumo) {
                                    this.dadosResumo = dadosResumo;
                                    this.resumo = dadosResumo.resumos[0];
                                    this.resumoStore.update(dadosResumo.resumos);
                                    // console.log('objeto de resumo: ', this.resumo);
                                }
                            });
                            this.pessoaService.getHistoricoContribuicao(participanteId, chamado.planoId).subscribe(contribuicoes => {
                                // console.log('Contribuicoes: ', contribuicoes);
                                if (contribuicoes.length > 0) {
                                    // console.log('Contribuicão: ', contribuicoes[0]);
                                    //this.contribuicao = _.orderBy(contribuicoes, ['DataVigencia'], ['desc'])[0];
                                    if (this.plano.nomePlano.includes("PS"))
                                        this.contribuicao = contribuicoes.find(x=> x.contribuicaoId == 6)
                                    else
                                        this.contribuicao = contribuicoes.find(x => x.contribuicaoId == 1)
                                }
                            });
                            this.pessoaService.getSaldo(participanteId, chamado.planoId).subscribe(saldos => {
                                this.saldos = saldos;
                                let total = 0;
                                for (const saldo of this.saldos) {
                                    total = total + saldo.reais;
                                }
                                this.saldoContribuicao = total;
                            });
                            this.pessoaService.getBeneficio(participanteId).subscribe(beneficios => {
                                // console.log('Beneficios: ', beneficios);
                                if (beneficios.length !== 0) {
                                    this.listaBeneficio = beneficios;
                                    // const tmpBeneficios = beneficios;
                                    // console.log('Beneficio: ', beneficios[0]);
                                    this.beneficio = beneficios[0];
                                    this.tipoBeneficio.nome = this.beneficio.tipoBeneficio.nome;
                                    // this.pessoaService.getTipoBeneficio(this.beneficio.tipoBeneficioId).subscribe(tipoBeneficio => {
                                    //     console.log('TipoBeneficio: ', tipoBeneficio);                                
                                    //     this.tipoBeneficio = tipoBeneficio;
                                    // });
                                    this.pessoaService.getValorSuplementacao(this.beneficio.beneficioId).subscribe(valorSuplementacao => {
                                        if (valorSuplementacao) {
                                            this.valorSuplementacao = valorSuplementacao;
                                        }
                                    });
                                    this.pessoaService.getPercentualRenda(this.participante.id, this.beneficio.beneficioId).subscribe(percentualRenda => {
                                        if (percentualRenda) {
                                            this.percentualRenda = percentualRenda;
                                        }
                                    });
                                }
                            });
                            this.pessoaService.getTipoFolha(this.participante.id).subscribe(tipoFolha => {
                                if (!this.util.IsNullOrUndefined(tipoFolha)) {
                                    this.tipoFolha = tipoFolha;
                                }
                            });
                            this.pessoaService.getValorBPA(this.participante.id).subscribe(valorBPA => {
                                if (valorBPA) {
                                    this.valorBPA = valorBPA;
                                }
                            });

                            this.pessoaService.getAllContratosEmprestimo(this.participante.id).subscribe(contratos => {
                                this.emprestimos = contratos;
                                // console.log('contratos ', this.emprestimos);
                            });

                        }
                    }
                });
                // this.loadingIndicator = false;
            }
        });
    }

    getNomeSituacao(situacaoId: number): string {
        if (situacaoId === undefined) {
            return '';
        }
        else {
            return this.situacaoChamadoService.getSituacaoNome(situacaoId);
        }
    }

    getDescricaoSexo(sexo): string {
        let descricao = 'Não Informado';
        if (sexo === 'M') {
            descricao = 'Masculino';
        }
        else if (sexo === 'F') {
            descricao = 'Feminino';
        }
        return descricao;
    }

    showMessage(message: string): void {
        if (!this.messageBox) {
            this.messageBox = true;
            const dialogRef = this.dialog.open(DialogComponent, {
                width: '350px',
                data: { message: message }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.backToHome();
                this.messageBox = false;
            });
        }
    }

    back(): void {
        this.location.back();
    }

    backToAtendimento(): void {
        this.router.navigate(['meus-atendimentos-app']);
    }

    backToHome(): void {
        this.router.navigate(['project']);
    }

    historicoChamado(chamadoId: number): void {
        this.router.navigate(['historico-chamado-app']);
    }

    chamadosParticipante(): void {
        this.router.navigate(['chamados-participante-app']);
    }

    inserirRegistro(chamadoId: number): void {
        this.router.navigate(['inserir-registro-chamado']);
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    newAtendente(): void {
        this.router.navigate(['cadastro-atendente-app']);
    }

    salvar() {
        this.chamado.assunto = this.assunto;
        this.dadosResumo.resumos = this.resumos;
        this.chamadoStore.getCurrent().subscribe(chamado => {
            if (chamado) {
                this.loadChamadoById(chamado.id);
            }
            else { }
        });
    }

    AbrirPopUpTempoServico() {
        const dialogRef = this.dialog.open(TempoServicoComponent, {
            width: 'auto',
            data: { message: 'Tempo de Serviço', idPessoa: this.participante.id }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    AbrirPopUpTempoServico2() {
        const dialogRef = this.dialog.open(TempoServicoComponent, {
            width: 'auto',
            data: { message: 'Tempo de Serviço', idPessoa: this.participante2.id }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    AbrirPopUpUltimosLancamentos() {
        const dialogRef = this.dialog.open(UltimosLancamentosComponent, {
            width: 'auto',
            data: { message: 'Últimos Lançamentos', idPessoa: this.participante.id }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    anexosChamado() {        
        this.integracaoService.getById(1).subscribe(integracao => {

            if (integracao) {
                this.integracao = integracao;

                let form = document.createElement("form");
                form.target = "view";
                form.method = integracao.metodo;
                form.action = integracao.url;
                var params = {
                "client_id": this.integracao.clientId,
                "client_secret": this.integracao.clientSecret,
                "usuario": this.chamado.atendente.login.idLogin,
                "id_chamado": this.chamado.id,
                "cpf": this.chamado.cpf,
                "nome": this.chamado.nomeParticipante,
                "email": this.participante.email
                };

                for (var i in params) {
                    if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                    }
                }

                document.body.appendChild(form);
                form.submit();
                window.open('', 'view');
            }
            else {
                this.snackBarService.open('Integração não encontrada na base de dados.', 'Fechar');
            }
        });
    }
}

