import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DialogComponent } from '../components/message/dialog.component';
import { Integracao } from '../shared/models/integracao';
import { IntegracaoService } from '../shared/services/integracao.service';
import { IntegracaoStore } from '../shared/store/integracao.store';

@Component({
  selector: 'app-cadastro-integracao',
  templateUrl: './cadastro-integracao.component.html',
  styleUrls: ['./cadastro-integracao.component.scss']
})
export class CadastroIntegracaoComponent implements OnInit {

  integracaoFilter: any = [];
  integracoes: Integracao[] = [];
  filteredIntegracoes: Integracao[];
  orderDirection: boolean = true;
  sortField: string;

  displayedColumns: string[] = ['id', 'url', 'metodo', 'clientId', 'clientSecret', 'ativada', 'acoes'];

  constructor(
    private readonly integracaoService: IntegracaoService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly integracaoStore: IntegracaoStore
  ) { }

  ngOnInit() {
    this.loadIntegracoes();
  }

  loadIntegracoes(){
    this.integracaoService.getAll().subscribe(integracoes => {
        this.filteredIntegracoes = integracoes.sort((a, b) => a.url.localeCompare(b.url));
        this.integracoes = integracoes.sort((a, b) => a.url.localeCompare(b.url));
        this.integracaoStore.update(null);
    });
  }

  newIntegracao(): void {
    this.integracaoStore.update(null);
    this.router.navigate(['new-integracao']);
  }

  editIntegracao(integracaoId: number) {
      const integracao = this.integracoes.find(a => a.id === integracaoId);
      this.integracaoStore.update(integracao);        
      this.router.navigate(['new-integracao']);
  }

  deleteIntegracao(integracaoId) {
      this.integracaoService.deleteById(integracaoId).subscribe(
          () => {
              this.showMessage('Integração removida.');
          },
          (err) => {
              this.showMessage(err);
          });
  }

  back(): void {
      this.router.navigate(['project']);
  }

  showMessage(message: string): void {
      const dialogRef = this.dialog.open(DialogComponent, {
          width: '350px',
          data: { message: message }
      });

      dialogRef.afterClosed().subscribe(() => {
          this.loadIntegracoes();
      });
  }

}
