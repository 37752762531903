import { Inject, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

export interface PeriodicElement {
    id :string;
    nome: string;
    telefone: string;
    area: string;
    canal: string;
    acoes: string;


}

const ELEMENT_DATA: PeriodicElement[] = [
    { id: '1234', nome: 'João Pedro', telefone: '987845623', area: 'financeiro', canal: '', acoes: '' },
    { id: '5678', nome: 'Marcos Almeida', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '91011', nome: 'Mariana Castro', telefone: '987845623', area: 'financeiro', canal: '', acoes: '' },
    { id: '1213', nome: 'Bruno Correa', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '1415', nome: 'João Pedro', telefone: '987845623', area: 'planejamento', canal: '', acoes: '' },
    { id: '1617', nome: 'João Pedro', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
    { id: '1819', nome: 'Mariana Castro', telefone: '987845623', area: '', canal: '', acoes: '' },
    { id: '2021', nome: 'Bruno Correa', telefone: '987845623', area: 'administrativo', canal: '', acoes: '' },
];

@Component({
    selector: 'cadastro-atendente-app',
    templateUrl: './cadastro-atendente-app.component.html',
    styleUrls: ['./cadastro-atendente-app.component.scss'],
    
   })
export class CadastroAtendenteAppComponent implements OnInit {

    myForm: FormGroup;
    disabled = false;
    ShowFilter = false;
    limitSelection = false;
    cities  = [];
    selectedItems = [];
    dropdownSettings: any = {};
    router: any;
    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.cities = [
            { item_id: 1, item_text: 'VÍDEO CHAT' },
            { item_id: 2, item_text: 'Telefone' },
            { item_id: 3, item_text: 'Fale Conosco' },
            { item_id: 4, item_text: 'Chat' }

        ];
        this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Marca todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 6,
            allowSearchFilter: this.ShowFilter
        };
        this.myForm = this.fb.group({
            city: [this.selectedItems]
        });
    }

    onItemSelect(item: any) {
        console.log('onItemSelect', item);
    }
    onSelectAll(items: any) {
        console.log('onSelectAll', items);
    }
    toogleShowFilter() {
        this.ShowFilter = !this.ShowFilter;
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
    }

    handleLimitSelection() {
        if (this.limitSelection) {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
        } else {
            this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
        }
    }

    backToList(): void {
        this.router.navigate(["atendentes-app"]);
    }

}