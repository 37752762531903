import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, Data } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Chamado } from '../shared/models/chamado.model';
import { Atendente } from '../shared/models/atendente.model';
import { ChamadoService } from '../shared/services/chamado.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';
import { AtendenteStore } from '../shared/store/atendente.store';
import { ChamadoStore } from '../shared/store/chamado.store';
import * as _ from 'lodash';
import { Situacao } from '../shared/models/situacao.model';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';
import { saveAs } from 'file-saver';
import { AtendenteService } from '../shared/services/atendente.service';
import { s } from '@angular/core/src/render3';

@Component({
    selector: 'todos-atendimentos-app',
    templateUrl: './todos-atendimentos-app.component.html',
    styleUrls: ['./todos-atendimentos-app.component.scss'],
})

export class TodosAtendimentosAppComponent implements OnInit {

    totalChamados: number
    totalChamadosRelatorio: number
    listaSituacao: Situacao[] = [];
    atendentes: Atendente[] = [];
    tempChamados: Chamado[] = [];
    chamados: Chamado[] = [];
    chamadosRelatorio: Chamado[] = [];
    chamadoStatus: ChamadoStatus = new ChamadoStatus();
    selectedChamados = [];
    selectedAtendente: Atendente = new Atendente();
    displayedColumns: string[] = ['ID', 'CPF', 'ATENDENTE', 'PARTICIPANTE', 'SITUAÇÃO', 'DT. ABERTURA', 'DT. PREV. DE CONCLUSÃO', 'AÇÕES'];
    statusTR = false;
    loadingIndicator = false;
    statusDoChamado = true;
    page: number = 0

    nome: string;
    cpf: string;
    data: Date;
    situacao: string;
    atendente: string;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        public dialog: MatDialog,
        private readonly router: Router,
        private readonly chamadoService: ChamadoService,
        private readonly situacaoChamadoService: SituacaoChamadoService,
        private readonly atendenteStore: AtendenteStore,
        private readonly atendenteService: AtendenteService,

        private readonly chamadoStore: ChamadoStore,
        private readonly chamadoStatusService: ChamadoStatusService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    getValue(event) {
        console.log(event)
    }

    ngOnInit() {
        this.situacaoChamadoService.getAll().subscribe(obj => this.listaSituacao = obj);
        this.atendenteService.getAll().subscribe(obj => this.atendentes = obj);

        this.atendenteStore.getCurrent().subscribe(atendente => {
            if (atendente) {
                this.selectedAtendente = atendente;
                this.loadChamadosPorAtendente(atendente.id);
            }
            else {
                // this.selectedAtendente = 1;
                this.loadChamadosPorAtendente(1);
                // this.backToProject();
            }
        });
    }

    filtrar() {
        this.page = 0
        this.loadChamadosPorAtendente(1)
    }


    changePage(event: any) {
        this.page = event.page
        this.loadChamadosPorAtendente(1)
    }

    loadChamadosPorAtendente(atendenteId: number) {
        this.loadingIndicator = true;
        this.chamadoService.filtrarChamados({
            nome: this.nome,
            cpf: this.cpf,
            dataAbertura: this.data,
            situacao: this.situacao ? Number(this.situacao) : 0,
            atendente: this.atendente ? Number(this.atendente) : 0,
            currentPage: this.page,
            pageSize: 9
        }).subscribe(response => {
            this.chamados = response.chamados;
            this.totalChamados = response.total;
            this.loadingIndicator = false;
        });
    }

    getNomeSituacao(situacao: Situacao): string {
        return this.situacaoChamadoService.getSituacaoNome(situacao.id);
    }

    getSituacaoColor(situacaoId: number): string {
        if (situacaoId === 1 || situacaoId === 5) {
            // Pendentes
            return 'red-800-fg';
        }
        else if (situacaoId === 6) {
            // Em atendimento
            return 'green-700-fg';
        }
        else {
            // Outros
            return 'orange-700-fg';
        }
    }

    backToProject(): void {
        this.router.navigate(['project']);
    }

    newApp(): void {
        this.router.navigate(['create-app']);
    }

    permission(): void {
        this.router.navigate(['permission-app']);
    }

    viewCalendar(): void {
        this.router.navigate(['calendar-app']);
    }

    verDetalhe(chamadoId: number): void {

        const chamado = this.chamados.find(c => c.id === chamadoId);
        this.chamadoStore.update(chamado);
        this.router.navigate(['chamado-detalhe-app']);
    }

    openDetail() {
        this.statusTR = !this.statusTR;
    }

    gerarRelatorio(): void {
        this.loadingIndicator = true;
        this.chamadoService.filtrarChamadosRelatorio({
            nome: this.nome,
            cpf: this.cpf,
            dataAbertura: this.data,
            situacao: this.situacao ? Number(this.situacao) : 0,
            atendente: this.atendente ? Number(this.atendente) : 0,
            currentPage: this.page,
            pageSize: 0
        }).subscribe(response => {
            this.chamadosRelatorio = response.chamados;
            this.totalChamadosRelatorio = response.total;
            this.exportarPlanilhaRelatorio();
            this.loadingIndicator = false;
        });
        
    }

    exportarPlanilhaRelatorio(): void {
        var text = "";
        var iTable = `
            <html>
                <head>
                    <title>Relatório</title>
                    <meta charset="UTF-8" />
                </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>CPF</th>
                            <th>ATENDENTE</th>
                            <th>PARTICIPANTE</th>
                            <th>SITUAÇÃO</th>
                            <th>DT.ABERTURA</th>
                            <th>DT.PREV.DE CONCLUSÃO</th>
                        </tr>
                    </thead>
                    <tbody>
                        
        `;

        text += iTable;
        this.chamadosRelatorio.map(c => {
            let dtAbertura = c.dataAbertura.toString().slice(0, 10);
            let dtPrevistaConclusao = c.dataPrevistaConclusao.toString().slice(0, 10);
            let iLineTable = `
                <tr>
                    <td>${c.id}</td>
                    <td>${c.cpf}</td>
                    <td>${c.atendente ? c.atendente.login.idLogin : ""}</td>
                    <td>${c.nomeParticipante}</td>
                    <td>${c.situacao.nome}</td>
                    <td>${dtAbertura}</td>
                    <td>${dtPrevistaConclusao}</td>
                </tr>`;
            text += iLineTable;
        });
        var fTable = "</tbody></table></body></html>";
        text += fTable;
        let data = new Blob([text], {
            // type: "text/plain;charset=utf-8"
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        const fileName = `relatorio.xls`;
        saveAs(data, fileName);
    }
}
