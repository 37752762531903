import { Situacao } from './../shared/models/situacao.model';
import { Inject, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableModule } from '@angular/material/table';

import { DialogComponent } from '../../../main/content/components/message/dialog.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { Chamado } from '../shared/models/chamado.model';
import { Canal } from '../shared/models/canal.model';
import { Atendente } from '../shared/models/atendente.model';
import { Area } from '../shared/models/area.model';

import { ChamadoService } from '../shared/services/chamado.service';
import { AtendenteService } from '../shared/services/atendente.service';
import { AreaService } from '../shared/services/area.service';
import { SituacaoChamadoService } from '../shared/services/situacaoChamado.service';

import { CanalStore } from '../shared/store/canal.store';
import { ChamadoStore } from '../shared/store/chamado.store';

import { EventEmitterService } from '../../content/shared/services/eventEmitter.service';

import * as _ from 'lodash';
import { AtendenteStore } from '../shared/store/atendente.store';
import { ChamadoStatusService } from '../shared/services/chamadoStatus.service';
import { ChamadoStatus } from '../shared/models/chamadoStatus.model';

@Component({
    selector: 'canal-app',
    templateUrl: './canal-app.component.html',
    styleUrls: ['./canal-app.component.scss'],

})

export class CanalAppComponent implements OnInit {

    displayedColumns: string[] = ['ID', 'STATUS', 'RESPONSAVEL', 'DTABERTURA', 'DTMODIFICACAO', 'ACOES'];

    selectedCanal: Canal = new Canal();
    chamados: Chamado[];

    concluido = 0;
    cancelado = 0;
    emAtendimento = 0;
    pendente = 0;
    // reaberto = 0;
    retaguarda = 0;
    total = 0;
    loadingIndicator = false;
    atendenteLogado: Atendente = new Atendente();
    chamadoStatus: ChamadoStatus = new ChamadoStatus();

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly chamadoService: ChamadoService,
        private readonly atendenteService: AtendenteService,
        private readonly areaService: AreaService,
        private readonly situacaoChamadoService: SituacaoChamadoService,
        private readonly canalStore: CanalStore,
        private readonly chamadoStore: ChamadoStore,
        private readonly atendenteStore: AtendenteStore,
        private readonly chamadoStatusService: ChamadoStatusService

    ) {
    }

    ngOnInit() {
        EventEmitterService.get('atualizaChamadosPorCanal').emit();

        this.canalStore.getCurrent().subscribe(canal => {
            if (canal) {
                this.selectedCanal = canal;
                // this.loadChamadosPorCanal(canal.id);
                this.loadChamadosTotalizadores(canal.id);

                this.atendenteStore.getCurrent().subscribe(atendenteLogado => {
                    this.atendenteLogado = atendenteLogado;

                    if (atendenteLogado) {
                        this.loadChamadosPorCanalAreaAtendente(canal.id, atendenteLogado.id);
                    }
                });
            }
        });


    }

    botaoClick() {
        EventEmitterService.get('atualizaChamadosPorCanal').emit('test');
    }

    // loadChamadosPorCanal(canalId: number){
    //     this.loadingIndicator = true;
    //     this.chamadoService.getAllByCanal(canalId).subscribe(chamados => {
    //         this.chamados = _.orderBy(chamados, ['situacao', 'id'], ['asc', 'asc']);
    //         this.loadingIndicator = false;
    //         // console.log(chamados);
    //     });
    // }

    loadChamadosPorCanalAreaAtendente(canalId: number, atendenteId: number) {
        this.atendenteService.getByAtendenteId(atendenteId).subscribe(area => {
            if (area) {
                this.loadingIndicator = true;
                this.chamadoService.getAllByCanalAreaAtendente(canalId, area.id, atendenteId).subscribe(chamados => {
                    this.chamados = _.orderBy(chamados, ['situacao', 'id'], ['asc', 'asc']);
                    this.loadingIndicator = false;
                });
            }
        });
    }

    loadChamadosTotalizadores(canalId: number) {
        this.chamadoService.getTotalizadores().subscribe(chamadosTotalizadores => {
            this.concluido = chamadosTotalizadores.find(c => c.id === canalId).concluido;
            this.cancelado = chamadosTotalizadores.find(c => c.id === canalId).cancelado;
            this.emAtendimento = chamadosTotalizadores.find(c => c.id === canalId).emAtendimento;
            this.pendente = chamadosTotalizadores.find(c => c.id === canalId).pendente;
            // this.reaberto = chamadosTotalizadores.find(c => c.id === canalId).reaberto;
            this.total = chamadosTotalizadores.find(c => c.id === canalId).total;
        });
    }

    getSituacaoColor(situacao: Situacao): string {
        if (situacao.nome === 'Pendente') {
            // Pendentes
            return 'red-800-fg';
        }
        else if (situacao.nome === 'Em Atendimento') {
            // Em atendimento
            return 'green-700-fg';
        }
        else {
            // Outros
            return 'orange-700-fg';
        }
    }

    atenderChamado(chamadoId: number): void {
        this.chamadoService.getById(chamadoId).subscribe(chamado => {
            let atenderChamado = new Chamado();
            atenderChamado = chamado;
            atenderChamado.atendente = this.atendenteLogado;
            this.situacaoChamadoService.getFiltroByNome('Em Atendimento').subscribe(sit => {
                if (atenderChamado.situacao.nome == 'Em Atendimento') {
                    return this.showMessageOnly('Esse chamado já foi atendido!');
                }
                atenderChamado.situacao = sit;
                this.chamadoService.atenderChamado(atenderChamado).subscribe(chamado => {
                    const message = 'Chamado ' + chamado.id + ' associado ao Atendente: ' + this.atendenteLogado.login.idLogin;
                    this.showMessage(message, chamado.id);
                },
                    error => {
                        const message = error;
                        this.showMessage(message);
                    });
            });
        },
            error => {
                const message = error;
                this.showMessage(message);
            });

    }

    showMessageOnly(message: string, chamadoId?: number): void {
        this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });
    }

    showMessage(message: string, chamadoId?: number): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: { message: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (chamadoId) {
                this.viewChamado(chamadoId);
            }
            else {
                this.backToProject();
            }
        });
    }

    viewChamado(chamadoId: number): void {

        const chamado = this.chamados.find(c => c.id === chamadoId);
        this.chamadoStore.update(chamado);

        // Solicita atualização dos contadores de chamados pendentes por canal
        EventEmitterService.get('atualizaChamadosPorCanal').emit('');

        this.router.navigate(['chamado-detalhe-app']);
    }


    backToProject(): void {
        this.canalStore.update(null);
        this.router.navigate(['project']);
    }

    newApp(): void {
        this.router.navigate(['create-app']);
    }

    permission(): void {
        this.router.navigate(['permission-app']);
    }

    viewCalendar(): void {
        this.router.navigate(['calendar-app']);
    }
}